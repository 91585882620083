import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Document, Page,pdfjs } from 'react-pdf';
import { Link } from 'react-router-dom';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfModal = () => {
  const url=localStorage.getItem('url')
  const [isLoading, setIsLoading] = useState(true);
  const isPdf = url && url.toLowerCase().endsWith('.pdf');
  const handleLoadSuccess = () => {
    setIsLoading(false);
  };

  return (
    <>
      <style>
        {`
          .mypddffffff {
            display: flex;
            justify-content: center;
          }
          canvas.react-pdf__Page__canvas {
            margin: auto;
          }
            .react-pdf__Document {
    overflow: scroll;
}
             @media(max-width:768px){
      canvas.react-pdf__Page__canvas{
  }
  
  }
        `}
      </style>
      <div style={{display:'flex',justifyContent:'end',marginTop:'18px'}}>
      <button>
          <Link to={url}>
          Download Document
          </Link>
          </button>
      </div>
      <div className='mypddffffff'>
        {isPdf ? (
          <Document file={url} onLoadSuccess={handleLoadSuccess}>
            <Page pageNumber={1}/>
          </Document>
        ) : (
          <img src={url} alt="Image" onLoad={handleLoadSuccess} />
        )}
      </div>
    </>
  );
}

export default PdfModal;
