import {
    DELETE_WISHLIST_PRODUCT_BY_ID_REQUEST,
    DELETE_WISHLIST_PRODUCT_BY_ID_SUCCESS,
    DELETE_WISHLIST_PRODUCT_BY_ID_FAILURE,
} from "../../types";
import axios from "axios";
import { url } from "../../auth/baseUrl";
import { basicAuth } from "../../auth/basicAuth";
import { fetchGetWishlist } from "./getWishList"
export const deleteWishListByCustomerIdRequest = () => {
    return {
        type: DELETE_WISHLIST_PRODUCT_BY_ID_REQUEST,
    };
};
export const deleteWishListByCustomerIdSuccess = (Data) => {
    return {
        type: DELETE_WISHLIST_PRODUCT_BY_ID_SUCCESS,
        payload: Data,
    };
};

export const deleteWishListByCustomerIdFailure = (Error) => {
    return {
        type: DELETE_WISHLIST_PRODUCT_BY_ID_FAILURE,
        payload: Error,
    };
};

export const deleteWishListByCustomerId = (productId) => {
    const customerId=localStorage.getItem('customerId')
    const headers = {
        Accept: "Application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(deleteWishListByCustomerIdRequest());
        axios.delete(`${url}deleteCustomerWishListByProductIdAndCustomerId`, {
            headers,
            params: { customerId, productId },
        })
            .then(function (response) {
                dispatch(deleteWishListByCustomerIdSuccess(response.data));
                dispatch(fetchGetWishlist())
            })
            .catch(function (error) {
                dispatch(deleteWishListByCustomerIdFailure(error));
            });
    };
};
