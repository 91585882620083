import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchProductsBySearch } from "../../actions/products/getProductsBySearch";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import google from "./google.png";

import { ArrowForward } from "@mui/icons-material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import outofstock from "./outofstock.png";
const SearchProduct = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.getProductsSearch);
  console.log(data,'datadatadatadata')
  const { loading } = useSelector((state) => state.getProductsSearch);
  const { keyword } = useParams();

  useEffect(() => {
    console.log(keyword,'keywordkeywordkeyword')
    dispatch(fetchProductsBySearch(keyword));
  }, [dispatch, keyword]);
  const storedCity = localStorage.getItem('cityName');

  const handleProductDetail = (item) => {
    console.log(item);
    // console.log("item ==-=->", item)
    const formattedProductName = item?.productTitle?.replace(/\s+/g, '-'); // Replace all spaces with hyphens

    if(storedCity){
      navigation(`/product-details/${storedCity}/${formattedProductName}`);
      }else{
        navigation(`/product-details/${'noida'}/${formattedProductName}`);
      }
  };

  const calculateDiscouunt = (eighteenMonth, perMonth) => {
    // Calculate the total price for 18 months without discount
    const discountPercentage = ((perMonth - eighteenMonth) / perMonth) * 100;
    // Return the discount percentage for 18 months
    return discountPercentage.toFixed(2);
  };

  const mydata = (days) => {
    const currentDate = new Date();
    const futureDate = new Date(
      currentDate.getTime() + days * 24 * 60 * 60 * 1000
    );
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    var forDate = futureDate.toLocaleDateString("en-GB", options);
    return forDate;
  };
  function forceRepaint() {
    setInterval(() => {
      window.scrollBy(0, 1); // Scrolls down 1 pixel
      window.scrollBy(0, -1); // Scrolls back up
    }, 50); // Run every 50ms during loading
  }
  forceRepaint();
  return (
    <>
    <style>
      {
        `
        .MuiBox-root.css-hshm0p.container.mydiidiidisii{
           display:grid;
              gap: 20px;
            marginTop: 60px;
          position: relative;
    top: 50px;
    margin-bottom: 97px;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        }
       
       
       
        @media(max-width:768px){
         .MuiBox-root.css-hshm0p.container.mydiidiidisii{
        display:grid;
        grid-template-columns: repeat(2,1fr);
        }
        }
        `
      }
    </style>
    <div>
      <h6 style={{color:'black'}}>
        {
          data==='Product not present'?(
            <>
            No product found
            </>
          ):""
        }
      </h6>
    </div>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={data} // Set open state based on the loading prop
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div
        className="MuiBox-root css-hshm0p container mydiidiidisii"
      >
        {data && Array.isArray(data) ? (
          data.filter((item) => item?.status === true).length > 0 ? (
            data.map((item, index) =>
              item?.status === true && (
                <div
                  onClick={() => handleProductDetail(item)}
                  key={index}
                  className="MuiBox-root"
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    overflow: "hidden",
                    height: "100%",
                    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <div style={{ paddingTop: "100%", position: "relative" }}>
                      {item && item.productImagesPath && (
                        <img
                          src={item.productImagesPath[0]}
                          alt=""
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                         {item?.inStockQuantity === 0 && (
              <img 
                src={outofstock} 
                alt="Out of stock"
                style={{
                  position: 'absolute',
                  top: '10px',
                  left: '10px',
                  width: '80px',
                  zIndex: 2
                }}
              />
            )}
      
                      {item?.stripText && (
                        <p
                          className="MuiTypography-root MuiTypography-Small_Medium"
                          style={{
                            backgroundColor: `${item?.stripColor}`,
                            position: "absolute",
                            bottom: "-14px", // Position at the bottom of the image
                            left: "2px", // Padding from the left
                            zIndex: 2, // Ensure it appears above the image
                            fontSize: "12px",
                            color: "white",
                            padding: "4px 8px",
                            borderRadius: "4px",
                          }}
                        >
                          {item?.stripText}
                        </p>
                      )}
                    </div>
                  </div>
                  <div style={{ padding: "10px" }}>
                    <p
                      style={{
                        marginTop: "",
                        fontSize: "14px",
                        fontWeight: "bold",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        marginBottom:'1px'
                      }}
                    >
                      {item.productTitle}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "",
                        marginTop: "5px",
                      }}
                    >
                      <p
                        className="MuiTypography-root MuiTypography-Small_Medium"
                        style={{
                          backgroundColor: "rgb(245, 99, 88)",
                          padding: "2px",
                          borderRadius: "5px",
                          color: "white",
                          fontSize: "10px",
                          marginBottom:'0px'
                        }}
                      >
                        -{calculateDiscouunt(item?.twelveMonth, item?.perMonth)}% off
                      </p>
                      <s
                        style={{
                          marginLeft: "5px",
                          fontSize: "11px",
                          position: "relative",
                        }}
                      >
                        ₹{item?.perMonth}/mo
                      </s>
                    </div>
                    <p
                      className="MuiTypography-root MuiTypography-body1"
                      style={{
                        marginTop: "5px",
                        fontSize: "16px",
                        marginBottom:'0px',
                        fontWeight: "bold",
                        marginBottom:'0px'
                      }}
                    >
                      ₹ {item?.twelveMonth}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <p
                        className="MuiTypography-root MuiTypography-body1"
                        style={{ fontSize: "10px",marginBottom:'0px' }}
                      >
                        Get it by {mydata(item.availabeDay)}
                      </p>
                      <ArrowForward />
                    </div>
                  </div>
                </div>
              )
            )
          ) : (
            <p>No products found</p>
          )
        ) : (
          <p style={{ color: "red", marginTop: "20px" }}>No products found.</p>
        )}
      </div>
      
      )}
    </>
  );
};

export default SearchProduct;
