import React, { useState, useEffect, useRef } from "react";
import Logo from "../../style/images/logo.png";
import { Link, useLocation } from "react-router-dom";
import "../../style/css/productListing.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
//API Function
import { fetchProductsByFilter } from "../../actions/products/getProductsByFilter";
import { fetchAllMainCategory } from "../../actions/products/mainCategory/getAllMainCategory";
import {
  fetchAllMainCategoryDropdownRequest,
  fetchAllMainCategoryDropdown,
} from "../../actions/products/mainCategory/getAllMainCategoryDropdown";
import { fetchAllSubCategoryDropdown } from "../../actions/products/subCategory/getAllSubCategoryDropdown";
import { fetchAllSuperCategoryDropdown } from "../../actions/products/superCategory/getAllSuperCategoryDropdown";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import TuneIcon from "@mui/icons-material/Tune";
//Redux
import { connect, useDispatch, useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
//Icons & Utils
import { useNavigate, useParams, NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ArrowForward, ArrowRight, FilterList } from "@mui/icons-material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CategoryIcon from "@mui/icons-material/Category";
import { BiCurrentLocation } from "react-icons/bi";
import { SlLike } from "react-icons/si";
import { BiLike } from "react-icons/bi";
import { FaUser } from "react-icons/fa";
import { LiaSearchSolid } from "react-icons/lia";
import { FaHome } from "react-icons/fa";
import { BiSolidTimer } from "react-icons/bi";
import { ImProfile } from "react-icons/im";
import TestingPage from "../testingPage";
import Slider from "react-slick";
import { motion } from "framer-motion";
import { Form, FormCheck } from "react-bootstrap";
import { getALLPRODUCTSAction } from "../../actions/products/getALLLPRODUCTSaction";
import google from "./google.png";
import outofstock from "./outofstock.png";
import { Box, Skeleton } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import { Helmet } from "react-helmet";

function ProductListing(props) {
  const location = useLocation();
  console.log(location.pathname)

  
  const categoryNamsssse = location.state;
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShow2 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);
      const cityData = useSelector((state) => state.getAllCity.data)
      const getCityNameFromUrl = () => {
        const pathSegments = location.pathname.split("/").filter(Boolean); // Split and remove empty segments
        
        // The second segment should be the city name
        const cityName = pathSegments[1]; // Example: "Delhi"
        
        return cityName;
      };


      useEffect(() => {
        const cityName = getCityNameFromUrl();

        // Extract the last part of the URL after 'product-listing/' which should be the city name
        const cleanPath = location.pathname.split('/').pop();  // This splits the path and gets the last segment
        // console.log(cleanPath, 'cleanPath');
        // Find the matching city from the cityData
        const selectedCity = cityData.find((city) => city.cityName.toLowerCase() === cityName.toLowerCase());
    // console.log(selectedCity,'selectedCity')
        if (selectedCity) {
          // If city is found, store the pincode in localStorage
          localStorage.setItem('cityName', selectedCity.cityName.toLowerCase());
          setFilterData({
            ...filterData,
            pincode:selectedCity?.pincode
          });
          // console.log('City found:', selectedCity);
        } else {
          // If city is not found, handle this case
          // console.log('City not found in data.');
          // Optionally, you could fallback to a default city or show an error
        }
      }, [cityData,location.pathname]); // 

  const {
    setMainCategoryData,
    handleGetProducts,
    ProductFilterData,
    loading,
    handleFetchAllMainCategory,
    mainCategoryData,
    error,
    handleFetchAllMainCategoryDropdown,
    getAllMainCategoryDropdownData,
    handleFetchAllSubCategoryDropdown,
    getSubCategoryDropdownByIdData,
    // handleFetchAllSuperCategoryDropdown,
    getSuperCategoryDropdownByIdData,
    mainCategoryDataPreSelected,
    setCityPincodeData,
    userSelectedPincode,
  } = props;
  // console.log(getSubCategoryDropdownByIdData, "getSubCategoryDropdownByIdData");

  const isMobile = window.innerWidth <= 768;
  const navigate = useNavigate();
  const [productsLoading, setProductsLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setProductsLoading(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  // Initialize the state with the retrieved keyword or an empty string

  const options = ["Sofas", "Beds", "Others"];
  // const priceOptions = ["250", "251 - 500", "501 - 750", "751 - 1000"]
  const sortOptions = [
    "Featured",
    "Name A-Z",
    "Name Z-A",
    "Price Low to High",
    "Price High to Low",
  ];
  let minPrice = Infinity; // Initialize minPrice with Infinity to ensure any value will be smaller
  let maxPrice = -Infinity; // Initialize maxPrice with -Infinity to ensure any value will be larger
  const producstttttt = useSelector(
    (state) => state?.getAllproductRedcuer?.data
  );
  // console.log(producstttttt, "producstttttt");

  const [sortedProductData, setSortedProductData] = useState([]);

  const [sortedProductforPriceRange, setSortedProductDataForPrice] = useState(
    []
  );

  const generatePriceOptions = (twelveMonths, products) => {
    // Filter out non-numeric values and find min/max
    const validPrices = twelveMonths?.filter((price) => !isNaN(price) && price >= 0);
  
    if (validPrices.length === 0) {
      // No valid prices found
      return [];
    }
  
    const minPrice = Math.min(...validPrices);
    const maxPrice = Math.max(...validPrices);
  
    const priceOptions = new Set();
    let start = Math.floor(minPrice / 300) * 300; // Start from the nearest lower multiple of 300
  
    // Generate ranges up to maxPrice
    while (start <= maxPrice) { // Include maxPrice in the range
      const end = start + 300; // Fix range calculation
      
      // Only add this range if there's at least one product within this range
      const productsInRange = products?.filter((product) => {
        const productPrice = product?.twelveMonth || 0;
        return productPrice >= start && productPrice < end; // Include start, exclude end
      });
  
      if (productsInRange.length > 0) {
        priceOptions.add(`${start} - ${end}`);
      }
  
      start += 300; // Move to the next range
    }
  
    return Array.from(priceOptions); // Convert Set to array for rendering
  };
  
  
  // Usage in collecting unique price options from all products
  const priceOptionsSet = new Set();
  
  if (Array.isArray(sortedProductforPriceRange)) {
    const twelveMonths = sortedProductforPriceRange
      .filter((product) => product?.status === true) // Exclude products with status false
      .map((product) => product?.twelveMonth || 0); // Collect all twelveMonth values
  
    const priceOptions = generatePriceOptions(twelveMonths, sortedProductforPriceRange); // Get unique price options
    priceOptions.forEach((option) => priceOptionsSet?.add(option)); // Add each option to the Set
  }
  
  const priceOptionsArray = Array.from(priceOptionsSet); // Convert Set to array for rendering
  
  // Defining State
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [superAnchor, setSuperAnchor] = useState(null);
  const [superFilters, setSuperFilters] = useState([]);
  const [priceRangeE, setPriceRangeE] = useState(null);
  const [selectedPriceRange, setSelectedPriceRange] = useState([]);
  const [sortE, setSortE] = useState(null);
  const [selectedSortE, setSelectedSortE] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedSortOption, setSelectedSortOption] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const { keyword } = useParams();
  const cleanPath = location.pathname.split('/').pop(); 
  // console.log(cleanPath, 'cleanPath from URL');
  const [categoryNames, cityName] = cleanPath.split('-on-rent-');
  // console.log(cityName,'hi')


  const [filterData, setFilterData] = useState({
    categoryName: "",
    subCategoryNames: [],
    minPrice: 0,
    maxPrice: 0,
    keyword: "",
    pincode: userSelectedPincode?.pincode
      ? userSelectedPincode?.pincode
      : 201301,
  });

  //Sub Category Functionality or Logic
  const SubCategoryOpen = Boolean(anchorEl);
  const SubCategoryId = SubCategoryOpen ? "filter-dropdown" : undefined;
  const handleOpenSubCategoryClick = (event) => {
    setAnchorEl(event.currentTarget);
    const popoverElement = document.querySelector(
      ".css-3bmhjh-MuiPaper-root-MuiPopover-paper"
    );

    // Check if the element exists
    if (popoverElement) {
      // Hide the element by setting its display property to 'none'
      popoverElement.style.display = "none";
    }
  };
  const handleSubCategoryClose = () => {
    setAnchorEl(null);
  };



  const [selectedSubCategory, setSelectedSubCategory] = useState([]); // Define selectedSubCategory state
  const [selectedsuperSubCategory, setselectedsuperSubCategory] = useState([]); // Define selectedSubCategory state


  const handleSubCategoryCheckboxChange = (filter) => {
    const isChecked = selectedSubCategory.some((item) => item.value === filter.value);
  
    // Determine the updated selectedSubCategory
    const updatedSelectedSubCategory = isChecked
      ? selectedSubCategory.filter((item) => item.value !== filter.value)
      : [...selectedSubCategory, filter];
  
    setSelectedSubCategory(updatedSelectedSubCategory); // Update selectedSubCategory state
  
    // Determine the updated selectedFilters
    // const updatedFilters = isChecked
    //   ? selectedFilters.filter((item) => item.value !== filter.value)
    //   : [...selectedFilters, filter];
  
    // Extract labels for subCategoryNames
    const labelArray = updatedSelectedSubCategory.map((item) => item.label);
    console.log(labelArray,'labelArray')
  
    // Update filterData
    const updatedFilterData = {
      ...filterData,
      subCategoryNames: labelArray,
      minPrice: 0, // Maintain the current minPrice
      maxPrice: 0, // Maintain the current maxPrice
      categoryName: filterData.categoryName, // Maintain the current categoryName
    };
  
    // Update states
    setFilterData(updatedFilterData);
    setSelectedFilters(updatedSelectedSubCategory);
    setSelectedPriceRange([])
    // Perform the API call with updatedFilterData
  };
  

  


  useEffect(() => {
    // handleFetchAllSuperCategoryDropdown()
  }, [getSubCategoryDropdownByIdData]);

  //Super Category Functionality or Logic
  const SuperCategoryOpen = Boolean(superAnchor);
  const SuperCategoryId = SuperCategoryOpen ? "filter-dropdown" : undefined;
  const handleOpenSuperCategoryClick = (event) => {
    setSuperAnchor(event.currentTarget);
    setSuperCategoryOpen(!superCategoryOpen);
  };
  const handleSuperCategoryClose = () => {
    setSuperAnchor(null);
  };

  //Super-Category Checkbox Selection
  // const handleSuperCategoryCheckboxChange = (filter) => {
  //   // Toggle the selection state of the filter
  //   if (selectedsuperSubCategory.includes(filter)) {
  //     // If the filter is already selected, remove it from the selection
  //     setselectedsuperSubCategory(
  //       selectedsuperSubCategory.filter((item) => item !== filter)
  //     );
  //   } else {
  //     // If the filter is not selected, add it to the selection
  //     setselectedsuperSubCategory([...selectedsuperSubCategory, filter]);
  //   }

  //   // Update superFilters state based on the selection
  //   const updatedFilters = superFilters.includes(filter)
  //     ? superFilters.filter((item) => item !== filter) // Remove filter if already in superFilters
  //     : [...superFilters, filter]; // Add filter if not in superFilters

  //   // Map updatedFilters to extract labels
  //   const labelArray = updatedFilters.map((item) => item.label);

  //   // Update filterData with the new list of supperCategoryNames
  //   setFilterData({
  //     ...filterData,
  //     supperCategoryNames: labelArray,
  //   });

  //   // Update superFilters state with the updatedFilters list
  //   setSuperFilters(updatedFilters);
  // };

  // Price Range Functionality or logic

  const PriceRangeOpen = Boolean(priceRangeE);
  const priceRangeId = PriceRangeOpen ? "price-range-dropdown" : undefined;
  const handleOpenPriceRangeClick = (event) => {
    setPriceRangeE(event.currentTarget);
    setPriceRangeOpen(!priceRangeOpen);
  };



  const handlePriceRangeClose = () => {
    setPriceRangeE(null);
  };
  const handlePriceRangeCheckboxChange = (filter) => {
    // Split the string into an array using the '-' delimiter
    const [min, max] = filter
      .split("-")
      .map((value) => parseInt(value.trim(), 10));
  
    const isFilterSelected = selectedPriceRange.includes(filter);
  
    const updatedFilters = isFilterSelected ? [] : [filter];
    
    // Reset minPrice and maxPrice to 0 if the same filter is clicked again
    const newFilterData = isFilterSelected
      ? { ...filterData, minPrice: 0, maxPrice: 0 }
      : { ...filterData, minPrice: min, maxPrice: max };
  
    setSelectedPriceRange(updatedFilters);
    setFilterData(newFilterData);
  };
  
  // useEffect(() => {
  //     if (filterData.categoryName) {
  //         localStorage.setItem('cat', filterData.categoryName);
  //     }
  // // }, [filterData]);
  // useEffect(() => {
  //   // Clear the 'cat' key from localStorage when navigating to the homepage
  //   localStorage.removeItem("cat");
  // }, []);

  useEffect(() => {
    // Clear the 'cat' key from localStorage when navigating to the homepage
    localStorage.setItem("pincode", filterData?.pincode);
  }, [filterData?.pincode]);

  // useEffect(() => {
  //     const handleNavigation = () => {
  //         // Clear the 'cat' key from localStorage when navigating to the home page
  //         localStorage.removeItem('cat');
  //     };

  //     // Listen for the component to unmount and navigate to the home page
  //     return () => {
  //         handleNavigation();
  //     };
  // }, []);

  //Sort Functionality or logic
  const sortopenss = Boolean(sortE);
  const sortId = sortopenss ? "sort-dropdown" : undefined;
  const handleOpenSortClick = (event) => {
    setSortE(event.currentTarget);
    setSortOpen(!sortOpen);
  };

  const handleSortClose = () => {
    setSortE(null);
  };
  const handleSortCheckboxChange = (filter) => {
    setSelectedSortOption(filter);
    const updatedFilters = selectedSortE.includes(filter) ? [] : [filter];

    // const updatedFilters = selectedSortE.includes(filter)
    //     ? selectedSortE.filter((item) => item !== filter)
    //     : [...selectedSortE, filter];

    setSelectedSortE(updatedFilters);
  };


  // product-details
  // const hasCalledEffect = useRef(false); // useRef to track if the effect has run

  // useEffect(() => {
  //   // Ensure the effect runs only once
  //   if (!hasCalledEffect.current) {
  //     window.scrollTo(0, 0);  // Scroll to top
  //     handleGetProducts(filterData, keyword);  // Call the product fetch function

  //     hasCalledEffect.current = true;  // Mark the effect as run
  //   }
  // }, []); // Dependencies fo

  useEffect(() => {
    // console.log(categoryNames,'categoryNames')
    if (categoryNames||cityName) {
      const decodedValue = decodeURIComponent(categoryNames);
      const formattedValue = decodedValue.replace(/-/g, " "); // Replace dashes with spaces
      localStorage.setItem("cat", formattedValue);
      
    
      localStorage.setItem('cityName', cityName); // Set categoryNames in localStorage

    }
  }, [categoryNames,cityName]); // Only trigger when categoryNames changes
  

    const hasLoadedcustomer=useRef(false)
  
    useEffect(() => {
      // Check if we're on the product details page based on the URL path
      if ( !hasLoadedcustomer.current) {
        handleFetchAllMainCategory();
    // handleFetchAllMainCategoryDropdown();
        hasLoadedcustomer.current = true;
      }
    }, []);  // Depend on dispatch and location.pathname
  


    const hasFetched = useRef(false); // Ref to track if the effect has been triggered before

    useEffect(() => {
      // Extract the last segment of the path for category name
      const cleanPath = location.pathname.split('/').pop(); 
      // console.log(cleanPath, 'cleanPath from URL');
      const [categoryName, cityName] = cleanPath.split('-on-rent-');
  //  console.log(categoryName,cityName)

      if (!mainCategoryData || mainCategoryData.length === 0) {
        // console.log('Main category data is not ready yet');
        return; // Wait for mainCategoryData to load
      }
    
      // Find the category ID matching the path
      const items = mainCategoryData.find((item) => 
        item?.categoryName?.toLowerCase() === categoryName?.toLowerCase()
      );
      // console.log(items?.categoryId, 'Category ID found');
      // console.log(mainCategoryDataPreSelected, 'Category ID found');

      
      if (!hasFetched.current && (mainCategoryDataPreSelected || items)) {
        // console.log("mainCategoryDataPreSelected before update:", mainCategoryDataPreSelected);
        
        const categoryId = mainCategoryDataPreSelected?.id || items?.categoryId;
    
        if (categoryId) {
          // Save the selected category ID and name
          localStorage.setItem("selectedId", categoryId);
          setSelectedCard(categoryId);
    
          // Call the API for subcategories
          handleFetchAllSubCategoryDropdown(categoryId);
    
          // Update filter data
          setFilterData((prevFilterData) => ({
            ...prevFilterData,
            categoryName: mainCategoryDataPreSelected?.categoryName || items?.categoryName,
          }));
    
          hasFetched.current = true; // Mark the effect as executed
        } else {
          // console.log("No category ID found");
        }
      }
    }, [mainCategoryData, mainCategoryDataPreSelected, cleanPath]);
    
const storedCity = localStorage.getItem('cityName');
const category = localStorage.getItem('cat');  // Correct the typo from 'catgetory' to 'category'
const [categoryName,setCateoryname]=useState()

// useEffect(()=>{
  console.log(categoryName,'categoryName')
//  localStorage.setItem('cat',categoryName);  // Correct the typo from 'catgetory' to 'category'
// },[categoryName])
 console.log(storedCity)

useEffect(() => {
  if (storedCity && category) {
    const formattedCategory = category.replace(/\s+/g, "-");
    navigate(`/product-listing/${formattedCategory}-on-rent-${storedCity}`, { replace: true });
  } 
}, [storedCity,category]);
 


const calculateDiscouunt = (twelveMonth, perMonth) => {
    // Calculate the total price for 18 months without discount
    const discountPercentage = ((perMonth - twelveMonth) / perMonth) * 100;
    // Return the discount percentage for 18 months
    return discountPercentage.toFixed(2);
  };
  const handleCardClick = (index, id, categoryName) => {
    setCateoryname(categoryName)
    // console.log(categoryNamsssse, "categoryNamssssecategoryNamsssse");
    setSelectedCard(id);
    localStorage.setItem("selectedId", id);
    handleFetchAllSubCategoryDropdown(id);
    const decodedValue = decodeURIComponent(categoryName); // Decode if necessary
    const formattedValue = decodedValue.replace(/-/g, " "); // Replace dashes with spaces
    localStorage.setItem("cat", formattedValue);
    
    setMainCategoryData({
      index: index,
      id: id,
      categoryName: categoryName,
    });
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      categoryName: categoryName,
      maxPrice: 0,
      minPrice: 0,
    }));
    setSelectedPriceRange([])
    // console.log(categoryName);
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      categoryName: categoryName,
      subCategoryNames:[], // Clear subCategoryNames when main category is clicked
      maxPrice: 0,
      minPrice: 0,
    }));
    setSelectedSubCategory([]); // Clear selectedSubCategory when main category is clicked
    setSelectedSortE([]); // Assuming setSelectedSortE is your state updater function
    setSelectedSortOption("Sort by")
  };
  useEffect(() => {
    // Check if categoryName has changed
    if (filterData?.minPrice) {
      return;
    } else if (filterData?.maxPrice) {
      return;
    } else if (filterData?.categoryName) {
      setSortedProductDataForPrice(sortedProductData);
    }
  }, [
    filterData.categoryName,
    filterData?.minPrice,
    filterData?.maxPrice,
    sortedProductData,
  ]); // Dependencies
  const isApiCallInProgress = useRef(false); // Flag to track if the API call is in progress

  // useEffect(() => {
  //   localStorage.setItem("cat", filterData?.categoryName);
  // }, [categoryNamsssse, filterData?.categoryName]);

  const isFirstRender = useRef(true); // Track the first render
  useEffect(() => {
    const fetchData = async () => {
      if (isApiCallInProgress.current) {
        return;
      }

      // Prevent multiple API calls
      isApiCallInProgress.current = true;

      // Ensure cityName and categoryNames are available
      if (cityName && categoryNames) {
        await handleGetProducts(filterData, keyword);
      }

      isApiCallInProgress.current = false;
    };

    // Avoid running on initial render if not needed
    if (!isFirstRender.current) {
      fetchData();
    }

    // Mark the first render as completed
    isFirstRender.current = false;
  }, [cityName, categoryNames, filterData, keyword]);
  //Sort Function
  var minPrice2 = -Infinity;
  var mixPrice2 = -Infinity;

  useEffect(() => {
    console.log(ProductFilterData, 'ProductFilterData');
  
    // Perform sorting logic whenever selectedSortOption or ProductFilterData changes
    if (
      selectedSortOption &&
      Array.isArray(ProductFilterData) &&
      ProductFilterData.length !== 0
    ) {
      const sortedData = [...ProductFilterData];
      if (selectedSortOption === "Name A-Z") {
        sortedData?.sort((a, b) => (a?.productTitle || '').localeCompare(b?.productTitle || ''));
      } else if (selectedSortOption === "Name Z-A") {
        sortedData?.sort((a, b) => (b?.productTitle || '').localeCompare(a?.productTitle || ''));
      } else if (selectedSortOption === "Price Low to High") {
        sortedData.sort((a, b) => (a?.twelveMonth || 0) - (b?.twelveMonth || 0));
      } else if (selectedSortOption === "Price High to Low") {
        sortedData.sort((a, b) => (b?.twelveMonth || 0) - (a?.twelveMonth || 0));
      }
      setSortedProductData(sortedData);
    } else {
      // If no sorting option is selected, set the original unsorted data
      if (Array.isArray(ProductFilterData) && ProductFilterData.length !== 0) {
        setSortedProductData([...ProductFilterData]);
      } else {
        setSortedProductData([]);
      }
    }
  }, [selectedSortOption, ProductFilterData]);
  
  // console.log(ProductFilterData,'ProductFilterData')

  const [hoveredIndices, setHoveredIndices] = useState(
    Array(sortedProductData.length).fill(null)
  );

  const handleHover = (index) => {
    const newHoveredIndices = [...hoveredIndices];
    newHoveredIndices[index] = index;
    setHoveredIndices(newHoveredIndices);
  };
  const handleProductDetail = (item) => {
    const formattedProductName1 = item?.productTitle?.replace(/\s+/g, '-'); // Replace all spaces with hyphens
    if(storedCity){
      navigate(`/product-details/${storedCity}/${formattedProductName1}`);
      }else{
        navigate(`/product-details/${'noida'}/${formattedProductName1}`);
      }

  };

  const [futureDate, setFutureDate] = useState();

  const mydata = (days) => {
    const currentDate = new Date();
    const futureDate = new Date(
      currentDate.getTime() + days * 24 * 60 * 60 * 1000
    );
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    var forDate = futureDate.toLocaleDateString("en-GB", options);
    return forDate;
  };
  const [subCategoryOpen, setSubCategoryOpen] = useState(false);

  const handleSubCategoryClick = () => {
    setSubCategoryOpen(!subCategoryOpen);
  };

  const [superCategoryOpen, setSuperCategoryOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleCloseDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setSuperCategoryOpen(false);
    }
  };
  const dropdownRef2 = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleCloseDropdown);
    return () => {
      document.removeEventListener("mousedown", handleCloseDropdown);
    };
  }, []);

  const handleCloseDropdown2 = (event) => {
    if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
      setSubCategoryOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleCloseDropdown2);
    return () => {
      document.removeEventListener("mousedown", handleCloseDropdown2);
    };
  }, []);

  const [priceRangeOpen, setPriceRangeOpen] = useState(false);
  const [sortOpen, setSortOpen] = useState(false);
  // useEffect(() => {
  //   dispatch(getALLPRODUCTSAction());
  // }, [dispatch]);

  const handleMouseEnter = () => {
    setSubCategoryOpen(true);
  };

  const handleMouseLeave = () => {
    setSubCategoryOpen(false);
  };

  const handleMouseEnter2 = () => {
    setPriceRangeOpen(true);
  };

  const handleMouseLeave2 = () => {
    setPriceRangeOpen(false);
  };
  const handleMouseEnter3 = () => {
    setSortOpen(true);
  };

  const handleMouseLeave3 = () => {
    setSortOpen(false);
  };
  // useEffect(() => {
  //   // Update meta tags dynamically based on pathname
  //   if (location.pathname === "/product-listing") {
  //     document.title = "Renty Furniture | Product Listing";
  //     const metaDescription = document.querySelector('meta[name="description"]');
  //     if (metaDescription) {
  //       metaDescription.setAttribute("content", "Explore Renty Furniture's wide selection of stylish and affordable furniture. Find the perfect pieces for your living room, bedroom, and office.");
  //     } else {
  //       const newMeta = document.createElement("meta");
  //       newMeta.name = "description";
  //       newMeta.content = "Explore Renty Furniture's wide selection of stylish and affordable furniture. Find the perfect pieces for your living room, bedroom, and office.";
  //       document.head.appendChild(newMeta);
  //     }
  //   }
  // }, [location.pathname]);
  // Retrieve selected category and card index from localStorage on component mount
  useEffect(() => {
    const savedCategoryName = localStorage.getItem("cat");
    const savedCategoryIndex = localStorage.getItem("selectedCardIndex");

    if (savedCategoryName && savedCategoryIndex !== null) {
      setSelectedCard(parseInt(savedCategoryIndex));
      setFilterData((prev) => ({
        ...prev,
        categoryName: savedCategoryName,
      }));
    }
  }, []);

  // useEffect(() => {
  //   const handlePopState = (event) => {
  //     event.preventDefault();
      
  //     // Get the previous route from localStorage
  //     const previousRoute = localStorage.getItem('previousRoute') || '/';
      
  //     // If we're already at the home page and user tries to go back
  //     if (location.pathname === '/') {
  //       // Show confirmation before closing
  //       const shouldClose = window.confirm('Are you sure you want to leave the website?');
  //       if (shouldClose) {
  //         window.close();
  //       } else {
  //         // Prevent closing by pushing a new entry
  //         window.history.pushState(null, '', window.location.href);
  //       }
  //     } else {
  //       // Navigate to the previous route
  //       navigate(previousRoute);
  //     }
  //   };

  //   // Store current route before it changes
  //   localStorage.setItem('previousRoute', location.pathname);

  //   // Add popstate event listener
  //   window.addEventListener('popstate', handlePopState);

  //   // Push initial state
  //   window.history.pushState(null, '', window.location.href);

  //   return () => {
  //     window.removeEventListener('popstate', handlePopState);
  //   };
  // }, [navigate, location]);

  useEffect(() => {
  console.log(cityName, 'cityNames');

  const selectedCity = cityData.find(
    (city) => city.cityName.toLowerCase() === cityName.toLowerCase()
  );

  const cityPincodeAvailable = localStorage.getItem('cityPincode');
  const cityNameAvailable = localStorage.getItem('cityName');

  if (selectedCity) {
    // If a matching city is found
    localStorage.setItem("cityId", selectedCity.cityId);

    if (!cityPincodeAvailable || cityNameAvailable?.toLowerCase() !== cityName.toLowerCase()) {
      // If cityPincode is not available OR cityName in localStorage is different, update the data
      localStorage.setItem("cityName", selectedCity.cityName.toLowerCase());
      localStorage.setItem("cityPincode", selectedCity.pincode);
    } else {
      // If cityPincode is already available, retain the existing one
      localStorage.setItem("cityPincode", cityPincodeAvailable);
    }
  } else {
    // If the city is not found, use the default cityId (Noida)
    if (!cityName) {
      localStorage.setItem("cityId", 1); // Default to Noida cityId
    }
  }
}, [cityData, location.pathname]);



  return ProductFilterData.legnth != 0 ? (
    <div style={{ marginTop: "5%" }}>
      {isMobile ? (
        <>
          <style>
            {`
                                .headerssss{
                                display:none;
                                }
                                    .MuiBox-root.css-1492rz {
    border: 1px solid grey !important;
}
                                    `}
          </style>
          
       

          <div class="MuiBox-root css-72yisn" bis_skin_checked="1">
            <div class="MuiBox-root css-1s315wo" bis_skin_checked="1">
              <div
                class="MuiBox-root css-qdo4dw"
                style={{ margin: "0px" }}
                bis_skin_checked="1"
              >
                {
                  loading?(
                    <>
                    <Box
              sx={{
                display: "grid",
                gridTemplateColumns: `repeat(${mainCategoryData?.length}, 1fr)`, // Dynamic number of columns based on data length
                gap: "10px", // Space between boxes
                margin: "auto",
                width: "95%",
              }}
            >
              {Array.from({ length: mainCategoryData?.filter((item) => item?.status !== false).length }).map((_, index) => (
                <Box
                  key={index}
                  sx={{
                  
                    border: "1px solid #ddd",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start", // Align items to the start (left)
                  }}
                >
                  <Skeleton variant="rectangular" width="100%" height={50} style={{borderRadius:'10px'}}/>
                  <Skeleton width="100%" style={{width:'120px',borderRadius:'15px'}} />
                  <Skeleton width="100%" />
                </Box>
              ))}
            </Box>
    
                    </>
                  ):(
                    <>
                      <div class="MuiBox-root css-1w45noj" bis_skin_checked="1">

{Array.isArray(mainCategoryData) &&
  mainCategoryData
    ?.filter((item) => item?.status !== false)
    ?.map((item, index) => (
      <div
        key={index}
        onClick={() =>
          handleCardClick(
            index,
            item.categoryId,
            item.categoryName
          )
        }
      >
        {Number(selectedCard) === Number(item.categoryId) ? (
          <div
            className="MuiBox-root css-ocf0bf"
            bis_skin_checked="1"
          >
            <div
              className="MuiBox-root css-15ro776"
              bis_skin_checked="1"
            >
              <svg
                width="12"
                height="12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 3 4.5 8.5 2 6"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <p
              className="MuiTypography-root MuiTypography-body1 css-heejq2"
              style={{ textOverflow: "ellipsis" }}
            >
              {item.categoryName}
            </p>
          </div>
        ) : (
          <div
            className={`MuiBox-root css-1492rz`}
            bis_skin_checked="1"
          >
            <p className="MuiTypography-root MuiTypography-body1 css-h6pkw8">
              {item.categoryName}
            </p>
          </div>
        )}
      </div>
    ))}
</div>
                    </>
                  )
                }
              

                {isMobile && (
                  <>
                    <style>
                      {`
                                                        button.MuiButtonBase-root.MuiButton-root.MuiButton-outline-primary.MuiButton-outline-primaryPrimary.MuiButton-sizeMedium.MuiButton-outline-primarySizeMedium.MuiButton-root.MuiButton-outline-primary.MuiButton-outline-primaryPrimary.MuiButton-sizeMedium.MuiButton-outline-primarySizeMedium.css-1w1rijm-MuiButtonBase-root-MuiButton-root {
 
                                                            border: 1px solid grey !important;
    white-space: nowrap;
    color: rgb(119, 119, 119) !important;
    font-size: 12px;
    background-color: transparent;


}
    button.MuiButtonBase-root.MuiButton-root.MuiButton-outline-primary.MuiButton-outline-primaryPrimary.MuiButton-sizeMedium.MuiButton-outline-primarySizeMedium.MuiButton-root.MuiButton-outline-primary.MuiButton-outline-primaryPrimary.MuiButton-sizeMedium.MuiButton-outline-primarySizeMedium.css-1w1rijm-MuiButtonBase-root-MuiButton-root:hover {
 background:red;
}
                                                        `}
                    </style>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "",
                        gap: "10px",
                        overflow: "auto",
                        margin: "10px"
                        
                      }}
                    >
                      {Array.isArray(getSubCategoryDropdownByIdData) &&
                      getSubCategoryDropdownByIdData.length !== 0 ? (
                        getSubCategoryDropdownByIdData.map((option) =>
                          // Check if status is true before rendering the option
                          option?.status ? (
                            <div
                              key={option.id}
                              style={{
                                border: "1px solid grey",
                                borderRadius: "8px",
                              }}
                            >
                              <Button
                              
                                variant="outline-primary" // Set default variant
                                onClick={() =>
                                  handleSubCategoryCheckboxChange(option)
                                }
                                style={{
                                  textTransform: "unset", // Capitalize text
                                  border: "1px solid grey", // Removed !important
                                  whiteSpace: "nowrap", // Prevent text wrapping
                                  color: selectedSubCategory.includes(option)
                                    ? "white"
                                    : "grey", // Set text color based on selection
                                  backgroundColor: selectedSubCategory.includes(
                                    option
                                  )
                                    ? "rgb(245, 99, 88)"
                                    : "transparent", // Set background color based on selection
                                }}
                              >
                                {option.label}
                              </Button>
                            </div>
                          ) : null
                        )
                      ) : (
                        <p>There are no subcategories available</p>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div class="" bis_skin_checked="1">
                <div class="" bis_skin_checked="1">
                  <div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2,1fr)",
                        margin: "auto",
                        width: "95%",
                        gap: "10px",
                        marginBottom: "15px",
                      }}
                    >
                      {loading ? (
                        <>
                          {/* Skeleton loader for each product card */}
                          {Array.from({ length: 4 }).map((_, index) => (
                            <div
                              key={index}
                              style={{
                                padding: "16px",
                                border: "1px solid #ddd",
                              }}
                            >
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={140}
                              />
                              <Skeleton width="60%" />
                              <Skeleton width="30%" />
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          {Array.isArray(sortedProductData) &&
                          sortedProductData.length !== 0 ? (
                            sortedProductData.map(
                              (item, index) =>
                                // Check if the status is true before rendering the product
                                item?.status === true && (
                                  <div
                                    key={index}
                                    className="MuiBox-root "
                                    bis_skin_checked="1"
                                    onClick={() => handleProductDetail(item)}
                                  >
                                    <div
                                      className="MuiBox-root css-w26f2k"
                                      bis_skin_checked="1"
                                    >
                                      <div style={{ position: "relative" }}>
  {item?.stripText && (
    <p
      className="strip-text"
      style={{
        backgroundColor: item?.stripColor || "transparent", // Default to transparent if color is missing
        position: "absolute",
        top: "", // Adjusted based on your layout
        right: 0,
        fontSize: "12px",
        zIndex: 1, // Ensures text appears on top
        padding: "2px", // More consistent padding
        borderRadius: "3px", // Optional for rounded edges
        color: "#fff", // Default text color for visibility
      }}
    >
      {item.stripText}
    </p>
  )}
</div>

                                      <div
                                        className="MuiBox-root css-14c5dc4"
                                        bis_skin_checked="1"
                                      >
                                        {item?.inStockQuantity == 0 && (
                                          <>
                                            <div
                                              className=""
                                              style={{
                                                width: "20px",
                                                position: "relative",
                                              }}
                                            >
                                              <img
                                                src={outofstock}
                                                style={{
                                                  width: "100px",
                                                  position: "absolute",
                                                  top: "20px",
                                                  zIndex: "9",
                                                }}
                                              />
                                            </div>
                                          </>
                                        )}
                                        <div
                                          className="MuiBox-root css-1om2adh"
                                          bis_skin_checked="1"
                                          style={{ paddingTop: "100%" }}
                                        >
                                          {item && item.productImagesPath && (
                                            <img
                                              src={item.productImagesPath[0]}
                                              alt=""
                                              data-srcset={
                                                item.productImagesPath[0]
                                              }
                                              sizes=""
                                              className="lazy loaded"
                                              style={{
                                                height: "100%",
                                                width: "100%",
                                                visibility: "visible",
                                              }}
                                              srcSet={item.productImagesPath[0]}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="MuiBox-root css-ldca19"
                                      bis_skin_checked="1"
                                      style={{ width: "100%" }}
                                    >
                                      <p
                                        className=""
                                        style={{
                                          marginTop: "15px",
                                          fontSize: "11px",
                                          display: "-webkit-box",
                                          WebkitLineClamp: 2, // Limit to 2 lines
                                          WebkitBoxOrient: "vertical",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {item?.productTitle}
                                      </p>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "",
                                        }}
                                      >
                                        <p
                                          className="MuiTypography-root MuiTypography-Small_Medium"
                                          style={{
                                            background: "rgb(245, 99, 88)",
                                            padding: "2px",
                                            borderRadius: "5px",
                                            color: "white",
                                            fontSize: "10px",
                                          }}
                                        >
                                          -
                                          {calculateDiscouunt(
                                            item?.twelveMonth,
                                            item?.perMonth
                                          )}
                                          % off
                                        </p>
                                        <s
                                          style={{
                                            display: "",
                                            justifyContent: "end",
                                            position: "relative",
                                            left: "5px",
                                            top: "5px",
                                            fontSize: "11px",
                                            marginBottom: "4px",
                                          }}
                                        >
                                          ₹{item?.perMonth}/mo
                                        </s>
                                      </div>

                                      <p className="MuiTypography-root MuiTypography-body1 css-2kxnf0">
                                        ₹ {item?.twelveMonth}
                                      </p>
                                      <div
                                        className="MuiBox-root css-ov56kn"
                                        bis_skin_checked="1"
                                      >
                                        <p className="MuiTypography-root MuiTypography-body1 css-1ws68lr">
                                          ₹ {item.price / 10}
                                        </p>
                                      </div>
                                      <div
                                        className=""
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p className="MuiTypography-root MuiTypography-body1 css-1ko6fke">
                                          Get it by {mydata(item.availabeDay)}
                                        </p>

                                        <ArrowForward />
                                      </div>
                                    </div>
                                  </div>
                                )
                            )
                          ) : (
                            // <p style={{ color: "red" }}>No products found</p>
                            <></>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {isMobile && (
                  <>
                    {/* 1 */}
                    <Offcanvas
                      placement="bottom"
                      style={{
                        width: "100%",
                        height: "50%",
                        borderRadius: "10px",
                      }}
                      show={show1}
                      onHide={handleClose1}
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Price Range</Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <Dropdown
                          show={!priceRangeOpen}
                          onToggle={setPriceRangeOpen}
                          id={priceRangeId}
                        >
                          <Dropdown.Menu className="sort">
                            <ul
                              style={{ padding: "16px", listStyleType: "none" }}
                            >
                              {Array.isArray(priceOptionsArray) &&
                                priceOptionsArray?.map((option) => (
                                  <li
                                    key={option}
                                    style={{
                                      marginBottom: "8px",
                                      cursor: "pointer", // To indicate the whole li is clickable
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() =>
                                      handlePriceRangeCheckboxChange(option)
                                    } // Single click handler on li
                                  >
                                    <Form.Check
                                      type="checkbox"
                                      checked={selectedPriceRange.includes(
                                        option
                                      )}
                                      onChange={() =>
                                        handlePriceRangeCheckboxChange(option)
                                      } // Ensures the checkbox toggles correctly
                                      onClick={(e) => e.stopPropagation()} // Prevents the click from bubbling to the li
                                      label={option}
                                      style={{
                                        marginRight: "8px",
                                        pointerEvents: "none",
                                      }} // Prevent checkbox from intercepting events
                                    />
                                  </li>
                                ))}
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Offcanvas.Body>
                    </Offcanvas>

                    {/* 2 */}
                    <Offcanvas
                      placement="bottom"
                      style={{
                        width: "100%",
                        height: "55%",
                        borderRadius: "10px",
                      }}
                      show={show}
                      onHide={handleClose}
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Sort</Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <Dropdown
                          show={!sortOpen}
                          onToggle={setSortOpen}
                          id={sortId}
                        >
                          <Dropdown.Menu>
                            <ul
                              style={{ padding: "16px", listStyleType: "none" }}
                            >
                              {sortOptions.map((option) => (
                                <li
                                  key={option}
                                  style={{
                                    marginBottom: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleSortCheckboxChange(option)
                                  } // Make the entire item clickable
                                >
                                  <Form.Check
                                    type="checkbox"
                                    checked={selectedSortE.includes(option)}
                                    onChange={() =>
                                      handleSortCheckboxChange(option)
                                    }
                                    label={option}
                                    style={{ pointerEvents: "none" }} // Disable the default checkbox click
                                  />
                                </li>
                              ))}
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Offcanvas.Body>
                    </Offcanvas>
                    <div class="MuiBox-root css-8atqhb" bis_skin_checked="1">
                      <div className="Moreflex">
                        <div
                          class="MuiBox-root css-na2bnx"
                          bis_skin_checked="1"
                          style={{ gap: "0px" }}
                        >
                          <div
                            class="MuiBox-root css-1fs6avd"
                            bis_skin_checked="1"
                            style={{ background: "" }}
                          >
                            <div
                              class="MuiBox-root css-1kjzbsf"
                              bis_skin_checked="1"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                background: "rgb(245, 99, 88)",
                                padding: "18px",
                              }}
                              onClick={handleShow}
                            >
                              <SwapVertIcon
                                className="item"
                                style={{
                                  height: "30px",
                                  width: "",
                                  color: "white",
                                }}
                              />
                              <div>
                                <div
                                  className="item"
                                  style={{ color: "white" }}
                                >
                                  Filter
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="MuiBox-root css-1fs6avd"
                            bis_skin_checked="1"
                          >
                            <div
                              class="MuiBox-root css-1kjzbsf"
                              bis_skin_checked="1"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                background: "rgb(245, 99, 88)",
                                padding: "18px",
                              }}
                              onClick={handleShow2}
                            >
                              <TuneIcon
                                style={{
                                  height: "25px",
                                  width: "20px",
                                  color: "white",
                                }}
                              />
                              <div>
                                <div
                                  className="item"
                                  style={{ color: "white" }}
                                >
                                  Price
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div class="MuiBox-root css-c88jtm" bis_skin_checked="1">
                <div class="MuiBox-root css-0" bis_skin_checked="1">
                  <div
                    class="MuiBox-root css-1gwz7tv"
                    bis_skin_checked="1"
                  ></div>
                </div>
                <div class="MuiBox-root css-0" bis_skin_checked="1">
                  <div
                    class="MuiBox-root css-1gwz7tv"
                    bis_skin_checked="1"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          class="MuiContainer-root MuiContainer-axWidthXl css-1epolx4"
          bis_skin_checked="1"
        >
          <div class="MuiBox-root css-0" bis_skin_checked="1">
            <div class="MuiBox-root css-qbi8c0" bis_skin_checked="1">
              {
                // console.log(mainCategoryData,'mainCategoryData')
              }
             {
              loading?(
                <>
                <Box
          sx={{
            display: "grid",
            gridTemplateColumns: `repeat(${mainCategoryData?.length}, 1fr)`, // Dynamic number of columns based on data length
            gap: "10px", // Space between boxes
            margin: "auto",
            width: "95%",
          }}
        >
          {Array.from({ length: mainCategoryData?.filter((item) => item?.status !== false).length }).map((_, index) => (
            <Box
              key={index}
              sx={{
              
                border: "1px solid #ddd",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start", // Align items to the start (left)
              }}
            >
              <Skeleton variant="rectangular" width="100%" height={50} style={{borderRadius:'10px'}}/>
              <Skeleton width="100%" style={{width:'120px',borderRadius:'15px'}} />
              <Skeleton width="100%" />
            </Box>
          ))}
        </Box>

                </>
              ):(
                <>
                <div class="MuiBox-root css-qbi8c0" bis_skin_checked="1">
                { Array.isArray(mainCategoryData) && mainCategoryData
                  ?.filter((item) => item?.status !== false)
                  ?.map((item, index) => (
                    <div
                      key={index}
                      onClick={() =>
                        handleCardClick(
                          index,
                          item.categoryId,
                          item.categoryName
                        )
                      }
                    >
                      {Number(selectedCard) === Number(item.categoryId) ? (
                        <div
                          className="MuiBox-root css-ocf0bf"
                          bis_skin_checked="1"
                        >
                          <div
                            className="MuiBox-root css-15ro776"
                            bis_skin_checked="1"
                          >
                            <svg
                              width="12"
                              height="12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10 3 4.5 8.5 2 6"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <p className="MuiTypography-root MuiTypography-body1 css-heejq2">
                            {item.categoryName}
                          </p>
                        </div>
                      ) : (
                        <div
                          className={`MuiBox-root css-1492rz`}
                          bis_skin_checked="1"
                        >
                          <p className="MuiTypography-root MuiTypography-body1 css-h6pkw8">
                            {item.categoryName}
                          </p>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
                </>
              )
             }
              
            </div>
            <div class="MuiBox-root css-jxqdoq" bis_skin_checked="1">
              <div
                class="MuiBox-root css-12h61o9"
                bis_skin_checked="1"
                style={{ gap: "0px" }}
              >
                <div
                  class=""
                  bis_skin_checked="1"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <p class="MuiTypography-root MuiTypography-body1 css-1awhple">
                    FILTERS{" "}
                  </p>
                  <div class="MuiBox-root css-iuzuyl" bis_skin_checked="1">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <div>
                            <Dropdown
                              ref={dropdownRef2}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              show={subCategoryOpen}
                            >
                              <Dropdown.Toggle
                                id="dropdown-subcategory"
                                aria-expanded={subCategoryOpen}
                              >
                                Sub-Category
                              </Dropdown.Toggle>
                              <Dropdown.Menu show={subCategoryOpen}>
                                {Array.isArray(
                                  getSubCategoryDropdownByIdData
                                ) &&
                                getSubCategoryDropdownByIdData.length !== 0 ? (
                                  getSubCategoryDropdownByIdData.map((option) =>
                                    option?.status ? (
                                      <div
                                        key={option.id}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          cursor: "pointer",
                                          padding: "5px",
                                        }}
                                        onClick={() =>
                                          handleSubCategoryCheckboxChange(
                                            option
                                          )
                                        }
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          checked={selectedSubCategory.includes(
                                            option
                                          )}
                                          onChange={() =>
                                            handleSubCategoryCheckboxChange(
                                              option
                                            )
                                          }
                                          onClick={(e) => e.stopPropagation()} // Stop event propagation to prevent double toggle
                                          style={{ marginRight: "8px" }}
                                        />
                                        <span>{option.label}</span>
                                      </div>
                                    ) : null
                                  )
                                ) : (
                                  <Dropdown.Item disabled style={{fontSize:'14px',wordSpacing:'2px'}}>
                                     No subcategories available
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                        {/* <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                                            <div>
                                                                <Dropdown ref={dropdownRef}>
                                                                    <Dropdown.Toggle
                                                                        aria-expanded={superCategoryOpen}
                                                                        onClick={handleOpenSuperCategoryClick}
                                                                        endIcon={<FilterAltIcon />}
                                                                        className='css-1x9pq6u'
                                                                    >
                                                                        Super Category
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu show={superCategoryOpen}>
                                                                        <ul style={{ listStyleType: 'none' }}>
                                                                            {Array.isArray(getSuperCategoryDropdownByIdData) && getSuperCategoryDropdownByIdData.length !== 0 &&
                                                                                getSuperCategoryDropdownByIdData.map((option) => (
                                                                                    option.status === false ? null :
                                                                                        <li key={option.id} style={{ marginBottom: '8px' }}>
                                                                                            <Form.Check
                                                                                                type="checkbox"
                                                                                                id={`super-category-checkbox-${option.id}`}
                                                                                                label={option.label}
                                                                                                checked={selectedsuperSubCategory.includes(option)} // Ensure that selectedSubCategory includes the current option
                                                                                                onChange={() => handleSuperCategoryCheckboxChange(option)}
                                                                                            />
                                                                                        </li>
                                                                                ))}
                                                                        </ul>
                                 {Array.isArray(getSuperCategoryDropdownByIdData)&& getSuperCategoryDropdownByIdData.length!==0?(<></>):(<>
                                    <Dropdown.Item disabled>There are no super categories available</Dropdown.Item>
                                 
                                 </>)}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </div> */}
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <div>
                            <Dropdown
                              ref={dropdownRef}
                              show={priceRangeOpen}
                              onMouseEnter={handleMouseEnter2}
                              onMouseLeave={handleMouseLeave2}
                            >
                              <Dropdown.Toggle
                                id="dropdown-subcategory"
                                variant="outlined"
                                endIcon={<FilterAltIcon />}
                                style={{ color: "white" }}
                              >
                                Price Range
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="sort">
                                <ul
                                  style={{
                                    padding: "16px",
                                    listStyleType: "none",
                                  }}
                                >
                                  {Array.isArray(priceOptionsArray) &&
                                    priceOptionsArray?.map((option) => (
                                      <li
                                        key={option}
                                        style={{
                                          marginBottom: "8px",
                                          cursor: "pointer",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        onClick={() =>
                                          handlePriceRangeCheckboxChange(option)
                                        } // Click event on the entire li (text + checkbox)
                                      >
                                        <input
                                          type="checkbox"
                                          style={{
                                            marginRight: "8px", // To add space between checkbox and label
                                            cursor: "pointer",
                                          }}
                                          checked={selectedPriceRange.includes(
                                            option
                                          )}
                                          onChange={() =>
                                            handlePriceRangeCheckboxChange(
                                              option
                                            )
                                          }
                                          onClick={(e) => e.stopPropagation()} // Prevent checkbox click from triggering the li click twice
                                        />
                                        <span style={{ cursor: "pointer" }}>
                                          {option}
                                        </span>
                                      </li>
                                    ))}
                                </ul>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <div>
                            <Dropdown
                              ref={dropdownRef}
                              show={sortOpen}
                              onMouseEnter={handleMouseEnter3}
                              onMouseLeave={handleMouseLeave3}
                            >
                              <Dropdown.Toggle
                                id="dropdown-subcategory"
                                aria-describedby={sortId}
                                endIcon={<CategoryIcon />}
                              >
                                {selectedSortOption
                                  ? selectedSortOption
                                  : "Sort By"}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <ul
                                  style={{
                                    padding: "16px",
                                    listStyleType: "none",
                                  }}
                                >
                                  {/* Remove duplicates using Set */}
                                  {[...new Set(sortOptions)].map((option) => (
                                    <li
                                      key={option}
                                      style={{
                                        display: "flex",
                                        marginBottom: "8px",
                                        cursor: "pointer", // Change cursor to pointer for better UX
                                      }}
                                      onClick={() =>
                                        handleSortCheckboxChange(option)
                                      } // Make the entire li clickable
                                    >
                                      <Form.Check
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          marginRight: "8px", // Adds space between checkbox and label
                                        }}
                                        type="checkbox"
                                        checked={selectedSortE.includes(option)}
                                        onChange={() =>
                                          handleSortCheckboxChange(option)
                                        } // Handle checkbox change
                                        onClick={(e) => e.stopPropagation()} // Prevent double triggering
                                      />
                                      <span>{option}</span>{" "}
                                      {/* Label for the option */}
                                    </li>
                                  ))}
                                </ul>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)", // 3 boxes per row
                    gap: "10px", // Space between boxes
                    margin: "auto",
                    width: "95%",
                  }}
                >
                  {Array.from({ length: 4 }).map((_, index) => (
                    <div
                      key={index}
                      style={{
                        padding: "16px",
                        border: "1px solid #ddd",
                        display: "flex",
                        flexDirection: "column", // Stack the skeleton vertically
                        alignItems: "flex-start", // Align items to the start (left)
                      }}
                    >
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={140}
                      />
                      <Skeleton width="60%" />
                      <Skeleton width="30%" />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
              {
                // console.log(sortedProductData,'sortedProductData')
              }
             <div className="MuiBox-root css-6djc" bis_skin_checked="1">
  {Array.isArray(sortedProductData) && sortedProductData.length !== 0 ? (
    sortedProductData.map(
      (item, index) =>
        // Render only products with status true
        item?.status === true && (
          <div
            key={index}
            className="MuiBox-root css-18q7liu singleproduct"
            bis_skin_checked="1"
            onClick={() => handleProductDetail(item)}
          >
            <div
              className="MuiBox-root css-w26f2k"
              bis_skin_checked="1"
              style={{ position: "relative" }}
            >
              {item?.inStockQuantity == 0 && (
                <div
                  style={{
                    width: "20px",
                    position: "relative",
                  }}
                >
                  <img
                    loading="lazy"
                    src={outofstock}
                    style={{
                      width: "100px",
                      position: "absolute",
                      top: "",
                      zIndex: "9",
                    }}
                  />
                </div>
              )}
              {item && item.productImagesPath && (
                <div style={{ position: "relative" }}>
                  {/* Strip Text */}
                  {item?.stripText && (
                    <p
                      className="MuiTypography-root MuiTypography-Small_Medium css-11je29n"
                      style={{
                        backgroundColor: `${item?.stripColor}`,
                        position: "absolute",
                        bottom: "-6px", // Position at the bottom of the image
                        left: "10px", // Small padding from left
                        zIndex: 2, // Ensure it appears above the image
                        padding: "5px",
                        color: "white",
                        fontSize: "12px",
                        borderRadius: "4px",
                      }}
                    >
                      {item?.stripText}
                    </p>
                  )}

                  {/* Product Image */}
                  <div
                    className="MuiBox-root css-1om2adh"
                    bis_skin_checked="1"
                    style={{ paddingTop: "100%", position: "relative" }}
                  >
                    <img
                      src={item.productImagesPath[0]}
                      alt=""
                      className="lazy loaded"
                      style={{
                        height: "100%",
                        width: "100%",
                        visibility: "visible",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Product Details */}
            <div
              className="MuiBox-root css-ldca19"
              bis_skin_checked="1"
              style={{ width: "100%" }}
            >
              <p
                style={{
                  margin: "0px",
                  display: "-webkit-box",
                  WebkitLineClamp: 2, // Limit to 2 lines
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
                className=""
              >
                {item?.productTitle}
              </p>
              <div
                style={{
                  display: "flex",
                  gap: "6px",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "5px",
                }}
              >
                <p className="MuiTypography-root MuiTypography-Small_Medium css-11je29n">
                  -{calculateDiscouunt(item?.twelveMonth, item?.perMonth)}% off
                </p>
                <p>
                  <s>₹{item?.perMonth}/mo</s>
                </p>
              </div>
              <p className="MuiTypography-root MuiTypography-body1 css-2kxnf0">
                ₹ {item?.twelveMonth}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div className="MuiBox-root css-ov56kn" bis_skin_checked="1">
                    <p className="MuiTypography-root MuiTypography-body1 css-1ws68lr">
                      ₹ {item.price / 10}
                    </p>
                  </div>
                  <p className="MuiTypography-root MuiTypography-body1 css-1ko6fke">
                    Get it by {mydata(item.availabeDay)}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <ArrowForward />
                </div>
              </div>
            </div>
          </div>
        )
    )
  ) : (
    <></>
  )}
</div>

              </>
            )}
          </div>
        </div>
      )}
    </div>
  ) : (
    ""
  );
}

const mapStateToProps = (state, ownProps) => {
  // console.log("stategetProductsByFilter -------->", state.getProductsByFilter);
  return {
    ProductFilterData: state.getProductsByFilter.data,
    error: state.getProductsByFilter.error,
    loading: state.getProductsByFilter.loading,
    mainCategoryData: state.getAllMainCategory.data,
    getAllMainCategoryDropdownData: state.getAllMainCategoryDropdown.data,
    getSubCategoryDropdownByIdData: state.getSubCategoryDropdownById.data,
    getSuperCategoryDropdownByIdData: state.getSuperCategoryDropdownById.data,
    mainCategoryDataPreSelected: state.setMainCateogryData.data,
    userSelectedPincode: state.setCityPincode.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleGetProducts: (filterData, keyword) =>
      dispatch(fetchProductsByFilter(filterData, keyword)),

    handleFetchAllMainCategory: () => dispatch(fetchAllMainCategory()),
    handleFetchAllMainCategoryDropdown: () =>
      dispatch(fetchAllMainCategoryDropdown()),
    handleFetchAllSubCategoryDropdown: (id) =>
      dispatch(fetchAllSubCategoryDropdown(id)),
    setMainCategoryData: (data) =>
      dispatch({ type: "SET_MAIN_CATEGORY_DATA", payload: data }),
    // handleFetchAllSuperCategoryDropdown: (id) => dispatch(fetchAllSuperCategoryDropdown(id)),
    setCityPincodeData: (data) =>
      dispatch({ type: "SET_CITY_PINCODE_DATA", payload: data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductListing);
