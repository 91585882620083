import React, {useEffect} from "react";
import logo from '../../../style/images/logo.png'
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";


const ProcessedToPay = () => {
    const getCartProductsData = useSelector((state) => state.getProductById.data)
    const isRenewlPlan = localStorage.getItem('isRenewlPlan')
    const isNavigationEnabled2 = false; // Set it to true or false based on your condition
    const navigate = useNavigate();

    const location = useLocation();
    useEffect(() => {
        const links = document.querySelectorAll('.item');
        links.forEach(link => {
            if (link.pathname === location.pathname) {
                link.classList.add('active');
            } else {
                link.classList.remove('active');
            }
        });
    }, [location])

    // Navigation is enabled only if isRenewlPlan is false
    const isNavigationEnabled = !isRenewlPlan; // Disable navigation if isRenewlPlan is true

    const handleLinkClick = (e) => {
        if (!isNavigationEnabled) {
            e.preventDefault(); // Prevent the link from being followed
            console.log("Navigation is disabled because it's a renewal plan");
        } else {
            if (!(location.pathname.includes('Address'))) {
                navigate(-1)
            }
        }
    };
    const handleLinkClick2 = (e) => {
        if (!isNavigationEnabled2) {
            e.preventDefault(); // Prevent the link from being followed
            console.log("Navigation is disabled because it's a renewal plan");
        }
    };

    return (
        <>
            <style>
                {
                    `
                .one:after{
content:'---------------------------'
                }
                a.one.item.active {
                    color: rgb(245, 99, 88);
                }
                @media (max-width:768px){
                  .one:after{
                    display:none;
                  }
                  .myheahder {
                    justify-content: center !important;
                }
                .k{
                  margin-top:10px;
                }
                }
        
                `
                }
            </style>
            <div className="myheahder" style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '20px',
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                flexWrap: 'wrap'
            }}>
                <div>
                    <NavLink to='/' style={{width: "250px"}}>
                        <img src={logo} style={{width: '150px'}}/>
                    </NavLink>
                </div>
                <div className="k" style={{
                    display: 'flex',
                    gap: '50px',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    position: 'relative',
                    top: '11px'
                }}>
                    <NavLink
                        // to='/Addresss'
                        className={`one item ${isNavigationEnabled ? '' : 'disabled'}`} // Add 'disabled' class conditionally
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            pointerEvents: isNavigationEnabled ? 'auto' : 'none', // Disable pointer events if navigation is not allowed
                            opacity: isNavigationEnabled ? 1 : 0.5, // Optional: Adjust opacity to show it's disabled
                        }}
                        onClick={handleLinkClick} // Call the event handler on click
                    >
                        <AddHomeWorkIcon/>
                        Address
                    </NavLink>

                    <NavLink
                        to='/MyOrderSummary'
                        className={`one item ${isNavigationEnabled2 ? '' : 'disabled-link'}`} // Apply disabled styles conditionally
                        style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
                        onClick={handleLinkClick2} // Call the event handler on click
                    >
                        <AddHomeWorkIcon/>
                        Order Summary
                    </NavLink>
                </div>
                <div></div>
                <div></div>

            </div>
        </>
    )
}

export default ProcessedToPay