import React ,{useEffect}from 'react'

function Privacy_Policy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div style={{padding:"15px",marginTop:'30px'}}>
        <h1>Privacy Policy</h1>
    <p><strong>DAY DREAM FUTURE REALITY INFRATECH PVT LTD</strong> (“RENTYFURNITURE,” “Company,” “we,” “us,” and “our”), a company incorporated under the Companies Act, 1956, operates from 17018 Pine Tower, Mahagun Mywoods, Greater Noida West, Noida 201301, U.P., India. We own and operate the website <a href="https://www.RENTYFURNITURE.com">https://www.RENTYFURNITURE.com</a> and the RENTYFURNITURE mobile application (collectively, “RENTYFURNITURE Platform,” “Platform”).</p>

    <p>This Privacy Policy (“Policy”) applies to all users of the RENTYFURNITURE Platform, including suppliers, distributors, and other third parties interacting with the Company. In this Policy, users are referred to as “You” and/or “Your.” This Policy outlines how we collect, store, share, and analyze data from You when you access the RENTYFURNITURE Platform. By using our Platform, You accept this Privacy Policy and agree to be legally bound by it. If You use the RENTYFURNITURE Platform on behalf of someone else, you represent that you are authorized to (i) accept this Privacy Policy on their behalf and (ii) consent on their behalf to our collection, use, and disclosure of their Personal Information as described in this Policy.</p>

    <h5>Collection of Personal Information</h5>
    <p>To use our Services/Products or contact RENTYFURNITURE, you may need to provide personal information such as your email address, name, phone number, gender, and mailing address. You agree to provide accurate, up-to-date, and complete information. This information, which may include sensitive personal information (“Personal Information”), is collected in compliance with applicable laws for identity verification and statutory compliance. We use your contact information to send you offers based on your previous orders and interests.</p>
    User Data
<h5>Data Collection:</h5> 

Personal Data: Our app collects the following personal data when you register or interact with the app:<br/>
Name: Used to personalize your experience within the app.<br/>
Email Address: Used for account creation, login, and communication purposes such as sending important updates or notifications.<br/>
Phone Number: Used for login and authentication purposes, as well as for communication such as account verification and customer support.<br/>
Aadhaar and PAN Card Information: Collected for the purpose of Know Your Customer (KYC) verification to comply with legal requirements and ensure the authenticity of user accounts.<br/>
<h5>Data Usage:</h5>

The personal data collected is used for the following purposes:<br/>
To create and manage your account.<br/>
To verify your identity and comply with legal KYC requirements.<br/>
To provide customer support and respond to your inquiries.<br/>
To send you important notifications and updates about the app.<br/>
To improve app functionality and user experience.<br/>
3. Data Sharing:

We do not share your personal data with third parties, except in the following cases:
Service Providers: We may share your data with third-party service providers who assist us in KYC verification, operating the app, or providing services to you. These providers are contractually obligated to protect your data and use it only for the purposes for which it was shared.<br/>
Legal Requirements: We may disclose your data if required to do so by law or in response to valid requests by public authorities.
4. Data Security:<br/>

We implement industry-standard security measures to protect your personal data from unauthorized access, disclosure, or misuse. This includes encryption and secure storage of Aadhaar and PAN card information. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security.
<h5>User Rights:</h5> 

You have the right to access, correct, or delete your personal data, including your Aadhaar and PAN card information. If you wish to exercise any of these rights, please contact us at complain@rentyfurniture.com. We will respond to your request in accordance with applicable laws
    <p>Additionally, when you browse the RENTYFURNITURE Platform, our servers may automatically record information such as the domain name and host from which you access the Internet, your Internet Protocol (IP) address, and the date and time of your access. This data is anonymized and de-identified, processed in aggregate to monitor and analyze Platform usage.</p>

    <h5>Use and Disclosure of Personal Information</h5>
    <p>RENTYFURNITURE uses your Personal Information to enhance your customer experience and manage our business. This may include administering services, personalizing Platform experiences, enabling access to website services, publishing information about you, updating you on products and services, sending statements and invoices, collecting payments, and communicating marketing materials through various channels. We may also use your information for customer satisfaction surveys, market research, promotional activities, or in connection with certain transactions.</p>

    <p>We may disclose Personal Information to third-party service providers to facilitate Services/Products on the Platform, such as logistics partners for delivery. We ensure these providers adhere to confidentiality obligations and industry standards.</p>

    <p>Disclosure of Personal Information may also occur under legal requirements, judicial decrees, or when necessary to protect our or others' rights, prevent harm, or combat fraud. In the event of a reorganization or asset sale, Personal Information may be transferred to a third party, which will maintain appropriate confidentiality and security measures.</p>

    <p>Currently, we do not transfer Personal Information internationally. However, our server providers may transfer data to countries where they operate. By using our Platform, you consent to such cross-border transfers and understand the associated risks. We are not responsible for the security of data during these transfers.</p>

    <p>We use Microsoft Clarity and Microsoft Advertising for behavioral metrics, heatmaps, and session replay to improve and market our services. We also use moEngage to analyze interactions with our apps and website. For more details, refer to the <a href="https://privacy.microsoft.com/en-us/privacystatement">Microsoft Privacy Statement</a> and <a href="https://www.moengage.com/privacy-policy/">Moengage Privacy Policy</a>.</p>

    <h5>Cookies</h5>
    <p>We may use cookies to store information on your computer. Cookies facilitate your use of our Platform but do not store Personal Information. You can choose to block or erase cookies, which may limit Platform features. Cookies help us personalize your experience and may assist in user identification without logging in.</p>

    <h5>Links</h5>
    <p>Our Platform may contain links to third-party sites that may collect data or solicit personal information. We have no control over these sites and are not responsible for their privacy practices or content. We recommend reviewing the privacy policies of these sites before using them.</p>

    <h5>Commitment to Personal Information Security</h5>
    <p>We strive to keep your Personal Information confidential and use reasonable technical and organizational precautions to prevent its loss, misuse, or alteration. However, we do not guarantee complete protection against unauthorized access, loss, misuse, or alterations. We are not liable for the security of Personal Information or its misuse by third parties.</p>

    <h5>Payment Gateway</h5>
    <p>We use third-party payment gateways to process transactions on our Platform. We do not control or access the information entered for payment transactions. Any issues or disputes with payment gateways are the responsibility of the payment gateway providers. We disclaim any liability for issues related to these payment gateways.</p>

    <h5>Security</h5>
    <p>We use industry-standard physical, electronic, and procedural safeguards to protect your information. Personal Information is encrypted during storage and processing.</p>

    <h5>Log Data</h5>
    <p>We collect Log Data, including device IP addresses, device names, operating system versions, and usage statistics, to monitor and address errors on the Platform.</p>

    <h5>Children’s Privacy</h5>
    <p>Our Services are not directed to individuals under 18. We do not knowingly collect Personal Information from children under 18. If we learn that such information has been provided, we will delete it. Parents or guardians should contact us if they are aware their child has provided such information.</p>

    <h5>Opt-Out and Deletion Policy</h5>
    <p>You may access, amend, or request deletion of your Personal Information by contacting us at <a href="mailto:customercare@RENTYFURNITURE.com">customercare@RENTYFURNITURE.com</a>. You may also opt out of non-essential communications. Note that deletion of certain information may result in the cancellation of your registration or access to certain features.</p>

    <h5>Changes to the Privacy Policy</h5>
    <p>We reserve the right to modify this Privacy Policy at any time. Changes will be posted on the RENTYFURNITURE Platform. Please review this Policy periodically to stay informed of any updates.</p>

    <h5>About this Privacy Policy</h5>
    <p>Your visit to our Platform is subject to this Privacy Policy and our Terms of Use. For questions about this Policy or our treatment of Personal Information, please contact <a href="mailto:customercare@RENTYFURNITURE.com">customercare@RENTYFURNITURE.com</a>.</p>

    <h5>Grievances</h5>
    <p>For privacy-related complaints or concerns, please contact our Grievance Officer, Mr. Uma Shankar, at <a href="mailto:grievance@RENTYFURNITURE.com">grievance@RENTYFURNITURE.com</a>. Disputes regarding this Privacy Policy are subject to the exclusive jurisdiction of Courts in Noida.</p>
        </div>
    )
}

export default Privacy_Policy