import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../style/css/footer.css";
import FaceBookicon from "../../style/images/socialmedia/facebook-circular.png";
import InstagramIcon from "../../style/images/socialmedia/insta.png";
import LinkdinIcon from "../../style/images/socialmedia/linkedin.png";
import TwitterIcon from "../../style/images/socialmedia/twitter.png";
import { fetchAllCity } from "../../actions/city/getAllCity";
import { useDispatch, useSelector } from "react-redux";

import lofoo from "./logo.png";
function Footer() {
  const isMobile = window.innerWidth <= 768;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.getAllCity.data);
  console.log(data, "ggggggggggggggg");
  // useEffect(() => {
  //     dispatch(fetchAllCity())
  // }, [dispatch])
  const navigate = useNavigate();

  const handleReload = () => {
    // window.location.reload();
  };
  const isWeb = true;
  const handleUrl = (url) => {
    navigate(url);
    // window.location.reload();
  };
  const handleSupport = (url) => {
    const login = localStorage.getItem("customerId");
    if (!login) {
      return navigate("/login");
    }

    navigate(url);
    // window.location.reload();
  };

  return (
    <>
      <style>
        {`
                .MuiBox-root.css-2fy7ln.desktoheader {
    background: beige;
}

footer{
background:beige !important;
}
                  .mobileheader{
                    display:none;
                    }

                    @media(max-width:768px){
                  .mobileheader{
                    display:block;
                    }     
                    .desktoheader{
                    display:none;
                    }               
                    }




                `}
      </style>

     
      {isMobile && (
        <>
          <div class="MuiBox-root css-0" bis_skin_checked="1">
            <footer
              class="MuiBox-root css-15ap2hb mb-4 djdjksjbs"
              style={{ background: "beige" }}
            >
              {/* <svg width="133" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.877 6.537h-.01c-.836-.005-1.51-.699-1.512-1.553V0H0v4.98c0 .966.384 1.89 1.066 2.56A3.592 3.592 0 0 0 0 10.103V15h2.347v-4.897c.002-.854.676-1.547 1.512-1.555H12v-2.01H3.877Z" fill="#069BAA"></path>
                                <path d="M10.91 0H4.912C4.132 0 4 .114 4 .79v2.42c0 .676.132.79.912.79h5.998c.78 0 1.09-.114 1.09-.79V.787C12 .109 11.69 0 10.91 0ZM29 0v9.113c0 1.954-.512 3.424-1.538 4.41-1.025.986-2.516 1.478-4.474 1.477-1.927 0-3.408-.491-4.44-1.473-1.033-.982-1.55-2.454-1.548-4.414V0h2.382v8.812c0 1.388.297 2.418.89 3.09.595.672 1.5 1.008 2.716 1.008 1.233 0 2.144-.336 2.736-1.008.59-.672.89-1.702.894-3.09V0H29Z" fill="#069BAA"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M43.94 8.615 48 15h-2.737l-3.74-6.004h-3.161V15H36V0h5.99c1.574-.005 2.825.394 3.752 1.197.927.804 1.39 1.888 1.39 3.252 0 1.412-.464 2.499-1.39 3.262a4.746 4.746 0 0 1-1.801.904Zm-2.127-6.554h-3.451V6.97h3.451c.965.002 1.682-.197 2.15-.599.467-.402.7-1.02.7-1.853 0-.818-.233-1.432-.7-1.842-.468-.41-1.185-.615-2.15-.615Z" fill="#069BAA"></path>
                                <path d="M55.273 12.908H63V15H53V0h2.273v12.908ZM68 15V0h10.688v2.092h-8.295v4.317h6.484v2.046h-6.484v4.453H79V15H68ZM97 0v15h-2.817l-5.656-9.444-1.42-2.66h-.022l.09 2.432V15H85V0h2.795l5.633 9.432 1.443 2.704h.023l-.09-2.454V0H97ZM115 10.914a5.807 5.807 0 0 1-1.336 2.242 5.758 5.758 0 0 1-2.092 1.378 7.287 7.287 0 0 1-2.648.464 7.206 7.206 0 0 1-3.674-.903 6.072 6.072 0 0 1-2.404-2.581c-.565-1.117-.847-2.454-.846-4.012.001-1.558.283-2.897.846-4.018A6.068 6.068 0 0 1 105.25.905a7.159 7.159 0 0 1 3.65-.903 8.085 8.085 0 0 1 2.616.396A5.253 5.253 0 0 1 113.5 1.59a5.279 5.279 0 0 1 1.27 1.983l-2.247.992c-.296-.866-.727-1.502-1.291-1.907-.563-.405-1.296-.607-2.197-.606a4.413 4.413 0 0 0-2.383.639 4.245 4.245 0 0 0-1.591 1.853 6.944 6.944 0 0 0-.567 2.954 7.342 7.342 0 0 0 .533 2.95c.311.76.847 1.41 1.537 1.865.721.44 1.556.662 2.403.638a3.995 3.995 0 0 0 2.344-.694c.66-.463 1.13-1.158 1.412-2.084l2.277.741Z" fill="#069BAA"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M129.72.906c-1.052-.603-2.298-.904-3.737-.904a7.323 7.323 0 0 0-3.709.904 6.086 6.086 0 0 0-2.42 2.579c-.568 1.118-.853 2.457-.854 4.015-.001 1.558.283 2.896.854 4.013a6.086 6.086 0 0 0 2.424 2.581 7.322 7.322 0 0 0 3.709.904c1.437 0 2.681-.301 3.733-.904a6.097 6.097 0 0 0 2.426-2.583c.571-1.116.855-2.453.854-4.013-.001-1.56-.286-2.897-.854-4.013a6.096 6.096 0 0 0-2.426-2.58Zm-6.132 1.786a4.359 4.359 0 0 1 2.395-.639 4.405 4.405 0 0 1 2.417.646 4.11 4.11 0 0 1 1.541 1.854 7.25 7.25 0 0 1 .539 2.955 7.247 7.247 0 0 1-.539 2.943 4.099 4.099 0 0 1-1.541 1.865 4.852 4.852 0 0 1-4.812 0 4.102 4.102 0 0 1-1.539-1.865 7.232 7.232 0 0 1-.541-2.951 7.217 7.217 0 0 1 .541-2.955c.313-.754.849-1.4 1.539-1.853Z" fill="#069BAA"></path>
                            </svg> */}
              <img width="250px" height="auto" src={lofoo} alt="lofoo" />
              <p
                class="MuiTypography-root MuiTypography-body1 css-w4ihce"
                style={{ fontWeight: "bold" }}
              >
                Quick links
              </p>

              <div class="MuiBox-root css-sizs06" bis_skin_checked="1">
                <div class="MuiBox-root css-1e0k1sj" bis_skin_checked="1">
                  {/* <a class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-titybc" href="/terms-and-conditions">
                                        <p class="MuiTypography-root MuiTypography-body1 css-35txkw">T&amp;C's</p>
                                    </a>
                                    <a class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-titybc" href="/privacy-policy">
                                        <p class="MuiTypography-root MuiTypography-body1 css-35txkw">Privacy Policy</p>
                                    </a>
                                    <a class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-titybc" href="/about-us">
                                        <p class="MuiTypography-root MuiTypography-body1 css-35txkw">About us </p>
                                    </a> */}
                  <p
                    to="/"
                    class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-titybc"
                  >
                    <p class="MuiTypography-root MuiTypography-body1 css-35txkw">
                      Home
                    </p>
                  </p>

                  <Link
                    onClick={handleReload}
                    to="/about-us"
                    class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-titybc"
                  >
                    <p class="MuiTypography-root MuiTypography-body1 css-35txkw">
                      About Us
                    </p>
                  </Link>

                  <Link
                    to="/contact-us"
                    class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-titybc"
                  >
                    <p class="MuiTypography-root MuiTypography-body1 css-35txkw">
                      Contact
                    </p>
                  </Link>

                  <a
                    href="https://www.blog.rentyfurniture.com/"
                    target="_blank"
                    class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-z1euss"
                  >
                    <p class="MuiTypography-root MuiTypography-body1 css-35txkw">Blogs</p>
                  </a>

                  <Link
                    to="/document-required"
                    class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-titybc"
                  >
                    <p class="MuiTypography-root MuiTypography-body1 css-35txkw">
                      Documents Required
                    </p>
                  </Link>
                </div>
                <div class="MuiBox-root css-13q7rzz" bis_skin_checked="1">
                  {/* <a class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-titybc" href="/annual-returns">
                                        <p class="MuiTypography-root MuiTypography-body1 css-35txkw">Annual Returns</p>
                                    </a>
                                    <a class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-titybc" href="https://help.furlenco.com/hc/en-us/requests/new">
                                        <p class="MuiTypography-root MuiTypography-body1 css-35txkw">Submit a query</p>
                                    </a> */}
               
                  <Link
                    to="/dashboard/all_request"
                    class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-titybc"
                  >
                    <p class="MuiTypography-root MuiTypography-body1 css-35txkw">
                      Support
                    </p>
                  </Link>
                  <Link
                    to="/Privacy_Policy"
                    class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-titybc"
                  >
                    <p class="MuiTypography-root MuiTypography-body1 css-35txkw">
                      Privacy Policy
                    </p>
                  </Link>
                  <Link
                    to="/Cancellation_return"
                    class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-titybc"
                  >
                    <p class="MuiTypography-root MuiTypography-body1 css-35txkw">
                      Cancellation & Return
                    </p>
                  </Link>
                  <Link
                    to="/shipping_policy"
                    class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-titybc"
                  >
                    <p class="MuiTypography-root MuiTypography-body1 css-35txkw">
                      Shipping Policy
                    </p>
                  </Link>

                  <Link
                    to="/rental_terms_and_conditions"
                    class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-titybc"
                  >
                    <p class="MuiTypography-root MuiTypography-body1 css-35txkw">
                      Rental Term & Conditions
                    </p>
                  </Link>
                </div>
              </div>

              <p class="MuiTypography-root MuiTypography-body1 css-1mp4fn2">
                FOLLOW US ON
              </p>
              <div class="MuiBox-root css-66sjy9" bis_skin_checked="1">
                <a
                  class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-nauzol"
                  target="_blank"
                  href="https://www.facebook.com/rentyfurnituree"
                >
                  <div
                    class="MuiBox-root css-10fmmkm"
                    style={{ paddingTop: "0px" }}
                    bis_skin_checked="1"
                  >
                    <img
                      alt=""
                      style={{
                        height: "auto",
                        width: "100%",
                        visibility: "visible",
                      }}
                      data-srcset="https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_1.0,w_50/s3-furlenco-images/2.0/social-media/facebook-circular.jpg 1x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_2.0,w_50/s3-furlenco-images/2.0/social-media/facebook-circular.jpg 2x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_3.0,w_50/s3-furlenco-images/2.0/social-media/facebook-circular.jpg 3x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_4.0,w_50/s3-furlenco-images/2.0/social-media/facebook-circular.jpg 4x"
                      sizes=""
                      srcset="https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_1.0,w_50/s3-furlenco-images/2.0/social-media/facebook-circular.jpg 1x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_2.0,w_50/s3-furlenco-images/2.0/social-media/facebook-circular.jpg 2x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_3.0,w_50/s3-furlenco-images/2.0/social-media/facebook-circular.jpg 3x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_4.0,w_50/s3-furlenco-images/2.0/social-media/facebook-circular.jpg 4x"
                      class="loaded"
                    />
                  </div>
                </a>
                <a
                  class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-nauzol"
                  target="_blank"
                  href="https://www.instagram.com/renty_furniture/"
                >
                  <div
                    class="MuiBox-root css-10fmmkm"
                    style={{ paddingTop: "0px" }}
                    bis_skin_checked="1"
                  >
                    <img
                      alt=""
                      style={{
                        height: "auto",
                        width: "100%",
                        visibility: "visible",
                      }}
                      data-srcset="https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_1.0,w_50/s3-furlenco-images/2.0/social-media/insta.jpg 1x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_2.0,w_50/s3-furlenco-images/2.0/social-media/insta.jpg 2x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_3.0,w_50/s3-furlenco-images/2.0/social-media/insta.jpg 3x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_4.0,w_50/s3-furlenco-images/2.0/social-media/insta.jpg 4x"
                      sizes=""
                      srcset="https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_1.0,w_50/s3-furlenco-images/2.0/social-media/insta.jpg 1x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_2.0,w_50/s3-furlenco-images/2.0/social-media/insta.jpg 2x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_3.0,w_50/s3-furlenco-images/2.0/social-media/insta.jpg 3x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_4.0,w_50/s3-furlenco-images/2.0/social-media/insta.jpg 4x"
                      class="loaded"
                    />
                  </div>
                </a>
                <a
                  class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-nauzol"
                  target="_blank"
                  href="https://x.com/rentyfurniture"
                >
                  <div
                    class="MuiBox-root css-10fmmkm"
                    style={{ paddingTop: "0px" }}
                    bis_skin_checked="1"
                  >
                    <img
                      alt=""
                      style={{
                        height: "auto",
                        width: "100%",
                        visibility: "visible",
                      }}
                      data-srcset="https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_1.0,w_50/s3-furlenco-images/2.0/social-media/twitter.jpg 1x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_2.0,w_50/s3-furlenco-images/2.0/social-media/twitter.jpg 2x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_3.0,w_50/s3-furlenco-images/2.0/social-media/twitter.jpg 3x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_4.0,w_50/s3-furlenco-images/2.0/social-media/twitter.jpg 4x"
                      sizes=""
                      srcset="https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_1.0,w_50/s3-furlenco-images/2.0/social-media/twitter.jpg 1x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_2.0,w_50/s3-furlenco-images/2.0/social-media/twitter.jpg 2x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_3.0,w_50/s3-furlenco-images/2.0/social-media/twitter.jpg 3x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_4.0,w_50/s3-furlenco-images/2.0/social-media/twitter.jpg 4x"
                      class="loaded"
                    />
                  </div>
                </a>
                <a
                  class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-nauzol"
                  target="_blank"
                  href="https://www.linkedin.com/company/rentyfurniture/"
                >
                  <div
                    class="MuiBox-root css-10fmmkm"
                    style={{ paddingTop: "0px" }}
                    bis_skin_checked="1"
                  >
                    <img
                      alt=""
                      style={{
                        height: "auto",
                        width: "100%",
                        visibility: "visible",
                      }}
                      data-srcset="https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_1.0,w_50/s3-furlenco-images/2.0/social-media/linkedin.jpg 1x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_2.0,w_50/s3-furlenco-images/2.0/social-media/linkedin.jpg 2x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_3.0,w_50/s3-furlenco-images/2.0/social-media/linkedin.jpg 3x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_4.0,w_50/s3-furlenco-images/2.0/social-media/linkedin.jpg 4x"
                      sizes=""
                      srcset="https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_1.0,w_50/s3-furlenco-images/2.0/social-media/linkedin.jpg 1x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_2.0,w_50/s3-furlenco-images/2.0/social-media/linkedin.jpg 2x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_3.0,w_50/s3-furlenco-images/2.0/social-media/linkedin.jpg 3x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_4.0,w_50/s3-furlenco-images/2.0/social-media/linkedin.jpg 4x"
                      class="loaded"
                    />
                  </div>
                </a>
             
              </div>
              <p className="MuiTypography-root MuiTypography-body1 css-35txkw">
                        Cities we deliver to:
                      </p>
              {/* <p class="MuiTypography-root MuiTypography-body1 css-1mp4fn2">CITIES WE DELIVER TO</p> */}
              <div
                class="MuiBox-root  flex gap-2"
                bis_skin_checked="1"
              >
             
                {
                  <p
                    className="MuiTypography-root MuiTypography-body1  mt-2"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                      lineHeight: "0px",
                    }}
                  >
                  
                    {Array.isArray(data) &&
                      data
                        ?.filter((item) => item?.status === true)
                        ?.map((item, index) => {
                          return (
                            <div key={index}>
                              <p style={{ marginBottom: "0px",fontWeight:'400',fontSize:'10px' }}>
                                {item?.cityName}
                              </p>
                            </div>
                          );
                        })}
                  </p>
                }
              </div>
              <div>

           
              <div class="MuiBox-root css-17m5qbj mt-2" bis_skin_checked="1" >
                      <a class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-1taosdw">
                        <div
                          class="MuiBox-root css-1om2adh"
                          bis_skin_checked="1"
                          style={{ paddingTop: "0px" }}
                        >
                          <a
                            href="https://play.google.com/store/apps/details?id=com.ssa.wbi.rentyfurniture"
                            target="blank"
                          >
                            <img
                              src="https://assets.furlenco.com/image/upload/c_scale,e_blur:75,f_auto,q_0.5,w_20/v1/s3-furlenco-images/2.0/social-media/google-play-stroe.jpg"
                              alt=""
                              data-srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/google-play-stroe.jpg 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/google-play-stroe.jpg 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/google-play-stroe.jpg 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/google-play-stroe.jpg 4x"
                              sizes=""
                              style={{
                                height: "auto",
                                width: "100%",
                                visibility: "visible",
                              }}
                              srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/google-play-stroe.jpg 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/google-play-stroe.jpg 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/google-play-stroe.jpg 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/google-play-stroe.jpg 4x"
                              class="loaded"
                            />
                          </a>
                        </div>
                      </a>
                      <a class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-258857">
                        <div
                          class="MuiBox-root css-1om2adh"
                          bis_skin_checked="1"
                          style={{ paddingTop: "0px" }}
                        >
                          <img
                            src="https://assets.furlenco.com/image/upload/c_scale,e_blur:75,f_auto,q_0.5,w_20/v1/s3-furlenco-images/2.0/social-media/apple-store.jpg"
                            alt=""
                            data-srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/apple-store.jpg 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/apple-store.jpg 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/apple-store.jpg 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/apple-store.jpg 4x"
                            sizes=""
                            style={{
                              height: "auto",
                              width: "100%",
                              visibility: "visible",
                            }}
                            srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/apple-store.jpg 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/apple-store.jpg 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/apple-store.jpg 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/apple-store.jpg 4x"
                            class="loaded"
                          />
                        </div>
                      </a>
                    </div>
                    <div style={{display:'flex',justifyContent:'center',textAlign:'center',marginTop:'10px'}}>
                    <p style={{marginBottom:'100px',fontWeight:'400'}}>
                    Renty Furniture (Day Dream Future Reality Infratech Pvt Ltd)
                    </p>
                    </div>
              </div>
              {/* <div class="MuiBox-root css-0" bis_skin_checked="1">
                                <div class="MuiBox-root css-1mhajqb" bis_skin_checked="1">
                                    <hr style="margin-top: 16px; margin-bottom: 16px;" />
                                    <div class="MuiBox-root css-t2i6k9" bis_skin_checked="1">
                                        <div class="MuiBox-root css-0" bis_skin_checked="1">
                                            <meta charset="utf-8">
                                                <meta charset="utf-8">
                                                    <meta charset="utf-8">
                                                        <meta charset="utf-8">
                                                            <p dir="ltr" style="line-height: 1.38;">Made a decision to buy furniture online? At Furlenco, we’ve always worked around the goal of making buying furniture easy. Now, we’re working on making it easier.&nbsp;</p>
                                                            <br />
                                                            <p dir="ltr" style="line-height: 1.38;">Furniture has steadfastly been the hidden hero of the house and therefore, to buy furniture is to truly invest. We don’t fully realise the difference between an ‘unfurnished’ home and a ‘furnished’ one until we step into one and figure out how important it is to buy furniture the right way. Memories might be remembered and poems might be written around the different rooms, views and corners of a home, but it’s the furniture that brings out the life within it. At Furlenco, we have always believed that the decision to buy furniture was not just for a home, but for a life lived to the fullest. From our mad deadlines to our bad binge-watching, the life we live, lead and practise revolves around furniture and therefore the decision to buy furniture.&nbsp; There’s a reason why we miss our own comfy furniture even in the most comfortable of hotels and there’s a reason why there’s something special about working at our own workstation instead of someone else’s. But it’s not just about our attachment to our own furniture, but also about the hassles we face (or don’t face) when it comes to the decision to buy furniture, whether rental furniture or furniture for purchase, and about the budget plans we make (or don’t make) about our choice to buy furniture. From a baby’s first crib to our grandparents’ bedroom, furniture revolves around and with us, and our lives invisibly revolve around our decision to buy furniture.&nbsp;</p>
                                                            <br />
                                                            <p dir="ltr" style="line-height: 1.38;">Before it was possible to buy furniture online, the decision to buy furniture in India was always a ‘once for life’ business. People used to buy furniture either after their first jobs or with their first families, and after the decision to buy furniture, it was passed down generation to generation until the furniture became unusable, after which the next round to buy furniture would be taken up by the next generation. Even after the advent of online buying furniture, the attempt to buy furniture online never really entered the Indian economic market. To buy furniture, as an online concept, has been a tricky affair in India, whether proper furniture for the common family entered the equation only in the 19th century onwards. Furniture in India has always been meant for the concept of buying as a one-time purchase, and furniture has therefore traditionally been considered costly. Added to that, furniture, even if bought online, has always been thought of as a permanent fixture and therefore online furniture became a ‘once and done’ affair at home because of the hassles of replacing furniture.&nbsp;</p>
                                                            <br />
                                                            <p dir="ltr" style="line-height: 1.38;">The concept of buying online furniture itself is as recent as the early 2010s, when Furlenco founder Ajith Karimpana first offered the convenience to rent furniture online and buy furniture online in India. When Ajith moved to Bengaluru from the USA, he realised that dealing with furniture - how to buy furniture online, moving it, selling it, replacing it, re-buying furniture - became his biggest hassle. To add to that, the convenience to buy furniture by choosing from a wide spectrum of furniture designs to buy furniture online at affordable prices was hardly available.&nbsp;</p>
                                                            <br />
                                                            <p dir="ltr" style="line-height: 1.38;">And so, Furlenco was born in 2011 with the idea that everyone deserved to get access to buy or rent furniture online without any hassles or budget headaches. Furlenco’s original aim has always been to enable customers to live a fuller life with the freedom of renting furniture online or to buy furniture online. Furlenco was the first to offer the ability of making renting furniture available for every category, rent for every room and every requirement imaginable in easy and affordable installments. Suddenly, renting furniture online no longer seemed like a luxurious choice and it gave flexibility to people who were always on the move in life to buy furniture online without worrying about it. Renting furniture became an affordable, practical way to live in Indian cities.&nbsp;</p>
                                                            <br />
                                                            <p dir="ltr" style="line-height: 1.38; margin-bottom: 16pt;">WHY FURLENCO&nbsp;</p>
                                                            <p dir="ltr" style="line-height: 1.38;">Furlenco’s original vision has always been to make the activity to buy furniture or renting essential furniture an easy decision, especially for people who moved to cities for their careers or people considering the action to buy furniture, who didn’t want to stick with one set of furniture in their home for life and were okay with the concept of renting furniture or to buy furniture online as life essentials. Furlenco offers you the freedom to buy original furniture, created by inhouse designers to serve as an excellent mix of comfort and design. When you buy furniture online from Furlenco, you get furniture designs from Furlenco that offer a cosmopolitan vibe, with functionality and flexibility at its core. To buy furniture, basically, to buy furniture online from Furlenco means the added flexibility of not having to worry about a hefty purchase tag, Furlenco customers can afford to buy furniture online, and also the option to rent and replace furniture according to their needs, likes and habitability. There are no added worries about dealing with the decision to&nbsp; gave the option of being returned or moved as well. And for the first time when you were making the decision to buy furniture online, premium designs in furniture weren’t just meant for the highly-salaried who wanted to buy furniture online: everyone gets access to world-class designs and material when they want to buy furniture online, and not just for the living room but for every room of the house. From sofas of multiple sizes and elegant worktables to comfy beds and sturdy wardrobes, Furlenco’s initial offering was aimed at making Indian furniture a truly democratic choice when it came to the decision to buy furniture online.&nbsp;</p>
                                                            <br />
                                                            <br />
                                                            <p dir="ltr" style="line-height: 1.38; margin-bottom: 16pt;">WHAT’S NEXT FOR FURLENCO&nbsp;</p>
                                                            <p dir="ltr" style="line-height: 1.38;">It’s a new decade and a new world, for people and for the decision to buy furniture online. The pandemic has shown us that the division between a home and an office has significantly narrowed down. WFH has become the norm and mental and physical wellness have become crucial responsibilities and not just side-features, and so the decision to buy furniture online has become more important than ever. People all over the world have realised that having peace is as important, or perhaps more important than running after deadlines. And of course, that has a lot to do with how they furnish their home, and therefore the decision to buy furniture online. <br />
                                                                <br />
                                                            </p>
                                                            <p dir="ltr" style="line-height: 1.38;">With that in mind, Furlenco is proud to work towards a newer version of home furniture, online furniture, rental furniture and more ways to buy furniture online: to become easier and more flexible than ever before. So why just rent? With a furniture network that now extends to more than 15 cities all over India and rapidly increasing, Furlenco now offers the freedom to buy furniture online the way you want it: no compromises and no questions asked when it comes to the decision to buy furniture online. And there’s much, much more. Here are the two big ways Furlenco is offering the true freedom to buy furniture online:&nbsp;</p>
                                                            <br />
                                                            <p dir="ltr" style="line-height: 1.38;">FREEDOM TO CHOOSE</p>
                                                            <br />
                                                            <p dir="ltr" style="line-height: 1.38;">In a world where almost all aspects of daily living have the freedom of choice, why should the decision to buy furniture online be any different? Not only does the new Furlenco vision believe that when you want to buy furniture online, you should enjoy the fullest extent of the word ‘freedom’, the idea is also to remove all the notions of inconvenience that are attached to the world of Indian online furniture when people want to buy furniture online. Whether you’re getting your furniture from a local shop, a branded showroom or online, about to buy furniture online, it still comes with its own setbacks. Furlenco’s new furniture offering is to remove any and all boundaries between the customer’s decision to buy furniture online and the delivery of furniture to their home as part of the next step in their furniture.&nbsp;</p>
                                                            <br />
                                                            <p dir="ltr" style="line-height: 1.38;">Welcome to the new freedom to buy furniture online, where you choose how you want your furniture: whether you want it as short-term rent, long-term rent with a subscription plan, whether you just want to buy furniture online directly, whether you want to sellback furniture you previously had. Buy furniture online that is brand new or choose refurbished, replace your online furniture when you get tired of it - the possibilities are endless and the options are many when you want to buy furniture online. Not just that: if you want to buy furniture online that you’re currently renting - well, nothing’s stopping you! Welcome to the Rent-to-own option where you can change your online furniture purchase decisions as and when you want.&nbsp;</p>
                                                            <br />
                                                            <br />
                                                            <p dir="ltr" style="line-height: 1.38;">FREEDOM TO CHANGE</p>
                                                            <br />
                                                            <p dir="ltr" style="line-height: 1.38;">You live in a world that’s constantly on the move. You change, so why shouldn’t your daily living opportunities change with you? And when you evolve, why shouldn’t the decision to buy furniture online evolve with you? With the new Furlenco, when you buy furniture online, it gives you the power to change as you want to. Don’t worry, the furniture will change with you! Don’t like the current look of your living room furniture? Replace it. Want to buy furniture that you were renting all this while? Nothing’s stopping you! To add to that, the 3-day free delivery service and the convenience of free intercity relocation&nbsp; when you buy furniture online means that you don’t have to worry about your furniture while being on the move!&nbsp;</p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="MuiBox-root css-n5fgxz" bis_skin_checked="1">See more &gt;
                                                </div>
                                        </div>
                                    </footer>
                                </div>
                            </div> */}
            </footer>
          </div>
        </>
      )}

      {isWeb && (
        <>
          <div class="MuiBox-root css-2fy7ln desktoheader">
            <hr class="MuiDivider-root MuiDivider-fullWidth css-4b5e1l" />

            <div class="MuiContainer-root MuiContainer-maxWidthXl css-1976cj6">
              <div class="MuiBox-root css-f6zynw">
                <div class="MuiBox-root css-7nyjp3">
                  <div class="MuiBox-root css-11yfrfv">
                    <div
                      class="MuiBox-root css-u8l2se"
                      style={{ paddingTop: "0px" }}
                    ></div>
                    <img
                      src={lofoo}
                      alt=""
                      data-srcset={lofoo}
                      sizes=""
                      style={{
                        height: "auto",
                        width: "250px",
                        visibility: "visible",
                      }}
                      srcset={lofoo}
                      class="loaded"
                    />
                    <div class="MuiBox-root css-psfrkx">
                      <a
                        class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-nauzol"
                        target="_blank"
                        href="https://www.facebook.com/rentyfurnituree"
                      >
                        <div
                          class="MuiBox-root css-vuugp1"
                          bis_skin_checked="1"
                          style={{ paddingTop: "0px" }}
                        >
                          <img
                            src={FaceBookicon}
                            data-srcset={FaceBookicon}
                            sizes=""
                            style={{
                              height: "auto",
                              width: "100%",
                              visibility: "visible",
                            }}
                            srcset={FaceBookicon}
                            class="loaded"
                            alt="WebP Image"
                          />
                        </div>
                      </a>
                      <a
                        class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-nauzol"
                        target="_blank"
                        href="https://www.instagram.com/renty_furniture/"
                      >
                        <div
                          class="MuiBox-root css-vuugp1"
                          bis_skin_checked="1"
                          style={{ paddingTop: "0px" }}
                        >
                          <img
                            src={InstagramIcon}
                            alt="WebP Image"
                            data-srcset={InstagramIcon}
                            sizes=""
                            style={{
                              height: "auto",
                              width: "100%",
                              visibility: "visible",
                            }}
                            srcset={InstagramIcon}
                            class="loaded"
                          />
                        </div>
                      </a>
                      <a
                        href="https://x.com/rentyfurniture"
                        class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-nauzol"
                        target="_blank"
                      >
                        <div
                          class="MuiBox-root css-vuugp1"
                          bis_skin_checked="1"
                          style={{ paddingTop: "0px" }}
                        >
                          <img
                            src={TwitterIcon}
                            alt="WebP Image"
                            data-srcset={TwitterIcon}
                            sizes=""
                            style={{
                              height: "auto",
                              width: "100%",
                              visibility: "visible",
                            }}
                            srcset={TwitterIcon}
                            class="loaded"
                          />
                        </div>
                      </a>
                      <a
                        class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-nauzol"
                        target="_blank"
                      >
                        <div
                          class="MuiBox-root css-vuugp1"
                          bis_skin_checked="1"
                          style={{ paddingTop: "0px" }}
                        >
                          <Link
                            to={`https://www.linkedin.com/company/rentyfurniture/`}
                            target="_blank"
                          >
                            <img
                              src={LinkdinIcon}
                              data-srcset={{ LinkdinIcon }}
                              sizes=""
                              style={{
                                height: "auto",
                                width: "100%",
                                visibility: "visible",
                              }}
                              srcset={{ LinkdinIcon }}
                              class="loaded"
                              alt="WebP Image"
                            />
                          </Link>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div class="MuiBox-root css-vl14sd">
                    <p
                      onClick={() => handleUrl("/about-us")}
                      class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-z1euss"
                    >
                      <div class="MuiBox-root css-4g6ai3">About Us</div>
                    </p>
                    <p
                      onClick={() => handleUrl("/")}
                      class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-z1euss"
                    >
                      <div class="MuiBox-root css-4g6ai3">Home</div>
                    </p>

                    <p
                      onClick={() => handleUrl("/contact-us")}
                      class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-z1euss"
                    >
                      <div class="MuiBox-root css-4g6ai3">Contact</div>
                    </p>
                    <p
                      onClick={() => handleSupport("/dashboard/all_request")}
                      class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-z1euss"
                    >
                      <div class="MuiBox-root css-4g6ai3">Support</div>
                    </p>
                    <a
                      href="https://www.blog.rentyfurniture.com/"
                      target="_blank"
                      class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-z1euss"
                    >
                      <div class="MuiBox-root css-4g6ai3">Blogs</div>
                    </a>
 
                    <p
                      onClick={() => handleUrl("/document-required")}
                      to="/document-required"
                      class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-z1euss"
                    >
                      <div class="MuiBox-root css-4g6ai3">
                        Documents Required
                      </div>
                    </p>
                    <p
                      onClick={() => handleUrl("/rental_terms_and_conditions")}
                      class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-z1euss"
                    >
                      <div class="MuiBox-root css-4g6ai3">
                        Rental Terms & Conditions
                      </div>
                    </p>
                    <p
                      onClick={() => handleUrl("/Privacy_Policy")}
                      class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-z1euss"
                    >
                      <div class="MuiBox-root css-4g6ai3">Privacy Policy</div>
                    </p>
                    <p
                      onClick={() => handleUrl("/Cancellation_return")}
                      class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-z1euss"
                    >
                      <div class="MuiBox-root css-4g6ai3">
                        Cancellation & Return
                      </div>
                    </p>
                    <p
                      to="/"
                      onClick={() => handleUrl("/shipping_policy")}
                      class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-z1euss"
                    >
                      <div class="MuiBox-root css-4g6ai3">Shipping Policy</div>
                    </p>
                  </div>
                  <div class="MuiBox-root css-11yfrfv">
                    <p class="MuiTypography-root MuiTypography-body1 css-1d3u4hl">
                      <Link to="/dashboard/all_request">NEED HELP?</Link>
                    </p>
                  </div>
                </div>

                <hr class="MuiDivider-root MuiDivider-fullWidth css-19no0ew" />

                <div class="">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "",
                    }}
                  >
                    <p class="MuiTypography-root MuiTypography-body1 css-i8ud6o">
                      CITIES WE DELIVER TO
                    </p>

                    <div class="MuiBox-root css-17m5qbj" bis_skin_checked="1">
                      <a class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-1taosdw">
                        <div
                          class="MuiBox-root css-1om2adh"
                          bis_skin_checked="1"
                          style={{ paddingTop: "0px" }}
                        >
                          <a
                            href="https://play.google.com/store/apps/details?id=com.ssa.wbi.rentyfurniture"
                            target="blank"
                          >
                            <img
                              src="https://assets.furlenco.com/image/upload/c_scale,e_blur:75,f_auto,q_0.5,w_20/v1/s3-furlenco-images/2.0/social-media/google-play-stroe.jpg"
                              alt=""
                              data-srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/google-play-stroe.jpg 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/google-play-stroe.jpg 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/google-play-stroe.jpg 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/google-play-stroe.jpg 4x"
                              sizes=""
                              style={{
                                height: "auto",
                                width: "100%",
                                visibility: "visible",
                              }}
                              srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/google-play-stroe.jpg 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/google-play-stroe.jpg 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/google-play-stroe.jpg 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/google-play-stroe.jpg 4x"
                              class="loaded"
                            />
                          </a>
                        </div>
                      </a>
                      <a class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-258857">
                        <div
                          class="MuiBox-root css-1om2adh"
                          bis_skin_checked="1"
                          style={{ paddingTop: "0px" }}
                        >
                          <img
                            src="https://assets.furlenco.com/image/upload/c_scale,e_blur:75,f_auto,q_0.5,w_20/v1/s3-furlenco-images/2.0/social-media/apple-store.jpg"
                            alt=""
                            data-srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/apple-store.jpg 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/apple-store.jpg 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/apple-store.jpg 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/apple-store.jpg 4x"
                            sizes=""
                            style={{
                              height: "auto",
                              width: "100%",
                              visibility: "visible",
                            }}
                            srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/apple-store.jpg 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/apple-store.jpg 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/apple-store.jpg 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/2.0/social-media/apple-store.jpg 4x"
                            class="loaded"
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="MuiBox-root css-11yfrfv" style={{ width: "50%" }}>
                  <p
                    className="MuiTypography-root MuiTypography-body1 css-4gkszw"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "24px",
                      lineHeight: "0px",
                    }}
                  >
                    {Array.isArray(data) &&
                      data
                        ?.filter((item) => item?.status === true)
                        ?.map((item, index) => {
                          return (
                            <div key={index}>
                              <p style={{ marginBottom: "0px" }}>
                                {item?.cityName}
                              </p>
                            </div>
                          );
                        })}
                  </p>
                </div>
              </div>
            </div>
            <div style={{ textAlign: "center", marginTop: "30px" ,fontWeight:""}}>
              <p>
                Renty Furniture (Day Dream Future Reality Infratech Pvt Ltd)
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Footer;
