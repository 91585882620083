import React from "react";
import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { ActiveSub } from "../../../actions/products/orders/activeSub";
import { useDispatch, useSelector } from "react-redux";
import { handelCancelTHErERENTANREQUEST } from "../../../actions/products/cancelPlanaction";
import { Loader } from "rsuite";
import { RenewOrderAction } from "../../../actions/products/renewproductAction";
import Swal from "sweetalert2";
import { useNavigate } from "framer";

function Active_Subscription_Items() {
  const data = useSelector((state) => state.ActiveRentalPlans.data);
  const loading = useSelector((state) => state.ActiveRentalPlans.loading);
  const newLoading = useSelector(
    (state) => state.cancelrequestofrentalplanreducer.loading
  );
  const renewloading = useSelector((state) => state.RenewOrderReducer.loading);
  const [renewId, setRenewid] = useState();

  const dispatch = useDispatch();

  console.log(data, "datadata");
  useEffect(() => {
    dispatch(ActiveSub());
  }, [dispatch]);
  const [activeCancelId, setActiveCanceriD] = useState();

  const handlecancelreq = async (item, cancelstatus) => {
    if (cancelstatus === "PENDING") {
      return Swal.fire({
        title: "Cancel Request",
        text: "Plan Cancel Request Already Sent",
        icon: "info", // Changed to info for clarity
      });
    }
  
    // Show confirmation dialog before proceeding
    const result = await Swal.fire({
      title: "Cancel Request",
      text: "Are you sure you want to cancel this plan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#d33', // Red color for the "Yes" button
      cancelButtonColor: '#3085d6', // Color for the "Cancel" button
      confirmButtonText: 'Yes, cancel it!',
      cancelButtonText: 'No, keep it'
    });
  
    if (result.isConfirmed) {
      try {
        setActiveCanceriD(item?.planId);
  
        // Dispatch cancel request first
        const cancelRequest = dispatch(handelCancelTHErERENTANREQUEST(item?.planId));
  
        // Introduce 1-second delay before calling ActiveSub
        const delay = new Promise((resolve) => setTimeout(resolve, 2000)); // 1000 ms = 1 second
  
        // Wait for the cancelRequest and 1-second delay before calling ActiveSub
        await Promise.all([cancelRequest, delay]);
  
        // After the delay, dispatch ActiveSub
        const activeSub = dispatch(ActiveSub());
  
        // Wait for ActiveSub to complete
        await activeSub;
  
        // Show alert after dispatching cancel request and ActiveSub
        Swal.fire({
          title: "Cancel Request",
          text: "Your canceling Plan Request has been sent",
          icon: "success",
        });
  
        // Optionally, reload the window after all actions are completed
        // location.reload();
  
      } catch (error) {
        console.error("Error occurred during cancellation request:", error);
        // Handle error gracefully
        Swal.fire({
          title: "Error",
          text: "An error occurred while processing your request. Please try again.",
          icon: "error",
        });
      }
    }
  };
  

  const navigate=useNavigate()

  const handleurl = async (urlss) => {
    console.log(urlss, "uruururuurur");
    localStorage.setItem("url", urlss);
    window.open("/pdfview", "", "");
  };
  const sendplanId = async (id) => {
    localStorage.setItem('customerAddeess',id?.customerAddress)
    console.log(id,'hihere ismy d')
    setRenewid(id?.planId);
    try {
      await dispatch(RenewOrderAction(id?.planId));
      dispatch(ActiveSub());
    
    } catch (error) {
      console.error("Error occurred during cancellation request:", error);
      // Handle error gracefully, e.g., display a message to the user
      alert("Error occurred while processing your request. Please try again.");
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options); // Using 'en-GB' for the desired format
    return formattedDate.replace(",", ""); // Remove any comma
  };
  const isAuthenticated = localStorage.getItem('customerId');
  useEffect(()=>{
    if(!isAuthenticated){
      return navigate('login')
    }
  
  },[])
  return (
    <>
      <style>
        {`
          .col-12.col-md-9.mt-4 {
    overflow: scroll;
}
          @import 'https://fonts.googleapis.com/css?family=Open+Sans:600,700';
          * {font-family: 'Open Sans', sans-serif;}
          
          .rwd-table {
            margin: auto;
            min-width: 300px;
            max-width: 100%;
            border-collapse: collapse;
          }
          
          .rwd-table tr:first-child {
            border-top: none;
            color: #fff;
          }
          
          .rwd-table tr {
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
          }
          
          
          .rwd-table th {
            display: none;
          }
          
          .rwd-table td {
            display: block;
          }
          
          .rwd-table td:first-child {
            margin-top: .5em;
          }
          
          .rwd-table td:last-child {
            margin-bottom: .5em;
          }
          
          .rwd-table td:before {
            // : ) ": ";
            font-weight: bold;
            width: 120px;
            display: inline-block;
            color: #000;
          }
          
          .rwd-table th,
          .rwd-table td {
            text-align: left;
          }
          
          .rwd-table {
            color: #333;
            border-radius: .4em;
            overflow: hidden;
          }
          
          .rwd-table tr {
            border-color: #bfbfbf;
          }
          
          .rwd-table th,
          .rwd-table td {
            padding: .5em 1em;
          }
          @media screen and (max-width: 601px) {
            .rwd-table tr:nth-child(2) {
              border-top: none;
            }
          }
          @media screen and (min-width: 600px) {
            .rwd-table tr:hover:not(:first-child) {
            }
            .rwd-table td:before {
              display: none;
            }
            .rwd-table th,
            .rwd-table td {
              display: table-cell;
              padding: .25em .5em;
            }
            .rwd-table th:first-child,
            .rwd-table td:first-child {
              padding-left: 0;
            }
            .rwd-table th:last-child,
            .rwd-table td:last-child {
              padding-right: 0;
            }
            .rwd-table th,
            .rwd-table td {
              padding: 1em !important;
            }
          }
          
          
          /* THE END OF THE IMPORTANT STUFF */
          
          /* Basic Styling */
          
          h1 {
            text-align: center;
            font-size: 2.4em;
          }
          .container {
            display: block;
            text-align: center;
          }
          h3 {
            display: inline-block;
            position: relative;
            text-align: center;
            font-size: 1.5em;
            color: #cecece;
          }
          h3:before {
            
            position: absolute;
            left: -50px;
            -webkit-animation: leftRight 2s linear infinite;
            animation: leftRight 2s linear infinite;
          }
          h3:after {
            
            position: absolute;
            right: -50px;
            -webkit-animation: leftRight 2s linear infinite reverse;
            animation: leftRight 2s linear infinite reverse;
          }
          @-webkit-keyframes leftRight {
            0%    { -webkit-transform: translateX(0)}
            25%   { -webkit-transform: translateX(-10px)}
            75%   { -webkit-transform: translateX(10px)}
            100%  { -webkit-transform: translateX(0)}
          }
          @keyframes leftRight {
            0%    { transform: translateX(0)}
            25%   { transform: translateX(-10px)}
            75%   { transform: translateX(10px)}
            100%  { transform: translateX(0)}
          }
          
          /*
              Don't look at this last part. It's unnecessary. I was just playing with pixel gradients... Don't judge.
          */
          /*
          @media screen and (max-width: 601px) {
            .rwd-table tr {
              background-image: -webkit-linear-gradient(left, #428bca 137px, #f5f9fc 1px, #f5f9fc 100%);
              background-image: -moz-linear-gradient(left, #428bca 137px, #f5f9fc 1px, #f5f9fc 100%);
              background-image: -o-linear-gradient(left, #428bca 137px, #f5f9fc 1px, #f5f9fc 100%);
              background-image: -ms-linear-gradient(left, #428bca 137px, #f5f9fc 1px, #f5f9fc 100%);
              background-image: linear-gradient(left, #428bca 137px, #f5f9fc 1px, #f5f9fc 100%);
            }
            .rwd-table tr:nth-child(odd) {
              background-image: -webkit-linear-gradient(left, #428bca 137px, #ebf3f9 1px, #ebf3f9 100%);
              background-image: -moz-linear-gradient(left, #428bca 137px, #ebf3f9 1px, #ebf3f9 100%);
              background-image: -o-linear-gradient(left, #428bca 137px, #ebf3f9 1px, #ebf3f9 100%);
              background-image: -ms-linear-gradient(left, #428bca 137px, #ebf3f9 1px, #ebf3f9 100%);
              background-image: linear-gradient(left, #428bca 137px, #ebf3f9 1px, #ebf3f9 100%);
            }
          }*/
          `}
      </style>
      <h4>
        <div>Active Subscription</div>
      </h4>
      <Table striped bordered hover style={{ overflow: "scroll" }}>
        <thead>
          <tr>
          <th>Product Name</th>

            <th>Order Id</th>
            <th>Active Date</th>
            <th>End Date</th>
            <th>Plan</th>
            <th>Cancel Plan</th>
            <th>Renew Plan</th>
          </tr>
          <style>
            {`
          th, td {
            text-align: center;
            white-space: nowrap; /* Prevents text from wrapping */
            overflow: hidden; /* Ensures content doesn't overflow */
            text-overflow: ellipsis; /* Adds ellipsis (...) for overflowed text */
          }
          td {
            max-width: 150px; /* Adjust as necessary */
            width: 150px; /* Set a fixed width to help prevent breaking */
          }
          button {
            white-space: nowrap; /* Prevent text wrapping in buttons */
            overflow: hidden;
            text-overflow: ellipsis; /* Adds ellipsis if text is too long */
          }
        `}
          </style>
        </thead>
        <tbody>
          {Array.isArray(data) &&
            [...data].reverse()?.map((item, index) => (
              <tr style={{ cursor: "pointer" }} key={index}>
                 <td onClick={() => handleurl(item?.orderInvoicePathName)}>
                  {item?.productName}
                </td>
                <td onClick={() => handleurl(item?.orderInvoicePathName)}>
                  {item.orderId}
                </td>
                <td onClick={() => handleurl(item?.orderInvoicePathName)}>
                  {formatDate(item?.planStartedDate)}
                </td>
                <td onClick={() => handleurl(item?.orderInvoicePathName)}>
                  {formatDate(item?.planEndDate)}
                </td>
                <td onClick={() => handleurl(item?.orderInvoicePathName)}>
                  {item?.duration} Month
                </td>
                <td>
                  <button
                    style={{
                      background: "rgb(245, 99, 88)",
                      color: "white",
                      borderRadius: "4px",
                      padding: "10px",
                    }}
                    onClick={() =>
                      handlecancelreq(item, item?.rentalPlanCancelRequest)
                    }
                  >
                    {activeCancelId === item?.planId && newLoading ? (
                      <Loader />
                    ) : item?.rentalPlanCancelRequest === "PENDING" ? (
                      "PENDING"
                    ) : (
                      "Cancel Plan"
                    )}
                  </button>
                </td>
                <td>
                  <button
                    style={{
                      background: "rgb(245, 99, 88)",
                      color: "white",
                      borderRadius: "4px",
                      padding: "10px",
                    }}
                    onClick={() => sendplanId(item)}
                  >
                    {renewId === item?.planId && renewloading ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      "Renew Plan"
                    )}
                  </button>
                </td>
              </tr>
            ))}
          {data === "No active rental plans found for the customer with id 5"
            ? "No data"
            : ""}
        </tbody>
      </Table>
    </>
  );
}

export default Active_Subscription_Items;
