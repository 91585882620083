import React, { useEffect, useState } from "react";
import "./kycstyle.css";
import axios from "axios";

import { SendOtpAdhar } from "../../../actions/products/cart/sendAdharOtpAction";
import emailimj from "./emailv.png";
import { GenerateSignatureAction } from "../../../actions/products/cart/generateSignatureAction";
import { useDispatch, useSelector } from "react-redux";
import { VerifyOTPAAAAAAATION } from "../../../actions/products/cart/verifyOtpaction";
import { VerifiOtpPanRedcucer } from "../../../reducers/products/cart/verifyotpPanReducer";
import { verifyPancerrrrrrrrrrr } from "../../../actions/products/cart/verifyOtpPanCard";
import { basicAuth } from "../../../actions/auth/basicAuth";
import { Alert, Spinner } from "react-bootstrap";
import { fetchCustomerById } from "../../../actions/customer/getCustomerById";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import add from "./add.png";
import pan from "./pan.png";
import completedtake from "./Completed Tick.png";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { url } from "../../../actions/auth/baseUrl";
import { ArrowBack } from "@mui/icons-material";
const steps = [
  "Email Verification",
  "Pancard Verification",
  "Aadhar Verification",
];
function NewKyc() {

  const customerId=localStorage.getItem('customerId')



  const data = useSelector((state) => state?.getCustomerById?.data);
  console.log(data, "dvcbhdcsjkskks");
  const responseotp = useSelector((state) => state.VerifiOtpRedcucer);
  const dispatch = useDispatch();
  const [pandCard, setPanCard] = useState({
    name: "",
    pannumber: "",
  });
  const [adharData, setAdharData] = useState({ adharNumber: "" });

  const [error, setError] = useState("");

  const validateAadharNumber = (number) => {
    const aadharRegex = /^\d{12}$/;
    return aadharRegex.test(number);
  };
  // const {loading}=useSelector((state)=>state?.SendOtpAdharReducer)
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [loading, setLoading] = useState(false);

  const VerifiedKycHandle = async (e) => {
    e.preventDefault();
    
    if (!validateAadharNumber(adharData.adharNumber)) {
      setError("Invalid Aadhar number. It should be a 12-digit number.");
      return;
    }
  
    const customerID = localStorage.getItem("customerId");
    const data = { aadharNumber: adharData.adharNumber };
    const headers = {
      Authorization: basicAuth,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  
    try {
      setLoading(true); // Start loading
      const response = await axios.post(url + `sendAadharOtpByCustomerId/${customerID}`, data, { headers });
  
      console.log(response, "API Response");
  
      if (response?.data?.message === "Invalid Aadhaar Card") {
        Swal.fire({
          title: "Invalid Aadhar",
          text: "Invalid Aadhar Number",
          icon: "error",
        });
      } else if (response?.data?.message === "Aadhar number is already used") {
        Swal.fire({
          title: "Aadhar number is already used",
          text: "Aadhar number is already used",
          icon: "error",
        });
      } else if (response?.data?.message === "OTP sent successfully") {
        Swal.fire({
          title: "OTP SENT",
          text: "Your OTP has been sent. Please check.",
          icon: "success",
        });
        setError("")
  
        // Start a 40-second countdown and disable the button
        setButtonDisabled(true);
        let timeLeft = 40;
        setCountdown(timeLeft);
  
        const timer = setInterval(() => {
          timeLeft -= 1;
          setCountdown(timeLeft);
          if (timeLeft <= 0) {
            clearInterval(timer);
            setButtonDisabled(false);
          }
        }, 1000);
  
        // Save reference ID to localStorage
        localStorage.setItem("ref", response.data?.ref_id);
      }
    } catch (error) {
      console.error(error, "API Error");
      if (error?.response?.data === "Aadhar number is already used") {
        Swal.fire({
          title: "Aadhar number is already used",
          text: "Aadhar number is already used",
          icon: "error",
        });
      } else {
        setError("An error occurred. Please try again later.");
      }
    } finally {
      setLoading(false); // End loading
    }
  };

  //    const hanndleSendOTPFOREMAIL=()=>{
  //     verifyEmail
  //    }
  const [eloaidng, setEloading] = useState(false);
  const hanndleSendOTPFOREMAIL = () => {
    var headers = {
      Accept: "application/json",
      Authorization: basicAuth,
    };
    setEloading(true);
    var baseUrl = "https://api.rentyfurniture.com/";
    axios
      .post(
        `${baseUrl}verifyEmail`,
        { email: data?.email },
        { headers: headers }
      )
      .then(async (response) => {
        if (response.status === 200) {
          setEloading(false);

          Swal.fire({
            title: "OTP Sent",
            text: "Please Check Your Email OTP Sent",
            icon: "success",
          });
          // Handle successful response
        }
      })
      .catch((error) => {
        setEloading(false);

        console.log(error);
      });
  };
  const [email, sendEmail] = useState();

  const handleVeryfyEmailOTP = () => {
    var headers = {
      Accept: "application/json",
      Authorization: basicAuth,
    };
    // setEloading(true)
    var baseUrl = "https://api.rentyfurniture.com/";
    axios
      .post(
        `${baseUrl}confirmOtpToVerifyEmail`,
        { email: data.email, otp: email },
        { headers: headers }
      )
      .then(async (response) => {
        if (response.status === 200) {
          setEloading(false);
          Swal.fire({
            title: "Verified Email",
            text: "You have Verified Your Email",
            icon: "success",
          });
          window.location.reload();
        }
      })
      .catch((error) => {
        // setEloading(false)
        Swal.fire({
          title: "Invalid OTP",
          text: "Please Enter Valid OTP",
          icon: "error",
        });
        console.log(error);
      });
  };
  // Call the function
  useEffect(() => {
    dispatch(fetchCustomerById());
  }, []);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const navigate = useNavigate();
  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      return navigate("/");
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const [pandata, setPandata] = useState({
    pan: "",
    otp: "",
  });
  const pandHandleChange = (e) => {
    const { name, value } = e.target;
    setPandata({
      ...pandata,
      [name]: value,
    });
  };

  const handleVeryPancard = () => {
    const ref = localStorage.getItem("ref");
    var headers = {
      Accept: "application/json",
      Authorization: basicAuth,
    };
    // setEloading(true)
    var baseUrl = "https://api.rentyfurniture.com/";
    axios
      .post(
        `${baseUrl}confirmOtpToVerifyEmail`,
        { ref_id: ref, otp: email },
        { headers: headers }
      )
      .then(async (response) => {
        if (response.status === 200) {
          setEloading(false);

          Swal.fire({
            title: 'Email verified',
            text: 'You have verified your Email',
            icon: 'success',
        });      
            window.location.reload();
        }
      })
      .catch((error) => {
        // setEloading(false)
        Swal.fire({
          title: "Invalid ",
          text: "Please Write Valid OTP",
          icon: "error",
        });
      });
  };

  const [otpAdhar, setOtpAhar] = useState();
  const [adharOtpLoading, setAdharOtpLoading] = useState(false);

  const veryFyOtpAdhar = () => {
    const ref = localStorage.getItem("ref");
    const customerId = localStorage.getItem("customerId");

    var headers = {
      Accept: "application/json",
      Authorization: basicAuth,
    };
    var baseUrl = "https://api.rentyfurniture.com/";
    setAdharOtpLoading(true);

    axios
      .post(
        `${baseUrl}verifyAadhaarOtpByCustomerId/${customerId}`,
        { refId: ref, otp: otpAdhar },
        { headers: headers }
      )
      .then(async (response) => {
        if (response.status === 200) {
          Swal.fire({
            title: "Verified  Aadhar",
            text: "You have verify Your Aadhar",
            icon: "success",
          });
          setAdharOtpLoading(false);

          dispatch(fetchCustomerById());
          // Handle successful response
        } else {
        }
      })
      .catch((error) => {
        setAdharOtpLoading(false);

        Swal.fire({
          title: "Invalid",
          text: "Please Write Valid OTP",
          icon: "error",
        });
      });
  };

  const [panNumbernew, setPANnumber] = useState("");
  const [errorPan,setPanError]=useState('')
  const [padncardLoading,setPandcardLoading]=useState(false)

  const handlePancard = () => {
    if(panNumbernew==''){
      return    setPanError('PanCard is Required')
     }
    const ref = localStorage.getItem("ref");
    const customer = localStorage.getItem("customername");
    const customerId = localStorage.getItem("customerId");
    setPandcardLoading(true)

    var headers = {
      Accept: "application/json",
      Authorization: basicAuth,
    };
    var baseUrl = "https://api.rentyfurniture.com/";
    axios
      .post(
        `${baseUrl}verifyPanByCustomerId/${customerId}`,
        { panNumber: panNumbernew, name: customer },
        { headers: headers }
      )
      .then(async (response) => {
        setPandcardLoading(false)

        if (response.data?.message == "Invalid PAN") {
        setPandcardLoading(false)

          console.log(response, 'sjkbcjh')
          Swal.fire({
              title: 'Invalid Pancard',
              text: 'Please Enter Valid Pancard',
              icon: 'error',
          });
          // Handle successful response
      }else{
        setPandcardLoading(false)

          Swal.fire({
              title: 'Verified Pancard',
              text: 'You have verified your Pancard',
              icon: 'success',
          }); 
          dispatch(fetchCustomerById())

      }
      })
      .catch((error) => {
    setPandcardLoading(false)

        Swal.fire({
          title: 'Invalid Pancard or Already Exist',
          text: 'Please Enter Valid Pancard',
          icon: 'error',
      });
        console.log(error);
      });
  };
  const validateStep = () => {
    switch (activeStep) {
        case 0: // Email Verification Step
            return data.emailVerificationStatus === true; // Check if email is verified
        case 1: // PAN Verification Step
            return data.panNumber && data.panNumberVerificationStatus === true; // Check if PAN is filled and verified
        default:
            return true; // Allow moving to the next step if it's the final step
    }
};

useEffect(()=>{
  if(!customerId){
   navigate('/login')
  }
 },[])
 const goBack = () => {
  navigate('/');
};
  return (
    <>
      <style>
        {`
                input.formbold-form-input {
    border: 1px solid grey !important;
}
                `}
      </style>
      <style>
        {`
  .MuiBox-root.css-na2bnx.containerww.headerssss {
    display: none !important;
}

                    .widtdtwy{
                    width:60%;
                    }
                    .kycpagess{
                    position:relative;
                    top:30px;
                    }
                        @media(max-width:767px){
                        .kycpagess{
                        }
                        .bobobokobk{
                        display:none;
                        }
                        .widtdtwy{
                    width:100% !important;
                    }
                        }
                        `}
      </style>


      <main
        className="kycpagess"
        style={{ position: "", top: "", bottom: "" }}
      >
             <div className='button' style={{background:'rgb(245, 99, 88)',width:'28px',padding:'2px',margin:'10px',borderRadius:'100%',position:'relative',top:"10px"}}>
                                         <button onClick={goBack} style={{color:'white'}}>
                                     <ArrowBack color='white'></ArrowBack>
                                     </button>
                                     </div>
        <Box sx={{ width: "85%", margin: "auto" }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (index === activeStep) {
                labelProps.optional = (
                  <Typography variant="caption">Current Step</Typography>
                );
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {/* Conditional Rendering for Email Verification Step */}
          {activeStep === 0 && (
            <div className="formbold-main-wrapper">
              {data.emailVerificationStatus ? (
                <>
                  <div
                    className="widtdtwy"
                    style={{ width: "", margin: "auto", display: "flex" }}
                  >
                    <img
                      style={{ display: "flex", margin: "auto", width: "50%" }}
                      src={completedtake}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
              {data?.emailVerificationStatus ? (
                <></>
              ) : (
                <>
                  <h3
                    style={{
                      margin: "",
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "700",
                    }}
                  >
                    Verify Email <hr />
                  </h3>
                </>
              )}

              {data.emailVerificationStatus ? (
                <Typography
                  variant="h6"
                  sx={{ mt: 2, mb: 1, display: "flex", margin: "auto" }}
                ></Typography>
              ) : (
                <div className="formbold-form-wrapper mt-2">
                  <div className="formbold-mb-5">
                    <input
                      style={{ border: "1px solid grey !important" }}
                      type="text"
                      name=""
                      value={data.email}
                      placeholder="Email"
                      className="formbold-form-input"
                      readOnly
                    />
                  </div>

                  <div style={{ display: "flex" }}>
                    <button
                      className="formbold-btn"
                      style={{ margin: "auto" }}
                      onClick={hanndleSendOTPFOREMAIL}
                      disabled={eloaidng}
                    >
                      {eloaidng ? "Sending..." : "Verify Email"}
                    </button>
                  </div>
                  <div className="formbold-mb-5 mt-3">
                    <input
                      style={{ border: "1px solid grey !important" }}
                      type="text"
                      name="otp"
                      placeholder="One Time Password"
                      onChange={(e) => sendEmail(e.target.value)}
                      className="formbold-form-input"
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <button
                      className="formbold-btn"
                      style={{ margin: "auto" }}
                      onClick={handleVeryfyEmailOTP}
                    >
                      Verify OTP
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          {activeStep === 1 && (
            <>
              {data?.panNumberVerificationStatus ? (
                <>
                  <div className="formbold-main-wrapper">
                    <div
                      className="widtdtwy"
                      style={{ width: "", margin: "auto", display: "flex" }}
                    >
                      <img
                        src={completedtake}
                        style={{
                          display: "flex",
                          margin: "auto",
                          width: "50%",
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="formbold-main-wrapper mt-5"
                    style={{ position: "relative", bottom: "15px" }}
                  >
                    <div>
                      <h3
                        style={{
                          margin: "",
                          display: "flex",
                          justifyContent: "center",
                          fontWeight: "700",
                        }}
                      >
                        Verify PAN <hr />
                      </h3>
                    </div>
                    <div className="formbold-form-wrapper mt-2">
                      <div className="formbold-mb-5 mt-3">
                        <input
                          onChange={(e) => setPANnumber(e.target.value)}
                          style={{ border: "1px solid grey !important" }}
                          type="text"
                          name="pan"
                          placeholder="Pan Number"
                          className="formbold-form-input"
                        />
                                                        <p style={{color:'red'}}>{errorPan}</p>
                        
                        <div style={{ display: "flex" }}></div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <button
                          className="formbold-btn "
                          disabled={padncardLoading}

                          style={{ margin: "auto" }}
                          onClick={handlePancard}
                        >
                          Verify Pan
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {activeStep === 2 && (
            <>
              {data?.aadharNumberVerificationStatus ? (
                <>
                  <div className="formbold-main-wrapper">
                    <div
                      className="widtdtwy"
                      style={{ width: "", margin: "auto", display: "flex" }}
                    >
                      <img
                        src={completedtake}
                        style={{
                          display: "flex",
                          margin: "auto",
                          width: "50%",
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="formbold-main-wrapper">
                    <div>
                      <h3
                        style={{
                          margin: "",
                          display: "flex",
                          justifyContent: "center",
                          fontWeight: "700",
                        }}
                      >
                        Verify Aadhar <hr />
                      </h3>
                    </div>
                    <div className="formbold-form-wrapper mt-2">
                      <div className="formbold-mb-5">
                        <input
                          style={{ border: "1px solid grey !important" }}
                          type="text"
                          name="adharNumber"
                          placeholder="Aadhar Number"
                          onChange={(e) =>
                            setAdharData({
                              ...adharData,
                              adharNumber: e.target.value,
                            })
                          }
                          className="formbold-form-input"
                        />
                        <p style={{color:'red'}}>{error}</p>
                      </div>

                      {/* otp */}

                      <div style={{ display: "flex" }}>
                        <button
                          className="formbold-btn"
                          style={{ margin: "auto" }}
                          disabled={isButtonDisabled || loading}
                          onClick={VerifiedKycHandle}
                        >
  {loading ? "Sending OTP..." : isButtonDisabled ? `Wait ${countdown} sec` : "Send OTP"}
  </button>
                      </div>
                      <div className="formbold-mb-5 mt-3">
                        <input
                          style={{ border: "1px solid grey !important" }}
                          onChange={(e) => setOtpAhar(e.target.value)}
                          type="text"
                          name=""
                          placeholder="One Time Password"
                          className="formbold-form-input"
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <button
                          className="formbold-btn "
                          style={{ margin: "auto" }}
                          disabled={adharOtpLoading}
                          onClick={veryFyOtpAdhar}
                        >
                           {adharOtpLoading?"Verifying...":"Verify OTP "}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you're finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />

                <Button
                   disabled={!validateStep()} // Disable if validation fails
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </main>

      {/* pancard */}
    </>
  );
}

export default NewKyc;
