import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export const SetmetaTitle = () => {
    const productdata = useSelector((state) => state?.getProductById?.data);
    console.log(productdata,'productdata')
  
      const categories=useSelector((state)=>state.getAllMainCategory.data)
  
  const location = useLocation();
  const activeCategories =Array.isArray(categories)&& categories
      ?.filter((item) => item.status) // Only include active categories
      .map((item) => item.categoryName)
      .join(", ") || "beds, sofas, TVs"; // Fallback if no categories available

  useEffect(() => {
    const pathSegments = location.pathname.split("/").filter(Boolean);

    // Check if the page is product listing
    const isProductListing = pathSegments[0] === "product-listing";

    // Get city name dynamically from URL or localStorage as fallback
    const cityName = isProductListing
      ? pathSegments[1]
      : localStorage.getItem("cityName") || "Noida";

    // Get category name from URL or set default
    const categoryName = isProductListing && pathSegments[2]
      ? pathSegments[2]
      : "Furniture";

    let title, description;

    if (location.pathname === "/" || location.pathname === `/${cityName}`) {
      title = `Find Furniture & Appliances on rent in ${cityName} | Renty Furniture`;
      description = `Find furniture & appliances on rent in ${cityName} and enjoy flexibility like never before. From Bed to Refrigerator on rent, get fast delivery & free repair across ${cityName}. Rent Now!`;
    } else if (isProductListing) {
      title = `${categoryName} on rent in ${cityName} | Renty Furniture`;
      description = `Finding ${categoryName.toLowerCase()} for rent in ${cityName}? with fast delivery & free repair. we will help you rent ${categoryName.toLowerCase()} with one click. ${activeCategories}  and more at Renty Furniture`;
    } else if (pathSegments[0] === "product-details") {
      // Extract city and product name from the URL
      const cityName = pathSegments[1] || "Noida";
      const productName = decodeURIComponent(
        pathSegments[2]?.replace(/-/g, " ") || "Furniture"
      );

      title = `${productName} on rent in ${cityName} | Renty Furniture`;
      description = `${productName} in ${cityName} starting @${productdata?.perMonth}/Month. Get ${productName} on rent with fast delivery & free maintenance only at Renty Furniture.`;
    }
    else {
      const pageName = pathSegments.join(" ").replace(/-/g, " ") || "Page";
      title = `${pageName} | Renty Furniture`;
      description = `Explore ${pageName} at Renty Furniture. Stylish, affordable, and high-quality furniture for all your needs. Rent today!`;
    }
    // Update the document title
    document.title = title;

    // Update or create the meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", description);
    } else {
      const newMeta = document.createElement("meta");
      newMeta.name = "description";
      newMeta.content = description;
      document.head.appendChild(newMeta);
    }
  }, [location.pathname]);

  return null;
};
