import React, { useState, useEffect, useRef } from "react";
import "../../style/css/header.css";
//API Method
import logom from "./logo.png";
import {Drawer  as Drawernew, ButtonToolbar, IconButton, Placeholder } from 'rsuite';

import { fetchAllCity } from "../../actions/city/getAllCity";
import {
  fetchProductsByFilter,
  fetchProductsHomeByFilter,
} from "../../actions/products/getProductsByFilter";
//Redux
import { connect } from "react-redux";
//Logo and Icons
import Logo from "../../style/images/logo.png";
import { BiUser } from "react-icons/bi";
import { BiCurrentLocation } from "react-icons/bi";
import { isAndroid, isIOS } from 'react-device-detect';

import { SlLike } from "react-icons/si";
import { BiLike } from "react-icons/bi";
import { FaUser } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import { LiaSearchSolid } from "react-icons/lia";
import { FaHome } from "react-icons/fa";
import { BiSolidTimer } from "react-icons/bi";
import { ImProfile } from "react-icons/im";
import { TbFilterMinus } from "react-icons/tb";
//Bottom Bar
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FolderIcon from "@mui/icons-material/Folder";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SwipeableTemporaryDrawer from "../location/location";
import SelectLocation from "../location/selectLocation";
import { fetchCartProducts, fetchCartProductsBybrowserId } from "../../actions/products/cart/getCartProducts";
//Links
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "../../style/css/location.css";
import { fetchProductsBySearch } from "../../actions/products/getProductsBySearch";

import { GetAllPincodeByCityIdAction } from "../../actions/products/getAllPincodeByPincodeId";
// import component 👇
import Drawer from "react-modern-drawer";
import wishlist from "./heart.png";

import { useSelector, useDispatch } from "react-redux";
//import styles 👇
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CallMadeIcon from "@mui/icons-material/CallMade";

import "react-modern-drawer/dist/index.css";
import Cartpng from "../assets/carts (1).png";
import search from "./search.png";
import l66666cation from "./gps.png";

import { ArrowBack, HomeMax, HomeMaxSharp } from "@mui/icons-material";
import { basicAuth } from "../../actions/auth/basicAuth";
import axios from "axios";
import { fetchProductById } from "../../actions/products/getProductById";
import { fetchGetWishlist } from "../../actions/products/wishList/getWishList";
import { fetchMostOrderProductsByFilter } from "../../actions/products/getMostOrderproducts";
import { fetchCustomerById } from "../../actions/customer/getCustomerById";
function Header(props) {
  console.log(isIOS,'isIOS')
  const [open, setOpen] = React.useState(false);

  const location = useLocation();
      const [placement, setPlacement] = React.useState();
  

  const pathName=location.pathname

  const pathNamesearch = location.pathname.split('/')[1]; // This will give "SearchProduct"

  const dispatch = useDispatch();
  const pincodeBycityId = useSelector(
    (state) => state?.GetAllPincodeByCityIDREDCUER?.data
  );
  // console.log(pincodeBycityId, "pincodeBycityId");


  const isActive = (path) => {

    
    // Use a flexible match for the dynamic routes like "/noida"
    if (path === '/' && location.pathname === '/') return true;

    if (path === '/dashboard/profile' && location.pathname === '/dashboard/profile') return true;
    
    // For dynamic cities, use regex to match "/noida", "/delhi", etc.
    const dynamicPathPattern = /^\/[a-zA-Z0-9-]+$/; // Matches "/noida", "/delhi", etc.
    return dynamicPathPattern.test(location.pathname) && location.pathname.startsWith(path);
  };

  const cartData = useSelector((state) => state?.getCartProducts?.data);
  const cart = cartData?.cartitemList?.length;
  const hasLoaded=useRef(false)
  const hasLoadedcustomer=useRef(false)

  useEffect(() => {
    // Check if we're on the product details page based on the URL path
    if ( !hasLoaded.current) {
      dispatch(fetchCartProducts());
      hasLoaded.current = true;
    }
  }, [dispatch]);  // Depend on dispatch and location.pathname

  
  const customerId = localStorage.getItem('customerId'); // Retrieve customerId from localStorage

  useEffect(() => {
    // Only call fetchCustomerById if customerId exists and hasn't been called yet
    if (customerId && !hasLoadedcustomer.current) {
      dispatch(fetchCustomerById(customerId)); // Pass customerId as needed
      hasLoadedcustomer.current = true; // Prevent repeated calls
    }
  }, [dispatch, customerId]); // Depend on dispatch and customerId

  
  const {
    onOpenDrawer,
    fetchAllCityData,
    handleFetchAllCity,
    handleFetchProductsByFilter,
    message,
    ProductFilterData,
    error,
    setCityPincodeData,
    userSelectedPincode,
  } = props;

  const myStyle = {};

  const { data } = useSelector((state) => state.getProductsSearch);
  // console.log(data, "filterDatafilterDatafilterData");
  const [filterSuggestion, setFilteruggestionProducts] = useState();
  // console.log(filterSuggestion,'filterSuggestionfilterSuggestion')

  const isAuthenticated = localStorage.getItem("customerId");
  useEffect(()=>{
 if(!isAuthenticated){
  localStorage.removeItem('customername')
 }
  },[isAuthenticated])
  const isMobile = window.innerWidth <= 768;
  const { keyword } = useParams();
  const [value, setValue] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const toggleDrawer = () => {
    handleFetchAllCity()

    setIsOpen((prevState) => !prevState);

  };
  const [show, setShow] = useState(false);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // console.log(fetchAllCityData?.length)
  useEffect(() => {
      handleFetchAllCity();

  }, []);
  //Location selector
  const [selectedPincode, setSelectedPincode] = useState();
  const [selectedCity, setSelectedCity] = useState({
    cityName: "",
    pincode: "",
  });
  const [pincode, setPinCode] = useState("");
  const cat = localStorage.getItem("cat");

  const [filterData, setFilterData] = useState({
    categoryName: cat,
    subCategoryNames: [],
    minPrice: 0,
    maxPrice: 0,
    keyword: "",
    pincode: "",
  });
  useEffect(()=>{
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
    categoryName:cat
    }));
  },[cat])
  const [newPincode, setNewPincode] = useState();
  const [msgPincode, setPincodemg] = useState();

  const filteredCities = fetchAllCityData;

  // const HandleGetPincodeBycityId = () => {
  //     if (!selectedPincode) {
  //         setPincodemg('Please enter a Valid  pincode');
  //         return;
  //     }
      console.log(pincodeBycityId,'pincodeBycityIdpincodeBycityId')

  //     if (pincodeBycityId && selectedPincode) {
  //         const selectedPincodeInt = parseInt(selectedPincode, 10);
  //         const filtered = pincodeBycityId.filter(pin => pin.pinCode === selectedPincodeInt);

  //         if (selectedCity && selectedCity.pincode === selectedPincodeInt) {
  //             // If the searched pincode is already selected
  //             setPincodemg('This Pincode already selected');
  //         } else if (filtered.length === 0) {
  //             // If no matching pincode found
  //             setPincodemg('Pincode is not serviceable');
  //         } else {
  //             // Valid pincode found, reset message and update the data
  //             setPincodemg(''); // Clear the message
  //             toggleDrawer();
  //             const firstMatchedPin = filtered[0]; // Get the first matched pincode
  //             const updatedSelectedCity = {
  //                 cityName: firstMatchedPin.city.cityName,
  //                 pincode: firstMatchedPin.pinCode
  //             };
  //             setSelectedCity(updatedSelectedCity);
  //             setPinCode(firstMatchedPin.pinCode);
  //             setCityPincodeData(updatedSelectedCity);
  //         }
  //     } else {
          console.log('Data or selected pincode is missing');
  //     }
  // };

  const HandleGetPincodeBycityId = async (e) => {
    e.preventDefault();
    try {
      if(selectedPincode?.length<6){
        return setPincodemg("Please Enter a valid Pincode");

      }
      if(!selectedPincode){
        return setPincodemg("Pincode is not serviceable");
    }
      const headers = {
        "ngrok-skip-browser-warning": "skip-browser-warning",
        Authorization: basicAuth, // Ensure proper formatting
      };

      const baseUrl = "https://api.rentyfurniture.com/";
      const response = await axios.get(
        `${baseUrl}getCityByPin/${selectedPincode}`,
        { headers }
      );
      // console.log(response, "responseresponseresponseresponse");
      if (response == "Service Not Available  at this pin!") {
        return setPincodemg("Pincode is not serviceable");
      } else {
        
        console.log(response,'response')
        const item = {
          cityName: response?.data?.cityName,
          pincode: selectedPincode,
          cityId: response?.data?.cityId,
        };
        console.log(selectedPincode)
        localStorage.setItem("cityPincode", selectedPincode);
        localStorage.setItem("pincode", selectedPincode);
        handleCItySelect(item);
        setPicodeHeader(selectedPincode);
    

        setPincodemg("");
        setSelectedPincode('')

      }
    } catch (error) {
      setPincodemg("Pincode is not serviceable");

      console.error(
        "Error creating new ticket:",
        error.response ? error.response.data : error.message
      );
    }
  };

  // console.log(filteredCities, "filteredCities");
  const {pathname}=useLocation()

  const handleSearchProduct = () => {
    if (!filterData.keyword || filterData.keyword.trim() === "") {
      // Show an alert if the keyword is empty
      return; setInputkey(null);

      // Optional: Dispatch an action if needed
      // dispatch(yourActionCreator());
      // Redirect to a different route or do nothing
    }
    console.log(pathNamesearch,'pathNamesearch')
    if(pathNamesearch!=='/SearchProduct'){

      // handleFetchProductsByFilter(filterData);
    }
    
    navigate(`/SearchProduct/${filterData.keyword}`);
    setShowSuggestions(false)
    setInputkey(null);

  };
  useEffect(()=>{
    setShowSuggestions(false)

  },[pathname])

  // useEffect(() => {
  //     handleFetchProductsByFilter(filterData, keyword)

    
  // }, [filterData,dispatch]);

  useEffect(() => {
    localStorage.setItem("pincode", filterData.pincode);

  }, [filterData.pincode]);

  useEffect(() => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      keyword: keyword ? keyword : "",
      pincode: userSelectedPincode?.pincode || 201301,
    }));
  }, [userSelectedPincode]);

  useEffect(() => {
    const cameFIRST = localStorage.getItem("addddcarrrr");

    if (!cameFIRST) {
      localStorage.setItem("addddcarrrr", "true");
      toggleDrawer();
    }
  }, []);
  const isHomePage = location.pathname === "/" || /^\/[^/]+$/.test(location.pathname); // Example: "/", "/Ghaziabad"

  console.log(pathName,'isHomePage')
  const handleCItySelect = async (item) => {
 console.log(item)
    // console.log(item, "itemitem.pincodeitem.pincode");
    
    const updatedSelectedCity = {
        cityName: item.cityName,
        pincode: item.pincode,
    };
    const newCityname= decodeURIComponent(item.cityName)
    localStorage.setItem('cityName', newCityname.replace(' ', '-'));
    // const currentPathSegments = location.pathname.split("/").filter(Boolean); // Remove leading/trailing slashes
    localStorage.setItem("cityId", item?.cityId);

    localStorage.setItem("cityPincode", item?.pincode);
    // Dispatch actions
    dispatch(GetAllPincodeByCityIdAction(item?.cityId));
    dispatch(fetchProductsHomeByFilter(item?.cityId));

    // Set local state and local storage
    setSelectedCity(updatedSelectedCity);
    setPinCode(item.pincode);
    console.log(item.pincode)


    toggleDrawer();
    setOpen(false);


    // Set city pincode data
    setCityPincodeData(updatedSelectedCity);
  
    const cleanPath = location.pathname.replace(/^\/|\/$/g, "").toLowerCase();
    console.log(cleanPath, "cleanPath");
  
    // Check if the path matches '/SearchProduct'
    if (cleanPath.startsWith('searchproduct')) {
      const searchCategory = cleanPath.split('/')[1];  // Extract 'mattres' or other product category
  
      console.log("Search Category:", searchCategory); // Will log 'mattres' for '/SearchProduct/mattres'
  
      // Get the city pincode from localStorage or dynamically set it if needed
      const cityPincode = localStorage.getItem('cityPincode');
      console.log("City Pincode:", cityPincode); // Will log the stored pincode from localStorage
  
      // Dispatch the action to fetch products by search category (e.g., 'mattres') and pincode
      dispatch(fetchProductsBySearch(searchCategory, cityPincode));
  
      // Optionally set the city pincode if needed
      if (item?.pincode) {
        localStorage.setItem("cityPincode", item?.pincode);
      }
    }
    console.log(pathName,'isHomePage')
    if(pathName=='/cart'||pathName=='/MyOrderSummary'){
      dispatch(fetchCartProducts());
  dispatch(fetchCartProductsBybrowserId())

    }

    if(pathName=='/whishlist'){
      dispatch(fetchGetWishlist());
    }
  if (!isHomePage) {
    // Call APIs if not on the homepage
  dispatch(fetchProductById())
  setFilterData((prevFilterData) => {
    const newFilterData = {
        ...prevFilterData,
        keyword: keyword || '',
        pincode: item.pincode,
    };

    // Call fetch with the updated filter data and keyword
    // handleFetchProductsByFilter(newFilterData, keyword);
    return newFilterData;
});

  }
        dispatch(fetchMostOrderProductsByFilter(item?.cityId));
  
  // dispatch(fetchMostOrderProductsByFilter())


};


  const [inputkey, setInputkey] = useState(null);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchProduct();
    }
    setInputkey(null);
  };


// Function to fetch suggestions
const handleFetchSuggestions = async () => {
  if(!inputkey){
    return setShowSuggestions(false)
  }
  try {
    if (inputkey.length < 3) {
      // Skip API call if input is less than 3 characters
      return;
    }
     setShowSuggestions(true)

    const headers = {
      "ngrok-skip-browser-warning": "skip-browser-warning",
      Authorization: basicAuth, // Ensure proper formatting
    };

    const baseUrl = 'https://api.rentyfurniture.com/';
    const query = inputkey; // Use the current input as query

    const response = await axios.get(`${baseUrl}search/lookahead`, {
      params: { q: query }, // Send 'q' as a query parameter
      headers: headers, // Optional headers if needed
    });
  setFilteruggestionProducts(response?.data?.data?.autoComplete)
  
    // console.log('API Response:', response.data); // Log the response data
  } catch (error) {
    console.error('Error fetching suggestions:', error.response ? error.response.data : error.message);
  }
};
// Debounce function
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
// Debounced version of the fetch function to avoid API calls on every keystroke
const debouncedFetchSuggestions = debounce(handleFetchSuggestions, 300);

// Effect hook to trigger the fetch when inputkey changes
useEffect(() => {
  // console.log(inputkey,'inputkeyinputkeyinputkey')
 
  debouncedFetchSuggestions();
}, [inputkey])

   
  const [myPincode, setPicodeHeader] = useState();

  const handlepuincodePres = async (e) => {
    // console.log(selectedPincode,'selectedPincodeselectedPincode')
    if (e.key === "Enter") {
      e.preventDefault();
      if(selectedPincode?.length<6){
        return setPincodemg("Please Enter a valid Pincode");

      }
      if(!selectedPincode){
        return setPincodemg("Please Enter Valid Pincode");
    }
    try {
      const headers = {
        "ngrok-skip-browser-warning": "skip-browser-warning",
        Authorization: basicAuth, // Ensure proper formatting
      };

      const baseUrl = "https://api.rentyfurniture.com/";
      const response = await axios.get(
        `${baseUrl}getCityByPin/${selectedPincode}`,
        { headers }
      );
      // console.log(response, "responseresponseresponseresponse");
      if (response == "Service Not Available  at this pin!") {
        return setPincodemg("Pincode is not serviceable");
      } else {
        const item = {
          cityName: response?.data?.cityName,
          pincode: selectedPincode,
          cityId: response?.data?.cityId,
        };
        handleCItySelect(item);
        setPicodeHeader(selectedPincode);
        setSelectedPincode('')
        localStorage.setItem("cityPincode", selectedPincode);
        setPincodemg("");
      }
    } catch (error) {
      setPincodemg("Pincode is not serviceable");

      console.error(
        "Error creating new ticket:",
        error.response ? error.response.data : error.message
      );
    }
    }
    setInputkey(null);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      keyword: inputValue,
    }));
    setInputkey(inputValue.trim() === "" ? null : inputValue);
  };

  // useEffect(() => {
  //   // Check if inputKey is not null and has at least 3 characters
  //   if (inputkey && inputkey.length >= 3) {
  //     const delayDebounceFn = setTimeout(() => {
  //       dispatch(fetchProductsBySearch(inputkey, filterData?.pincode));
  //     }, 300); // Optional: Add a debounce delay

  //     return () => clearTimeout(delayDebounceFn); // Cleanup on unmount
  //   }
  // }, [inputkey, filterData?.pincode]);

  useEffect(() => {
    if (Array.isArray(data)) {
      const filteredSuggestions = data.filter((item) => {
        const searchTerm = inputkey?.toLowerCase();

        // Check if the item has a `status` of `true` and if any of the category names or product title contains the input key
        return (
          item?.status !== false && // Filter out items with status `false`
          (item?.productTitle?.toLowerCase()?.includes(searchTerm) ||
            item?.mainCategoryName?.toLowerCase()?.includes(searchTerm) ||
            item?.subCategoryName?.toLowerCase()?.includes(searchTerm) ||
            item?.superCategoryName?.toLowerCase()?.includes(searchTerm))
        );
      });
      // setFilteruggestionProducts(filteredSuggestions);
    } else {
      setFilteruggestionProducts([]);
    }
  }, [inputkey, data]);

  // console.log(data, "datataytcfasytcfasytc");


  useEffect(() => {
    const links = document.querySelectorAll(".item");
    links.forEach((link) => {
      if (link.pathname === location.pathname) {
        link.classList.add("active");
      } else {
        link.classList.remove("active");
      }
    });
  }, [location]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleInputFocus = () => {
    setShowSuggestions(true);
  };

  const handleInputBlur = () => {
    setShowSuggestions(false);
  };
  const handlenavigate = (e) => {
    e.preventDefault();
    if (isAuthenticated) {
      navigate("/whishlist");
    } else {
      navigate("/login");
    }
  };

  const storedCity=localStorage.getItem('cityName')
  const handleSuggestProducts = (item) => {
    const formattedProductName1 = item?.replace(/\s+/g, '-'); // Replace all spaces with hyphens
  
    if(storedCity){
      navigate(`/product-details/${storedCity}/${formattedProductName1}`);
      }else{
        navigate(`/product-details/${'noida'}/${formattedProductName1}`);
      }
  };
  const [cityId, setCityId] = useState(null);

  useEffect(() => {
    // Check if cityId exists in localStorage
    const storedCityId = localStorage.getItem('cityId');


    if (!storedCityId) {
      // If cityId exists in localStorage, use that
// If not, set the default cityId to 1
// localStorage.setItem('cityId', '1'); // Default to cityId 1
// setCityId(1);   
 }
 else {
    
  
    }
  }, []);
  const handleNaviate = () => {
    const storedCity = localStorage.getItem('cityName'); // Get the city name from localStorage
    if (storedCity) {
      // Decode the stored city to handle spaces properly
      const decodedCity = decodeURIComponent(storedCity);
  
      // Navigate to the URL-encoded version of the city
      navigate(`/${encodeURIComponent(decodedCity)}`); // Ensures the URL is properly formatted
    } else {
      console.log("No cityName found in localStorage");
    }
  };
  
  const isCartPage = location.pathname === "/cart"; // Replace "/cart" with your cart page path
  const MyOrderSummary = location.pathname === "/MyOrderSummary"; // Replace "/cart" with your cart page path
  const extractProductDetails = (pathname) => {
    if (pathname.includes("/product-details")) {
      return "product-details";
    }
    return null; // Return null if not found
  };
  const result = extractProductDetails(location.pathname);
  const cartPage = location.pathname === "/cart"||location.pathname === "/MyOrderSummary";
  // console.log(result,'location.pathname')


  useEffect(() => {
    const currentRoute = location.pathname;

    // Update the previous route only if it differs from the current route
    const prevRoute = localStorage.getItem('currentRoute');
    if (prevRoute !== currentRoute) {
      localStorage.setItem('prevRoute', prevRoute || '/'); // Default to '/' if no previous route exists
      localStorage.setItem('currentRoute', currentRoute);
    }

    console.log('Previous Route:', prevRoute);
  }, [location.pathname]);
  const goBack = () => {
    const prevRoute = localStorage.getItem('prevRoute') || '/'; // Default to root if no previous route
    navigate(prevRoute);
  };
  const handleClickNaviate = () => {
    // Retrieve `selectedId` from localStorage or set default to 1
    const selectedId = localStorage.getItem('selectedId') || 1;
  
    // Retrieve `cat` from localStorage or set default to 'bedroom'
    const category = localStorage.getItem('cat') || 'bedroom';
  
    // Set `selectedId` and `cat` in localStorage
    localStorage.setItem('selectedId', selectedId);
    const decodedValue = decodeURIComponent(category);

    localStorage.setItem('cat', decodedValue);
  
    // Navigate to the correct URL
    navigate(`/product-listing/${category}-on-rent-${storedCity}`);
  };
  useEffect(() => {
    const handleResize = () => {
      const keyboardHeight =
        window.innerHeight - document.documentElement.clientHeight;
      document.documentElement.style.setProperty(
        "--keyboard-height",
        `${keyboardHeight}px`
      );
    };
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  
  
  
  const handleOpen = key => {
    handleFetchAllCity()

    setOpen(true);
    setPlacement(key);
};

  return (
    <>
     <style>
        {`
          .MuiBox-root.css-1uin9l0 {
            z-index: ${isCartPage||MyOrderSummary||result ? 999 : 9999} !important;
          }
        `}
      </style>
      <style>
        {`
                    .css-1s0tc6b img {
    position: absolute;
    top: 0px;
    width: 31px;
}


              
                .item.active p{
                    color:black;
                    font-weight:700;
                }
                    .mobileheader{
                    display:none;
                    }

                    @media(max-width:768px){
                  .mobileheader{
                    display:block;
                    }     
                    .desktoheader{
                    display:none;
                    }               
                    }
                `}
      </style>
      {/* mobile header */}
      <div className="mobileheader">
        <div class="MuiBox-root css-clbtza" bis_skin_checked="1">
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Search Products</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="MuiInputBase-root MuiInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth css-1ie9fvn">
                <input
                  placeholder="What are you searching for?"
                  type="text"
                  class="MuiInputBase-input MuiInput-input css-mnn31"
                  value={filterData.keyword}
                  onKeyPress={handleKeyPress}
                  onChange={handleInputChange}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleClose}>
                <img
                  src={search}
                  style={{ width: "25px", height: "25px" }}
                  onClick={handleSearchProduct}
                />
              </Button>
            </Modal.Footer>
          </Modal>

          <div class="MuiBox-root css-11t353r" bis_skin_checked="1" style={{display:'flex',justifyContent:'space-between',width:'65%'}}>
            {
           isIOS  && cartPage&&(
                <>
                  <div className='button' style={{background:'rgb(245, 99, 88)',width:'28px',padding:'2px',margin:'10px',borderRadius:'100%',position:'relative',top:"10px"}}>
                              <button onClick={goBack} style={{color:'white'}}>
                          <ArrowBack color='white'></ArrowBack>
                          </button>
                          </div>
                </>
              )
            }
          {
          isIOS&&  !isHomePage?(
              <>
               <div onClick={goBack} className='button' style={{background:'rgb(245, 99, 88)',width:'28px',padding:'2px',margin:'10px',borderRadius:'100%',position:'relative',top:"10px"}}>
                              <button  style={{color:'white'}}>
                          <ArrowBack color='white'></ArrowBack>
                          </button>
                          </div>
              </>
            ):(
              <>
              <h3 style={{visibility:'hidden'}}>
              h
              </h3>
              </>
            )
          }
         
            <div class="MuiBox-root css-1r9aykm" bis_skin_checked="1">
          
              <div
                class="MuiBox-root css-cnjuyp"
                style={{ paddingTop: "0px" }}
                bis_skin_checked="1"
              >
                  <img
                  onClick={handleNaviate}
                    alt=""
                    style={{
                      height: "auto",
                      width: "100%",
                      visibility: "visible",
                    }}
                    data-srcset={logom}
                    sizes=""
                    srcset={logom}
                    class="loaded"
                  />
              </div>
            </div>
          </div>
          <div
            class="MuiBox-root css-i0vy0s"
            id="global-header"
            bis_skin_checked="1"
          >
            <div class="MuiBox-root css-fa5g5n" bis_skin_checked="1">
              <div class="MuiBox-root css-1kz6cvi" bis_skin_checked="1">
                <div
                  style={{
                    alignItems: "center",
                    flexDirection: "row",
                    display: "flex",
                  }}
                  bis_skin_checked="1"
                >
                  <div
                    class="MuiCollapse-root MuiCollapse-horizontal MuiCollapse-entered css-1cijpyl"
                    style={{ minWidth: "0px" }}
                    bis_skin_checked="1"
                  >
                    <div
                      class="MuiCollapse-wrapper MuiCollapse-horizontal css-164swfl"
                      bis_skin_checked="1"
                    >
                      <div
                        class="MuiCollapse-wrapperInner MuiCollapse-horizontal css-1mziwkx"
                        bis_skin_checked="1"
                      >
                        <div
                          class="MuiBox-root css-70qvj9"
                          bis_skin_checked="1"
                          onClick={toggleDrawer}
                        >
                          <div
                            class="MuiBox-root css-1ly73or"
                            bis_skin_checked="1"
                          >
                            <img
                              src={l66666cation}
                              style={{ width: "24px", height: "24px" }}
                            />
                          </div>
                          <div class="MuiBox-root css-0" bis_skin_checked="1">
                            <p class="MuiTypography-root MuiTypography-body1 css-rae83j"
                            onClick={() => handleOpen('bottom')}

                            >
                              Delivery to{" "}
                              {localStorage.getItem("cityPincode") || 201301}
                            </p>
                            <p class="MuiTypography-root MuiTypography-body1 css-12rb58m"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="MuiBox-root css-xflhna" bis_skin_checked="1">
                <div
                  style={{
                    alignItems: "center",
                    flexDirection: "row",
                    display: "flex",
                  }}
                  bis_skin_checked="1"
                >
                  <div
                    class="MuiCollapse-root MuiCollapse-horizontal MuiCollapse-entered css-1cijpyl"
                    style={{ minWidth: "0px" }}
                    bis_skin_checked="1"
                  >
                    <div
                      class="MuiCollapse-wrapper MuiCollapse-horizontal css-164swfl"
                      bis_skin_checked="1"
                    >
                      <div
                        class="MuiCollapse-wrapperInner MuiCollapse-horizontal css-1mziwkx"
                        bis_skin_checked="1"
                      >
                        <div
                          class="MuiBox-root css-1ay9vb9"
                          bis_skin_checked="1"
                        >
                          <Link to="/searchpage">
                            <img
                              src={search}
                              style={{ width: "25px", height: "25px" }}
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="MuiCollapse-root MuiCollapse-horizontal MuiCollapse-entered css-1cijpyl"
                    style={{ minWidth: "0px" }}
                    bis_skin_checked="1"
                  >
                    <div
                      class="MuiCollapse-wrapper MuiCollapse-horizontal css-164swfl"
                      bis_skin_checked="1"
                    >
                      <div
                        class="MuiCollapse-wrapperInner MuiCollapse-horizontal css-1mziwkx"
                        bis_skin_checked="1"
                      >
                        <div
                          class="MuiBox-root css-dvot97"
                          bis_skin_checked="1"
                        >
                          <button onClick={handlenavigate}>
                            <img
                              src={wishlist}
                              height={"25px"}
                              width={"25px"}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="MuiCollapse-root MuiCollapse-horizontal MuiCollapse-entered css-1cijpyl"
                    style={{ minWidth: "0px" }}
                    bis_skin_checked="1"
                  >
                    <div
                      class="MuiCollapse-wrapper MuiCollapse-horizontal css-164swfl"
                      bis_skin_checked="1"
                    >
                      <div
                        class="MuiCollapse-wrapperInner MuiCollapse-horizontal css-1mziwkx"
                        bis_skin_checked="1"
                      >
                          <Link to="/cart">

                        <div
                          class="MuiBox-root css-79elbk"
                          bis_skin_checked="1"
                        >
                            <img
                              src={Cartpng}
                              style={{ width: "22px", height: "22px" }}
                            />
                          <div
                            class="MuiBox-root css-1yjllpn"
                            bis_skin_checked="1"
                          >
                            {cart}
                          </div>
                        </div>
                        </Link>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

     
        <div className="MuiBox-root css-na2bnx containerww headerssss">
      <button
        onClick={handleClickNaviate}
        style={{
          textDecoration: "none",
          position: "relative",
          top: "7px",
        }}
        className="item"
      >
        <div className="MuiBox-root css-1fs6avd">
          <div className="MuiBox-root css-1kjzbsf">
            <BiSolidTimer
              style={{
                height: "25px",
                width: "25px",
                color:  "rgb(245, 99, 88)", // Active color is red
              }}
            />
          </div>
          <p
            className="MuiTypography-root MuiTypography-body1 css-c2nj2v"
            style={{
              color: "rgb(245, 99, 88)", // Active color is red
            }}
          >
            Rents
          </p>
        </div>
      </button>

      <NavLink
        to="/"
        style={{
          textDecoration: "none",
          position: "relative",
          top: "7px",
        }}
        className="item"
      >
        <div className="MuiBox-root css-1fs6avd">
          <div className="MuiBox-root css-1kjzbsf">
            <FaHome
              style={{
                height: "20px",
                width: "20px",
                color: isActive("/") ? "#f6bc1d" : "rgb(245, 99, 88)", // Active color is #f6bc1d
              }}
            />
          </div>
          <p
            className="MuiTypography-root MuiTypography-body1 css-c2nj2v"
            style={{
              color: isActive("/") ? "#f6bc1d" : "rgb(245, 99, 88)", // Active color is red
            }}
          >
            Home
          </p>
        </div>
      </NavLink>
 {
      !isAuthenticated?(
      <>
      <div className="MuiBox-root css-1fs6avd">
          <NavLink to='/login'
           style={{
          textDecoration: "none",
          position: "relative",
          top: "7px",
        }}
          >

          <div className="MuiBox-root css-1kjzbsf">
            <BiUser
              style={{
                height: "20px",
                width: "20px",
                color: "rgb(245, 99, 88)", // Active color is red
              }}
            />
          </div>
          <p
            className="MuiTypography-root MuiTypography-body1 css-c2nj2v"
            style={{
              color: "rgb(245, 99, 88)", // Active color is red
            }}
          >
            Profile
          </p>
          </NavLink>
        </div>
      
      </>
      ):(
      <NavLink
        to="/dashboard/profile"
        style={{
          textDecoration: "none",
          position: "relative",
          top: "7px",
        }}
        className="item"
      >
     

        <div className="MuiBox-root css-1fs6avd">
          <div className="MuiBox-root css-1kjzbsf">
            <BiUser
              style={{
                height: "20px",
                width: "20px",
                color: isActive("/dashboard/profile") ? "#f6bc1d" : "rgb(245, 99, 88)", // Active color is red
              }}
            />
          </div>
          <p
            className="MuiTypography-root MuiTypography-body1 css-c2nj2v"
            style={{
              color: isActive("/dashboard/profile") ? "rgb(247, 206, 117)" : "rgb(245, 99, 88)", // Active color is red
            }}
          >
            Profile
          </p>
        </div>
      </NavLink>
      )
      }
      
    </div>
      </div>
      {/* screenn */}
      <div className="header-container desktoheader">
        <noscript>
          <img width={1} height={1} style={{ display: "none" }} src={logom} />
        </noscript>
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-5LDD26Q"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
        <div id="__next">
          <div class="MuiBox-root css-1uovz60"></div>
          <div class="MuiBox-root css-0" id="main">
            <div class="MuiBox-root css-0">
              <div class="MuiBox-root css-1uin9l0" style={{zIndex:''}}>
                <div class="MuiBox-root css-12h61o9" id="header-content">
                  <div class="MuiBox-root css-1t5c0b2">
                    <div class="cursor-pointer MuiBox-root css-9e50nb">
                      <div
                        class="MuiBox-root css-1om2adh"
                        style={{ paddingTop: "10.638297872340425%" }}
                      >
                          <img
                  onClick={handleNaviate}

                            src={logom}
                            alt=""
                            data-srcset={logom}
                            sizes=""
                            class="lazy loaded"
                            style={{
                              height: "auto",
                              width: "100%",
                              visibility: "visible",
                              position: "relative",
                              top: "-30px",
                            }}
                            srcset={logom}
                          />
                      </div>
                    </div>
                    <div class="MuiBox-root css-1lirfxe">
                      <div class="MuiBox-root css-15ro776">
                        <div
                          class="hoverable  MuiBox-root css-4lkcnh"
                          icons="[object Object]"
                          id="header-location-pin"
                          onClick={toggleDrawer}
                        >
                          <img
                            src={l66666cation}
                            style={{ width: "24px", height: "24px" }}
                          />
                          <div class="MuiBox-root css-1gdh6jb">
                            <p class="MuiTypography-root MuiTypography-Small_Regular css-xm1owp">
                              Delivery to
                            </p>
                            <p class="MuiTypography-root MuiTypography-P_SemiBold css-4s9voa">
                              {localStorage.getItem("cityPincode") || 201301}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="MuiBox-root css-1eem22k">
                    <div class="MuiBox-root css-120gsus">
                      <div class="MuiBox-root css-ku4lgg">
                        <div class="MuiInputBase-root MuiInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth css-1ie9fvn">
                          <input
                            placeholder="What are you searching for?"
                            type="text"
                            class="MuiInputBase-input MuiInput-input css-mnn31"
                            value={filterData.keyword}
                            onFocus={handleInputFocus} // Show suggestions when input is focused
                            // Hide suggestions when input loses focus

                            onKeyPress={handleKeyPress}
                            onChange={handleInputChange}
                          />
                        </div>

                        <div
                          class="MuiBox-root css-8zy5qf"
                          style={{ position: "relative", top: "-5px" }}
                        >
                          <img
                            src={search}
                            style={{ width: "25px", height: "25px" }}
                            onClick={handleSearchProduct}
                          />
                        </div>
                      </div>
                      {showSuggestions &&
                        filterSuggestion &&
                        filterSuggestion.length > 0 && (
                          <div class="suggestion-box">
                            <ul class="suggestion-list">
                              {filterSuggestion &&
                                filterSuggestion.map((item) => {
                                  if (!item || !item.Label) {
                                    console.error(
                                      "Invalid item structure:",
                                      item
                                    );
                                    return null; // Skip rendering this item
                                  }
                                  return (
                                    <>
                                      <li
                                        key={item.id}
                                        className="d-flex "
                                        style={{ gap: "8px" }}
                                        onClick={() =>
                                          handleSuggestProducts(item?.Label)
                                        }
                                      >
                                        <CallMadeIcon
                                          style={{
                                            color: "black",
                                            fontSize: "20px",
                                          }}
                                          color="rgb(245, 99, 88)"
                                      
                                        />
                                        {item.Label}
                                      </li>
                                    </>
                                  );
                                })}
                            </ul>
                          </div>
                        )}
                    </div>
                    <div class="MuiBox-root css-120gsus">
                      <div class="popover_wrapper MuiBox-root css-0">
                        <div
                          class="popover_content popover_right MuiBox-root css-0"
                          id="mouse-over-popover"
                        >
                          <div class="MuiBox-root css-1bhdxsm">
                            <div class="MuiBox-root css-16vcgef">
                              <p class="MuiTypography-root MuiTypography-body1 css-1awhple">
                                Hello!{" "}
                              </p>
                              <p style={{ color: "black" }}>
                                {localStorage.getItem("customername")}
                              </p>
                              {isAuthenticated ? (
                                <>
                                  <Link to="/dashboard/profile">
                                    <p class="MuiTypography-root MuiTypography-body1 cursor-pointer css-1gndsm0">
                                      Dashboard
                                    </p>
                                  </Link>
                                  <Link to="/dashboard/all_request">
                                    <p class="MuiTypography-root MuiTypography-body1 cursor-pointer css-1gndsm0">
                                      Your Orders
                                    </p>
                                  </Link>
                                </>
                              ) : (
                                <Link to="/login">
                                  <p
                                    class="MuiTypography-root MuiTypography-body1 cursor-pointer css-1gndsm0"
                                    style={{ fontWeight: "700" }}
                                  >
                                    Login
                                  </p>
                                </Link>
                              )}

                              {/* <Link to="/registration">
                                                                    <p
                                                                        class="MuiTypography-root MuiTypography-body1 cursor-pointer css-1gndsm0">
                                                                        Registration</p>
                                                                </Link> */}

                              <hr class="MuiDivider-root MuiDivider-fullWidth css-39bbo6" />
                              <div class="MuiBox-root css-1yuhvjn"></div>
                            </div>
                          </div>
                        </div>
                        <div class="popover_title MuiBox-root css-1la3wyy">
                          <div class="MuiBox-root css-0">
                            <div
                              class="hoverable  MuiBox-root css-0"
                              icons="[object Object]"
                            >
                              <FaUser />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="MuiBox-root css-120gsus">
                      <div
                        class="hoverable move-up-transition MuiBox-root css-0"
                        icons="[object Object]"
                      >
                        {/* <Link to="/whishlist" > */}
                        <button onClick={handlenavigate}>
                          <img src={wishlist} height={"25px"} width={"25px"} />
                        </button>
                        {/* </Link> */}
                      </div>
                    </div>
                    <div class="MuiBox-root css-0">
                      <div
                        class="MuiCollapse-root MuiCollapse-horizontal MuiCollapse-entered css-1cijpyl"
                        style={{ minWidth: "0px" }}
                        bis_skin_checked="1"
                      >
                        <div
                          class="MuiCollapse-wrapper MuiCollapse-horizontal css-164swfl"
                          bis_skin_checked="1"
                        >
                          <div
                            class="MuiCollapse-wrapperInner MuiCollapse-horizontal css-1mziwkx"
                            bis_skin_checked="1"
                          >
                            <div
                              class="MuiBox-root css-79elbk"
                              bis_skin_checked="1"
                            >
                              <Link to="/cart">
                                <img src={Cartpng} style={{ width: "20px" }} />
                              </Link>
                              <div
                                class="MuiBox-root css-1yjllpn"
                                bis_skin_checked="1"
                              >
                                {cart}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /  Location Selection  / */}
      {isMobile ? (
        <>
        <style>
          {

            `
            /* Prevent layout shifts for mobile keyboards */
.fixed-drawer {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  overflow-y: auto; /* Allow scrolling if necessary */
  z-index: 1300 !important; /* Maintain proper stacking context */
}

            `
          }
        </style>
        <Drawernew placement={placement} open={open} onClose={() => setOpen(false)}>
        <div>
              <div
                class="MuiPaper-root MuiPaper-elevation MuiPaper-elevation16 MuiDrawer-paper MuiDrawer-paperAnchorBottom css-fy2uxz"
                tabindex="-1"
                bis_skin_checked="1"
                style={{
                  transform: "none",
                  height: "calc(100vh - var(--keyboard-height, 0px))", // Dynamically adjust based on keyboard height
                  transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                }}
              >
                <div class="MuiBox-root css-wie1fk" bis_skin_checked="1">
                  <div class="MuiBox-root css-163n7ae" bis_skin_checked="1">
                    <div class="MuiBox-root css-khle4t" bis_skin_checked="1">
                      <p class="MuiTypography-root MuiTypography-body1 css-m64942">
                        Select Delivery Location
                      </p>
                      <div class="MuiBox-root css-0" bis_skin_checked="1">
                        <svg
                          width="24"
                          height="24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => setOpen(false)}
                        >
                          <path
                            d="M18 6 6 18M6 6l12 12"
                            stroke="#555"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <style>
                      {`
                                                .inputagforr{
                                                border:1px solid black !important;
                                                }
                                                ::placeholder{
                                                text-align:center !important;
                                                }
                                                `}
                    </style>
                    <div
                      class="MuiBox-root css-14zwodr"
                      bis_skin_checked="1"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <div class="MuiBox-root css-j1akof" bis_skin_checked="1">
                        <div
                          class="MuiFormControl-root MuiTextField-root css-uw3g51"
                          bis_skin_checked="1"
                        >
                          <div
                            class="MuiInputBase-root MuiInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-10iatzg"
                            bis_skin_checked="1"
                          >
                            <input
                              aria-invalid="false"
                              autocomplete="off"
                              id=":r1:"
                              name="mobile"
                              placeholder="Enter your pincode"
                              type="tel"
                              minlength="6"
                              maxlength="6"
                              color="#FFFFFF"
                              className="MuiInputBase-input MuiInput-input css-mnn31 inputagforr"
                              value={selectedPincode}
                              onKeyDown={handlepuincodePres}
                              onChange={(e) =>
                                setSelectedPincode(e.target.value)
                              }
                              style={{
                                borderRadius: "30px",
                                border: "1px solid black",
                                padding: "9px",
                                fontSize: "12px", // Set font size to 16px or larger
                            }}
                        
                            />
                          </div>
                        </div>

                        <div
                          class="MuiBox-root css-bx8o6j"
                          bis_skin_checked="1"
                          role="button"
                          onClick={HandleGetPincodeBycityId}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5 12H19"
                              stroke="#069BAA"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M12 5L19 12L12 19"
                              stroke="#069BAA"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <p
                        class="MuiTypography-root MuiTypography-Small_Medium css-z77hf"
                        style={{ color: "red" }}
                      >
                        {msgPincode}
                      </p>
                    </div>
                    <p
                      class="MuiTypography-root MuiTypography-body1 css-1j5np21"
                      style={{ width: "100%" }}
                    >
                      Currently selected pincode :
                      <span class="MuiTypography-root MuiTypography-body1 css-1vbw26">
                        {localStorage.getItem("cityPincode") || 201301}
                      </span>

                    </p>
                    <div style={{display:'flex',justifyContent:'center',textAlign:'center',width:'100%'}}>
                  Select Delivery Location

                  </div>
                    
                    <div class="MuiBox-root css-u78fzv p-2" style={{marginBottom:'110px',margin:'auto'}} bis_skin_checked="1">
                  {Array.isArray(filteredCities) &&
                  filteredCities.length !== 0 ? (
                    filteredCities.map((item, index) =>
                      // Check if the city status is true
                      item?.status ? (
                        <div
                          key={index}
                          className={`cursor-pointer MuiBox-root css-14shhfv ${
                            selectedCity?.cityName === item.cityName
                              ? "selected-city"
                              : ""
                          }`}
                          style={{
                            border:
                              selectedCity?.cityName === item.cityName
                                ? "2px solid #069BAA"
                                : "",
                            boxShadow:
                              selectedCity?.cityName === item.cityName
                                ? "0 0 10px rgba(6, 155, 170, 0.5)"
                                : "",
                            borderRadius:
                              selectedCity?.cityName === item.cityName
                                ? "20px"
                                : "",
                          }}
                          onClick={() => {
                            handleCItySelect(item);
                          }}
                        >
                          <div
                            class="MuiBox-root css-1om2adh"
                            bis_skin_checked="1"
                            style={{ paddingTop: "80%" }}
                          >
                            <img
                              src={
                                item.cityImagesPhotoPath.startsWith("https")
                                  ? item?.cityImagesPhotoPath
                                  : item?.cityImagesPhoto
                              }
                              alt=""
                              data-srcset={
                                item.cityImagesPhotoPath.startsWith("https")
                                  ? item?.cityImagesPhotoPath
                                  : item?.cityImagesPhoto
                              }
                              sizes=""
                              class="lazy loaded"
                              style={{
                                height: "auto",
                                width: "100%",
                                visibility: "visible",
                              }}
                              srcset={
                                item.cityImagesPhotoPath.startsWith("https")
                                  ? item?.cityImagesPhotoPath
                                  : item?.cityImagesPhoto
                              }
                            />
                          </div>
                          <p class="MuiTypography-root MuiTypography-P_Medium css-1f9bf0l pt-3">
                            {item.cityName}
                          </p>
                        </div>
                      ) : null
                    )
                  ) : (
                    <p>There is no city present</p>
                  )}
                </div>
                  </div>
                </div>
              </div>
            </div>
            </Drawernew>
          {/* <Drawer
  className="fixed-drawer bla bla bla"
            open={isOpen}
            onClose={toggleDrawer}
            direction={"bottom"}
            style={{ height: "70vh" ,transition:'200ms'}}
          >
            <div>
              <div
                class="MuiPaper-root MuiPaper-elevation MuiPaper-elevation16 MuiDrawer-paper MuiDrawer-paperAnchorBottom css-fy2uxz"
                tabindex="-1"
                bis_skin_checked="1"
                style={{
                  transform: "none",
                  height: "70vh",
                  transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                }}
              >
                <div class="MuiBox-root css-wie1fk" bis_skin_checked="1">
                  <div class="MuiBox-root css-163n7ae" bis_skin_checked="1">
                    <div class="MuiBox-root css-khle4t" bis_skin_checked="1">
                      <p class="MuiTypography-root MuiTypography-body1 css-m64942">
                        Select Delivery Location
                      </p>
                      <div class="MuiBox-root css-0" bis_skin_checked="1">
                        <svg
                          width="24"
                          height="24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => setIsOpen(false)}
                        >
                          <path
                            d="M18 6 6 18M6 6l12 12"
                            stroke="#555"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <style>
                      {`
                                                .inputagforr{
                                                border:1px solid black !important;
                                                }
                                                ::placeholder{
                                                text-align:center !important;
                                                }
                                                `}
                    </style>
                    <div
                      class="MuiBox-root css-14zwodr"
                      bis_skin_checked="1"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <div class="MuiBox-root css-j1akof" bis_skin_checked="1">
                        <div
                          class="MuiFormControl-root MuiTextField-root css-uw3g51"
                          bis_skin_checked="1"
                        >
                          <div
                            class="MuiInputBase-root MuiInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-10iatzg"
                            bis_skin_checked="1"
                          >
                            <input
                              aria-invalid="false"
                              autocomplete="off"
                              id=":r1:"
                              name="mobile"
                              placeholder="Enter your pincode"
                              type="tel"
                              minlength="6"
                              maxlength="6"
                              color="#FFFFFF"
                              className="MuiInputBase-input MuiInput-input css-mnn31 inputagforr"
                              value={selectedPincode}
                              onKeyDown={handlepuincodePres}
                              onChange={(e) =>
                                setSelectedPincode(e.target.value)
                              }
                              style={{
                                borderRadius: "30px",
                                border: "1px solid black",
                                padding: "9px",
                                fontSize: "12px", // Set font size to 16px or larger
                            }}
                        
                            />
                          </div>
                        </div>

                        <div
                          class="MuiBox-root css-bx8o6j"
                          bis_skin_checked="1"
                          role="button"
                          onClick={HandleGetPincodeBycityId}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5 12H19"
                              stroke="#069BAA"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M12 5L19 12L12 19"
                              stroke="#069BAA"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <p
                        class="MuiTypography-root MuiTypography-Small_Medium css-z77hf"
                        style={{ color: "red" }}
                      >
                        {msgPincode}
                      </p>
                    </div>
                    <p
                      class="MuiTypography-root MuiTypography-body1 css-1j5np21"
                      style={{ width: "100%" }}
                    >
                      Currently selected pincode :
                      <span class="MuiTypography-root MuiTypography-body1 css-1vbw26">
                        {localStorage.getItem("cityPincode") || 201301}
                      </span>

                    </p>
                    <div style={{display:'flex',justifyContent:'center',textAlign:'center',width:'100%'}}>
                  Select Delivery Location

                  </div>
                    
                    <div class="MuiBox-root css-u78fzv p-2" style={{marginBottom:'110px'}} bis_skin_checked="1">
                  {Array.isArray(filteredCities) &&
                  filteredCities.length !== 0 ? (
                    filteredCities.map((item, index) =>
                      // Check if the city status is true
                      item?.status ? (
                        <div
                          key={index}
                          className={`cursor-pointer MuiBox-root css-14shhfv ${
                            selectedCity?.cityName === item.cityName
                              ? "selected-city"
                              : ""
                          }`}
                          style={{
                            border:
                              selectedCity?.cityName === item.cityName
                                ? "2px solid #069BAA"
                                : "",
                            boxShadow:
                              selectedCity?.cityName === item.cityName
                                ? "0 0 10px rgba(6, 155, 170, 0.5)"
                                : "",
                            borderRadius:
                              selectedCity?.cityName === item.cityName
                                ? "20px"
                                : "",
                          }}
                          onClick={() => {
                            handleCItySelect(item);
                          }}
                        >
                          <div
                            class="MuiBox-root css-1om2adh"
                            bis_skin_checked="1"
                            style={{ paddingTop: "80%" }}
                          >
                            <img
                              src={
                                item.cityImagesPhotoPath.startsWith("https")
                                  ? item?.cityImagesPhotoPath
                                  : item?.cityImagesPhoto
                              }
                              alt=""
                              data-srcset={
                                item.cityImagesPhotoPath.startsWith("https")
                                  ? item?.cityImagesPhotoPath
                                  : item?.cityImagesPhoto
                              }
                              sizes=""
                              class="lazy loaded"
                              style={{
                                height: "auto",
                                width: "100%",
                                visibility: "visible",
                              }}
                              srcset={
                                item.cityImagesPhotoPath.startsWith("https")
                                  ? item?.cityImagesPhotoPath
                                  : item?.cityImagesPhoto
                              }
                            />
                          </div>
                          <p class="MuiTypography-root MuiTypography-P_Medium css-1f9bf0l pt-3">
                            {item.cityName}
                          </p>
                        </div>
                      ) : null
                    )
                  ) : (
                    <p>There is no city present</p>
                  )}
                </div>
                  </div>
                </div>
              </div>
            </div>
          </Drawer> */}
        </>
      ) : (
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction={"left"}
          className="bla bla bla"
          style={{
            width: "",
            height: "100%",
            overflowY: "auto",
            padding: "20px",
          }}
        >
          <div>
            <div
              class="MuiBox-root css-1rp0757"
              id="location-selector"
              bis_skin_checked="1"
            >
              <div class="MuiBox-root css-uzd8m2" bis_skin_checked="1">
                <p class="MuiTypography-root MuiTypography-H10A_Regular css-mqetcj">
                  Select Delivery Location
                </p>
                <div
                  class="cursor-pointer MuiBox-root css-0"
                  bis_skin_checked="1"
                >
                    <svg
                          width="24"
                          height="24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => setIsOpen(false)}
                        >
                          <path
                            d="M18 6 6 18M6 6l12 12"
                            stroke="#555"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                </div>
              </div>
              <div class="MuiBox-root css-naaosy" bis_skin_checked="1">
                <form style={{ width: "100%" }}>
                  <style>
                    {`
                                                .inputagforr{
                                                border:1px solid black !important;
                                                }
                                                `}
                  </style>
                  <div
                    class="MuiFormControl-root MuiTextField-root css-113fqis"
                    bis_skin_checked="1"
                  >
                    <div
                      class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-adornedEnd css-a46wc5"
                      bis_skin_checked="1"
                    >
                      <input
                        aria-invalid="false"
                        id=":r2:"
                        placeholder="Enter your pincode"
                        type="tel"
                        inputmode="numeric"
                        pattern="[0-9]*"
                        minlength="5"
                        maxlength="6"
                        class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedEnd css-1uvydh2 inputagforr"
                        value={selectedPincode}
                        onKeyPress={handlepuincodePres}
                        onChange={(e) => setSelectedPincode(e.target.value)}
                        style={{ borderRadius: "30px", padding: "9px" }}
                      />
                      <div
                        class="MuiInputAdornment-root MuiInputAdornment-positionEnd MuiInputAdornment-outlined MuiInputAdornment-sizeMedium css-1nvf7g0"
                        onClick={HandleGetPincodeBycityId}
                        bis_skin_checked="1"
                      >
                        <button
                          class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-lia39s"
                          tabindex="0"
                          type="button"
                        >
                          <div class="MuiBox-root css-0" bis_skin_checked="1">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5 12.9453H19"
                                stroke="#069BAA"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="right-arrow-icon"
                              ></path>
                              <path
                                d="M12 5.94531L19 12.9453L12 19.9453"
                                stroke="#069BAA"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="right-arrow-icon"
                              ></path>
                            </svg>
                          </div>
                          <span class="MuiTouchRipple-root css-w0pj6f"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <p
                  class="MuiTypography-root MuiTypography-Small_Medium css-z77hf"
                  style={{ color: "red" }}
                >
                  {msgPincode}
                </p>
                <div class="MuiBox-root css-b8l48z" bis_skin_checked="1">
                  <p class="MuiTypography-root MuiTypography-Small_Medium css-z77hf">
                    Currently selected pincode :{localStorage.getItem("cityPincode") || 201301}

                  </p>
                </div>
              </div>
              <div class="MuiBox-root css-0" bis_skin_checked="1">
                <div class="MuiBox-root css-1pa9coj" bis_skin_checked="1">
                  <hr class="MuiDivider-root MuiDivider-fullWidth css-39bbo6" />
                  <p class="MuiTypography-root MuiTypography-H11A_Medium css-1s17izv">
                    Or select your city
                  </p>
                  <hr class="MuiDivider-root MuiDivider-fullWidth css-39bbo6" />
                </div>
                <div class="MuiBox-root css-u78fzv p-2" bis_skin_checked="1">
                  {Array.isArray(filteredCities) &&
                  filteredCities.length !== 0 ? (
                    filteredCities.map((item, index) =>
                      // Check if the city status is true
                      item?.status ? (
                        <div
                          key={index}
                          className={`cursor-pointer MuiBox-root css-14shhfv ${
                            selectedCity?.cityName === item.cityName
                              ? "selected-city"
                              : ""
                          }`}
                          style={{
                            border:
                              selectedCity?.cityName === item.cityName
                                ? "2px solid #069BAA"
                                : "",
                            boxShadow:
                              selectedCity?.cityName === item.cityName
                                ? "0 0 10px rgba(6, 155, 170, 0.5)"
                                : "",
                            borderRadius:
                              selectedCity?.cityName === item.cityName
                                ? "20px"
                                : "",
                          }}
                          onClick={() => {
                            handleCItySelect(item);
                          }}
                        >
                          <div
                            class="MuiBox-root css-1om2adh"
                            bis_skin_checked="1"
                            style={{ paddingTop: "80%" }}
                          >
                            <img
                              src={
                                item.cityImagesPhotoPath.startsWith("https")
                                  ? item?.cityImagesPhotoPath
                                  : item?.cityImagesPhoto
                              }
                              alt=""
                              data-srcset={
                                item.cityImagesPhotoPath.startsWith("https")
                                  ? item?.cityImagesPhotoPath
                                  : item?.cityImagesPhoto
                              }
                              sizes=""
                              class="lazy loaded"
                              style={{
                                height: "auto",
                                width: "100%",
                                visibility: "visible",
                              }}
                              srcset={
                                item.cityImagesPhotoPath.startsWith("https")
                                  ? item?.cityImagesPhotoPath
                                  : item?.cityImagesPhoto
                              }
                            />
                          </div>
                          <p class="MuiTypography-root MuiTypography-P_Medium css-1f9bf0l pt-3">
                            {item.cityName}
                          </p>
                        </div>
                      ) : null
                    )
                  ) : (
                    <p>There is no city present</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      )}
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    fetchAllCityData: state.getAllCity.data,
    message: state.getProductsByFilter.message,
    error: state.getProductsByFilter.error,
    userSelectedPincode: state.setCityPincode.data,
    ProductFilterData: state.getProductsByFilter.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleFetchAllCity: () => dispatch(fetchAllCity()),

    handleFetchProductsByFilter: (data) =>
      dispatch(fetchProductsByFilter(data)),
    setCityPincodeData: (data) =>
      dispatch({ type: "SET_CITY_PINCODE_DATA", payload: data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
