import React,{useEffect, useState} from 'react'
import "../../style/css/contactus.scss"
import { basicAuth } from '../../actions/auth/basicAuth';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
function Contact_Us() {
    const [data,setdata]=useState({
        name:"",
        email:"",
        message:""
    })
    const [error,setErrorMessages]=useState({
        name:"",
        email:"",
        message:""
    })

    const validateAddressData = () => {
        let errors = {};
        
        // Check required fields in `data` and `errorMessages`
        if (!data?.name) {
          errors.name = "name is required.";
        }
        if (!data?.email) {
          errors.email = "email is required.";
        }
        if (!data?.message) {
          errors.message = "message is required.";
        }
      
        // Check for any errors
        if (Object.keys(errors).length > 0) {
          // If there are errors, set them in the state and return false
          setErrorMessages(errors);
          return false;
        } else {
          // Clear errors if validation passes
          setErrorMessages({});
          return true;
        }
      };
      const [loading,setLoadin]=useState(false)
      
      const handleclick = async (e) => {
        e.preventDefault();
      
        // Run validation first
        if (!validateAddressData()) {
          return; // Prevent API call if validation fails
        }
      
      
        // Proceed with the API call if validation passes
        setLoadin(true)
        try {
          const headers = {
            "ngrok-skip-browser-warning": "skip-browser-warning",
            Authorization: basicAuth, // Ensure proper formatting
          };
          
          const newData={
            customerEmail:data?.email,
            customerName:data?.name,
            customerMessage:data?.message
          }
          
          const baseUrl = 'https://api.rentyfurniture.com/';
          const response = await axios.post(`${baseUrl}sendContactUsEmail`, newData, { headers });
          
          Swal.fire({
            title: 'Form Submitted',
            text: 'Form Submitted successfully ',
            icon: 'success',
        });    
        setLoadin(false)

        setdata({
            name:"",
            email:"",
            message:""
        })
        } catch (error) {
          console.error('Error creating new ticket:', error.response ? error.response.data : error.message);
        }finally{
        setLoadin(false)

        }
      };
      
    const isMobile = window.innerWidth <= 768;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const handleChange=(e)=>{
        const {name,value}=e.target
        setdata({
            ...data,
            [name]:value
        })

    }
    return (
        <div>
            {/* Style 1 */}
            {isMobile ?

                <>
                    {/* Style 2 */}

                    <section>

                        {/* <div class="section-header">
                    <div class="container">
                        <h2>Contact Us</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                </div> */}

                        <div class="container">
                            <style>
                                {

                                        `
                                        .inputbox{
                                        border:1px solid black !important;
                                        }
                                        .border{
                                        border:1px solid black !important;
                                        }
                                        `
                                }
                            </style>
                            <div class="row">
                                {/* 
                        <div class="contact-info">
                            <div class="contact-info-item">
                                <div class="contact-info-icon">
                                    <i class="fas fa-home"></i>
                                </div>

                                <div class="contact-info-content">
                                    <h4>Address</h4>
                                    <p>4671 Sugar Camp Road,<br /> Owatonna, Minnesota, <br />55060</p>
                                </div>
                            </div>

                            <div class="contact-info-item">
                                <div class="contact-info-icon">
                                    <i class="fas fa-phone"></i>
                                </div>

                                <div class="contact-info-content">
                                    <h4>Phone</h4>
                                    <p>571-457-2321</p>
                                </div>
                            </div>

                            <div class="contact-info-item">
                                <div class="contact-info-icon">
                                    <i class="fas fa-envelope"></i>
                                </div>

                                <div class="contact-info-content">
                                    <h4>Email</h4>
                                    <p>ntamerrwael@mfano.ga</p>
                                </div>
                            </div>
                        </div> */}
   <div class="">
                                                <div class="contact_form_inner">
                                                    <div class="">
                                                        <h3>Contact Us</h3>
                                                        <p>Feel Free to contact us any time. We will get back to you as soon as we can!.</p>
                                                        <input  value={data?.name} onChange={handleChange} name='name'  type="text" class="form-control form-group border" placeholder="Name" />
                                                        <p style={{color:'red'}}>{error?.name}</p>
                                                        <input  value={data?.email} onChange={handleChange}  name="email" type="text" class="form-control form-group border" placeholder="Email" />
                                                        <p style={{color:'red'}}>{error?.email}</p>

                                                        <textarea value={data?.message} onChange={handleChange} name='message' class="form-control form-group border" placeholder="Message"></textarea>
                                                        <p style={{color:'red'}}>{error?.message}</p>

                                                        <button class="contact_form_submit" onClick={handleclick}>{loading?<CircularProgress color="inherit" size={24} />:"Send"}</button>
                                                    </div>
                                                </div>
                                            </div>

                            </div>
                        </div>
                    </section>
                </>
                :
                <>
                    <section class="contact_us" >
                        <div class="container">
                            <div class="row">
                                <div class="col-md-10 offset-md-1">
                                    <div class="contact_inner">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <div class="contact_form_inner">
                                                    <div class="contact_field">
                                                        <h3>Contact Us</h3>
                                                        <p>Feel Free to contact us any time. We will get back to you as soon as we can!.</p>
                                                        <input value={data?.name} onChange={handleChange} name='name'  type="text" class="form-control form-group" placeholder="Name" />
                                                        <p style={{color:'red'}}>{error?.name}</p>
                                                        <input  value={data?.email} onChange={handleChange}  name="email" type="text" class="form-control form-group" placeholder="Email" />
                                                        <p style={{color:'red'}}>{error?.email}</p>

                                                        <textarea value={data?.message} onChange={handleChange} name='message' class="form-control form-group" placeholder="Message"></textarea>
                                                        <p style={{color:'red'}}>{error?.message}</p>

                                                        <button class="contact_form_submit" onClick={handleclick}>{loading?<CircularProgress color="inherit" size={24} />:"Send"}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="right_conatct_social_icon d-flex align-items-end">
                                                    <div class="socil_item_inner d-flex">
                                                        <li><a href="#"><i class="fab fa-facebook-square"></i></a></li>
                                                        <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="contact_info_sec">
                                            <h4>Contact Info</h4>
                                            <div class="d-flex info_single align-items-center">
                                                <i class="fas fa-headset"></i>
                                                <span>+91 9999436043</span>
                                            </div>
                                            <div class="d-flex info_single align-items-center">
                                                <i class="fas fa-envelope-open-text"></i>
                                                <span>rentyfurniture.com</span>
                                            </div>
                                            <div class="d-flex info_single align-items-center">
                                                <i class="fas fa-map-marked-alt"></i>
                                                <span>Address - 718, T1- Assotech Business Cresterra Sector 135 Noida.</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    

                    {/* <section class="map_sec">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-10 offset-md-1">
                                    <div class="map_inner mt-5">
                                        <h4>Find Us on Google Map</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quo beatae quasi assumenda, expedita aliquam minima tenetur maiores neque incidunt repellat aut voluptas hic dolorem sequi ab porro, quia error.</p>
                                        <div class="map_bind">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d471220.5631094339!2d88.04952462217592!3d22.6757520733225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f882db4908f667%3A0x43e330e68f6c2cbc!2sKolkata%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1596988408134!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}

                </>


            }
        </div>
    )
}

export default Contact_Us