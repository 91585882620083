
import {
    POST_LOGIN_OTP_REQUEST,
    POST_LOGIN_OTP_SUCCESS,
    POST_LOGIN_OTP_FAILURE,
} from "../types";
import axios from "axios";
// import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";
export const sendLoginOtpRequest = () => {
    return {
        type: POST_LOGIN_OTP_REQUEST,
    };
};
export const sendLoginOtpSuccess = (Data) => {

    return {
        type: POST_LOGIN_OTP_SUCCESS,
        payload: Data,
    };
};

export const sendLoginOtpFailure = (Error) => {
    return {
        type: POST_LOGIN_OTP_FAILURE,
        payload: Error,
    };
};

export const sendLoginOtp = (number) => {

    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
    };

    var val = Math.floor(1000 + Math.random() * 9000);
    localStorage.setItem("Otp", val)

    return (dispatch) => {
        dispatch(sendLoginOtpRequest());
        let sender_id = "DDRFUR"
        axios
            .post(`https://api.rentyfurniture.com/sendLoginOtp`,{mobile:number},{headers:headers})
            .then(function (response) {
                
                dispatch(sendLoginOtpSuccess(response.data));
                // localStorage.setItem("messageOtp", "OtpSentSuccessfully")
            })
            .catch(function (error) {
                localStorage.setItem("message", "OtpFailedToSend")
                dispatch(sendLoginOtpFailure(error));
            });


    };
};


