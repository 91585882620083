// ScrollToTop.js
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  // Create a ref to the top of the page (or any specific element you want to scroll to)
  const topRef = useRef(null);

  const location = useLocation();

  useEffect(() => {
    // Scroll to the element referenced by 'topRef' whenever the route changes
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location]); // This effect runs every time the location changes

  return <div ref={topRef} />; // An empty div is rendered, acting as a reference point for scroll
};

export default ScrollToTop;
