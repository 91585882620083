import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
//Redux
import { connect, useSelector } from "react-redux";
//API Methods
import { sendLoginOtp } from "../../actions/auth/login";
import { fetchCustomerByNumber } from "../../actions/customer/getCustomerByNumber";
import "../../style/css/login.css";
import axios from "axios";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { UpdateCartItemsBybrowserIdCustomerIdAction } from "../../actions/products/cart/updateCartItemBybandCId";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { basicAuth } from "../../actions/auth/basicAuth";
import { url } from "../../actions/auth/baseUrl";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ArrowBack } from "@mui/icons-material";
function Otp(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token=localStorage.getItem('customerId')
  useEffect(()=>{
   if(token){
      navigate('/')
   }
  },[])
  const backgroundStyle = {
    backgroundImage: `url('path-to-your-background-image.jpg')`, // Replace 'path-to-your-background-image.jpg' with the actual path to your image file
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };
  const userData = useSelector((state) => state?.getCustomerByNumber?.data);
  const loaded = useSelector((state) => state?.getCustomerByNumber?.loading);

  console.log(userData, "userDatattatata");

  const location = useLocation();

  useDispatch(() => {}, []);

  const {
    handleSendLoginOtp,
    message,
    error,
    handleFetchCustomerByNumber,
    customerMessage,
    customerError,
  } = props;
  const [number, setNumber] = useState("");
  const [yesOtp, setYesOtp] = useState(false);

  const [otpverify, setOtpVerify] = useState(false);
  const [phone, setPhone] = useState("");
  const [hasFilled, setHasFilled] = useState(false);
  const [otp, setOtp] = useState("");
  const handleLogin = async () => {
    const myNumbernumber = localStorage.getItem("numberme");
    dispatch(sendLoginOtp(myNumbernumber));
    await Swal.fire({
      title: "OTP Resent",
      text: "Please Check OTP Sent",
      icon: "success",
    });
  };

  const OtpData = localStorage.getItem("Otp");
  //Handle Check Customer Is exit or not
  useEffect(() => {
    if (localStorage.getItem("message") == "CustomerExist") {
      localStorage.removeItem("message");
      handleSendLoginOtp(number);
    }
    if (localStorage.getItem("message") === "CustomerNotExist") {
      localStorage.removeItem("message");
      Swal.fire({
        title: "Customer Is Not Exist",
        text: "Please Signup",
        icon: "error",
      });
      navigate("/registration");
    }
  }, [customerMessage, customerError]);

  //CHeck Otp Sent Successfully or not
  // useEffect(() => {

  //     if (localStorage.getItem("message") === "OtpSentSuccessfully") {

  //         localStorage.removeItem("message");
  //         setHasFilled(true);

  //     }
  //     if (localStorage.getItem("message") === "OtpFailedToSend") {

  //         Swal.fire({
  //             title: 'Something Went Wrong!!',
  //             text: 'Please enter right otp',
  //             icon: 'error',
  //         });
  //         localStorage.removeItem("message");
  //     }
  // }, [message, error])

  //Handle Verify Otp
const newemial=localStorage.getItem('numberme')
const [loading,setloading]=useState(false)  

const handleVerifyOtp = async () => {
  const newdata = {
    "phone": newemial,
    "otp": otp
  };

  const headers = {
    "Content-Type": "application/json",
    'Authorization': basicAuth,
  };

  try {
    setloading(true)
    const response = await axios.post(`${url}verifyOtpAndLogin`, newdata, { headers });

    // Handle the response as needed
    console.log('OTP verified and customer created:', response.data);

    if (response.status === 200) {
      Swal.fire({
        title: 'OTP Verified Successfully',
        text: 'Your OTP has been successfully verified.',
        icon: 'success',
      });

      const redirectTo = await localStorage.getItem('redirectAfterLogin') || '/';
      console.log(redirectTo);

      localStorage.removeItem("Otp");
      localStorage.setItem('login', "true");
      console.log(response.data, 'customer data');

      localStorage.setItem('customerId', response.data?.customerId);
      localStorage.setItem('customername', response.data?.name);

      // Dispatch action to update cart items
      dispatch(UpdateCartItemsBybrowserIdCustomerIdAction());
      setloading(false)
      // Navigate to the redirect URL
      navigate(redirectTo);

    } else {
      setloading(false)

      Swal.fire({
        title: 'Invalid OTP',
        text: 'The OTP you entered is incorrect. Please check the OTP and try again.',
        icon: 'error',
      });
    }

  } catch (error) {
    setloading(false)

    Swal.fire({
      title: 'Invalid OTP',
      text: 'The OTP you entered is incorrect. Please check the OTP and try again.',
      icon: 'error',
    });

    // Log the error for further analysis
    console.error('Error verifying OTP:', error);
  }
};
const handleresendotp=async()=>{
  try{
    dispatch(sendLoginOtp(newemial))
  await  Swal.fire({
      title: 'OTP Resent',
      text: 'Your OTP Resent successfully',
      icon: 'success',
  });
  }catch(er){
    Swal.fire({
      title: 'Resend Failed',
      text: 'Resend OTP Failed',
      icon: 'error',
  });
  }
  
}

const handleKeyDown = (e) => {
  if (e.key === 'Enter') {
    handleVerifyOtp();
  }
};
const handleNavgate=()=>{
  localStorage.removeItem('Otp')
  localStorage.removeItem('messageOtp')
  localStorage.removeItem('numberme')


  navigate('/login')
}

  return (
    <div className="body_color">
          <div className='button' style={{background:'rgb(245, 99, 88)',width:'41px',padding:'9px',margin:'10px',borderRadius:'100%'}}>
                      <button onClick={handleNavgate} style={{color:'white'}}>
                  <ArrowBack color='white'></ArrowBack>
                  </button>
                  </div>
      <style>
        {`
                        @media(max-width:767px){
                        .bobobokobk{
                        display:none;
                        }
                        }
                        `}
      </style>
      <div class="box-form" style={{ marginTop: "70px" }}>
        <div class="left" style={{ width: "100%" }}>
          <div class="overlay">
            <p></p>
            <span></span>
          </div>
        </div>
        <div class="right" style={{ width: "95%" }}>
          <h5 style={{ boxShadow: "", fontWeight: 1000, marginTop: "40px" }}>
            OTP
          </h5>
          <br></br>
          <span style={{ fontSize: "12px", marginTop: "15px" }}>
            {" "}
            We have send OTP to your number
          </span>
          <div class="inputs">
            <input
              type="text"
              placeholder="Enter Your OTP Here"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              onKeyPress={handleKeyDown}
            />
            <div
              onClick={handleresendotp}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
                marginTop: "5px",
                marginBottom: "5px",
                cursor: "pointer",
                color: "red",
              }}
            >
              Resend OTP
            </div>
          </div>

          <div
            class=""
            style={{
              display: "flex",
              gap: "4px",
              flexWrap: "wrap",
              justifyContent: "",
              marginTop: "",
              padding: "0px",
            }}
          >
            <button style={{ margin: "auto" }} className='button' onClick={handleVerifyOtp}>
              {
                loading?(
                  <>
                    <Backdrop
                            style={{ position: "unset", background: "unset" ,width:'35px',height:'20px'}}
                            sx={{
                              color: "white",
                              zIndex: (theme) => theme.zIndex.drawer + 999,
                            }}
                            open={loading} // Set open state based on the loading prop
                          >
                            <CircularProgress color="inherit" />
                          </Backdrop>
                  </>
                ):(
                  <>
              Verify
                  
                  </>
                )
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state, ownProps) => {
  return {
    fetchAllCityData: state.getAllCity.data,
    message: state.sendOtpSms.message,
    error: state.sendOtpSms.error,
    customerMessage: state.getCustomerByNumber.message,
    customerError: state.getCustomerByNumber.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleSendLoginOtp: (number) => dispatch(sendLoginOtp(number)),
    handleFetchCustomerByNumber: (number) =>
      dispatch(fetchCustomerByNumber(number)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Otp);
