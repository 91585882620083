


import React, { useEffect } from 'react'
import Table from 'react-bootstrap/Table';

import { fetchAllOrdersByCustomerId } from '../../../actions/products/orders/getAllOrdersByCustomerId'

import { GETALLCANCELpLNS } from '../../../actions/products/orders/getAllcancelPlan';
import { useDispatch, useSelector } from 'react-redux'
function Close_Subscription_Items() {
  const data = useSelector((state) => state?.getcanccel?.data)
  console.log(data,'datadatadatadatadata')
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(GETALLCANCELpLNS())
  }, [dispatch])
  return (
    <div className=''  striped bordered hover style={{height:'73vh',overflow:'auto'}}>
      <h4>
    <div>Close Plans</div>

      </h4>
    <Table striped bordered hover>
    <thead>
      <tr>
        <th>Product</th>
        <th>Order Id</th>
        <th>Rental Request</th>
        <th>Plan</th>


        <th>Rental Status</th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(data) ? (
          [...data].reverse()?.map((item, index) => (
          <tr style={{cursor:'pointer'}} key={index}>
            <td>
            {item?.productName}
            </td>
            <td>{item.orderId}</td>

            <td>{item.rentalPlanCancelRequest}</td>
            <td>{item.duration} Month</td>
            <td>{item.rentalStatus}</td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="7">No data available</td>
        </tr>
      )}
    </tbody>
  </Table>
  </div>
  )
}

export default Close_Subscription_Items