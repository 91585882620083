import React, { useState, useEffect, useRef } from "react";
import "../../style/css/header.css";
import { fetchAllCity } from "../../actions/city/getAllCity";
import { fetchProductsByFilter, fetchProductsHomeByFilter } from "../../actions/products/getProductsByFilter";
import { connect } from "react-redux";
//Logo and Icons
import Logo from "../../style/images/logo.png";
import { BiCurrentLocation } from "react-icons/bi";
import { SlLike } from "react-icons/si";
import { BiLike } from "react-icons/bi";
import { FaUser } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import { LiaSearchSolid } from "react-icons/lia";
import { FaHome } from "react-icons/fa";
import { BiSolidTimer } from "react-icons/bi";
import { ImProfile } from "react-icons/im";
import { TbFilterMinus } from "react-icons/tb";
//Bottom Bar
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FolderIcon from "@mui/icons-material/Folder";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SwipeableTemporaryDrawer from "../location/location";
import SelectLocation from "../location/selectLocation";
import { fetchCartProducts } from "../../actions/products/cart/getCartProducts";
//Links
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "../../style/css/location.css";
import { fetchProductsBySearch } from "../../actions/products/getProductsBySearch";

import { GetAllPincodeByCityIdAction } from "../../actions/products/getAllPincodeByPincodeId";
// import component 👇
import Drawer from "react-modern-drawer";

import { useSelector, useDispatch } from "react-redux";
//import styles 👇
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CallMadeIcon from "@mui/icons-material/CallMade";

import "react-modern-drawer/dist/index.css";
import { ArrowBack } from "@mui/icons-material";
import axios from "axios";
import { basicAuth } from "../../actions/auth/basicAuth";
import { fetchProductById } from "../../actions/products/getProductById";
function PincodeCom(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const { id } = props; // Destructure id from props
   console.log(id,'prodycrs')
  const dispatch = useDispatch();
  const pincodeBycityId = useSelector(
    (state) => state?.GetAllPincodeByCityIDREDCUER?.data
  );
  console.log(pincodeBycityId, "pincodeBycityId");

  const cartData = useSelector((state) => state?.getCartProducts?.data);
  const cart = cartData?.cartitemList?.length;
  const location=useLocation()
   const hasLoaded=useRef(false)
  
    useEffect(() => {
      // Check if we're on the product details page based on the URL path
      if (location.pathname.startsWith('/product-details/') && !hasLoaded.current) {
        dispatch(fetchCartProducts());
        hasLoaded.current = true;
      }
    }, [dispatch, location.pathname]);  // Depend on dispatch and location.pathname
  
  const {
    onOpenDrawer,
    fetchAllCityData,
    handleFetchAllCity,
    handleFetchProductsByFilter,
    message,
    ProductFilterData,
    error,
    setCityPincodeData,
    userSelectedPincode,
  } = props;

  const { data } = useSelector((state) => state.getProductsSearch);
  console.log(data, "filterDatafilterDatafilterData");
  const [filterSuggestion, setFilteruggestionProducts] = useState();

  const isAuthenticated = localStorage.getItem("login");
  const isMobile = window.innerWidth <= 768;
  const { keyword } = useParams();
  const [value, setValue] = React.useState(0);
  const [isOpen2, set2IsOpen] = React.useState(false);
  const navigate = useNavigate();
  const toggle2 = () => {
    handleFetchAllCity()
  
    set2IsOpen((prevState) => !prevState);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    handleFetchAllCity();
  }, []);
  //Location selector
  const [selectedPincode, setSelectedPincode] = useState();
  const [selectedCity, setSelectedCity] = useState({
    cityName: "",
    pincode: "",
  });
  const [pincode, setPinCode] = useState("");

  const [filterData, setFilterData] = useState({
    categoryName: "",
    subCategoryNames: [],
    minPrice: 0,
    maxPrice: 0,
    keyword: "",
    pincode: "",
  });
  const [newPincode, setNewPincode] = useState();
  const [msgPincode, setPincodemg] = useState();

  const [myPincode, setPicodeHeader] = useState();

  const HandleGetPincodeBycityId = async (e) => {
    e.preventDefault();
    try {
      if(selectedPincode?.length<6){
        return setPincodemg("Please Enter a valid Pincode");

      }
      if (!selectedPincode) {
        return setPincodemg(" Please Enter Valid Pincode");
      }
      const headers = {
        "ngrok-skip-browser-warning": "skip-browser-warning",
        Authorization: basicAuth, // Ensure proper formatting
      };

      const baseUrl = "https://api.rentyfurniture.com/";
      const response = await axios.get(
        `${baseUrl}getCityByPin/${selectedPincode}`,
        { headers }
      );
      console.log(response, "responseresponseresponseresponse");
      if (response == "Service Not Available  at this pin!") {
        return setPincodemg("Pincode is not serviceable");
      } else {
        const item = {
          cityName: response?.data?.cityName,
          pincode: response?.data?.pincode,
          cityId: response?.data?.cityId,
        };
        handleCItySelect(item);
        setPicodeHeader(selectedPincode);
        localStorage.setItem("cityPincode", selectedPincode);
        setSelectedPincode('')
        setPincodemg("");
      }
    } catch (error) {
      setPincodemg("Pincode is not serviceable");

      console.error(
        "Error creating new ticket:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const filteredCities = fetchAllCityData;

  console.log(filteredCities, "filteredCities");
  const handleSearchProduct = () => {
    handleFetchProductsByFilter(filterData);
    navigate(`/SearchProduct/${filterData.keyword}`);
  };

  // useEffect(() => {
  //   handleFetchProductsByFilter(filterData, keyword);
  // }, [filterData]);

  useEffect(() => {
    localStorage.setItem("pincode", filterData.pincode);
  }, [filterData.pincode]);

  useEffect(() => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      keyword: keyword ? keyword : "",
      pincode: userSelectedPincode?.pincode || 201301,
    }));
  }, [userSelectedPincode]);

  const handleCItySelect = async (item) => {
    console.log(item, "itemitem.pincodeitem.pincode");
    
    const updatedSelectedCity = {
        cityName: item.cityName,
        pincode: item.pincode,
    };
    const newCityname= decodeURIComponent(item.cityName)
    localStorage.setItem('cityName', newCityname.replace(' ', '-'));
    // Dispatch actions
    dispatch(GetAllPincodeByCityIdAction(item?.cityId));
    dispatch(fetchProductsHomeByFilter(item?.cityId));

    // Set local state and local storage
    setSelectedCity(updatedSelectedCity);
    setPinCode(item.pincode);
    localStorage.setItem("cityId", item?.cityId);
    localStorage.setItem("cityPincode", item?.pincode);

    // Update filter data
    setFilterData((prevFilterData) => {
        const newFilterData = {
            ...prevFilterData,
            keyword: keyword || '',
            pincode: item.pincode,
        };

        // Call fetch with the updated filter data and keyword
        handleFetchProductsByFilter(newFilterData, keyword);
        return newFilterData;
    });

    // Toggle the drawer
    toggle2();

    // Set city pincode datagget
    setCityPincodeData(updatedSelectedCity);
  dispatch(fetchProductById(id))

};

  const [inputkey, setInputkey] = useState(null);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchProduct();
    }
    setInputkey(null);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    // Update keyword in filterData state
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      keyword: inputValue,
    }));

    // Set inputKey to null if inputValue is an empty string
    setInputkey(inputValue.trim() === "" ? null : inputValue);
  };

  useEffect(() => {
    // Check if inputKey is not null before dispatching the action
    if (inputkey !== null) {
      dispatch(fetchProductsBySearch(inputkey, filterData?.pincode));
    }
  }, [inputkey, filterData?.pincode]);

  useEffect(() => {
    // Update suggestions based on the filtered products
    if (Array.isArray(data)) {
      const filteredSuggestions = data.filter((item) =>
        item?.productTitle.toLowerCase().includes(inputkey?.toLowerCase())
      );
      setFilteruggestionProducts(filteredSuggestions);
    } else {
      setFilteruggestionProducts([]);
    }
  }, [inputkey, data]);

  const handleSuggestProducts = (item) => {
    console.log(item, "itemmm");
    navigate(`/product-details/${item.productId}`);
  };

  useEffect(() => {
    const links = document.querySelectorAll(".item");
    links.forEach((link) => {
      if (link.pathname === location.pathname) {
        link.classList.add("active");
      } else {
        link.classList.remove("active");
      }
    });
  }, [location]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleInputFocus = () => {
    setShowSuggestions(true);
  };
  const handlepuincodePres = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      try {
        if (!selectedPincode) {
          return setPincodemg("Please Enter a valid Pincode");
        }
        if(selectedPincode?.length<6){
          return setPincodemg("Please Enter a valid Pincode");
  
        }
       
        const headers = {
          "ngrok-skip-browser-warning": "skip-browser-warning",
          Authorization: basicAuth, // Ensure proper formatting
        };

        const baseUrl = "https://api.rentyfurniture.com/";
        const response = await axios.get(
          `${baseUrl}getCityByPin/${selectedPincode}`,
          { headers }
        );
        console.log(response, "responseresponseresponseresponse");
        if (response == "Service Not Available  at this pin!") {
          return setPincodemg("Pincode is not serviceable");
        } else {
          const item = {
            cityName: response?.data?.cityName,
            pincode: response?.data?.pincode,
            cityId: response?.data?.cityId,
          };
          handleCItySelect(item);
          setPicodeHeader(selectedPincode);
          localStorage.setItem("cityPincode", selectedPincode);
          setPincodemg("");
        setSelectedPincode('')

        }
      } catch (error) {
        setPincodemg("Pincode is not serviceable");

        console.error(
          "Error creating new ticket:",
          error.response ? error.response.data : error.message
        );
      }
    }
    setInputkey(null);
  };
  const handleInputBlur = () => {
    setShowSuggestions(false);
  };
  return (
    <>
      <style>
        {`
                                                .inputagforr{
                                                border:1px solid black !important;
                                                }
                                                `}
      </style>
      <style>
        {`
              
                .item.active p{
                    color:black;
                    font-weight:700;
                }
                    .mobileheader{
                    display:none;
                    }

                    @media(max-width:768px){
                  .mobileheader{
                    display:block;
                    }     
                    .desktoheader{
                    display:none;
                    }               
                    }
                `}
      </style>
      {/* mobile header */}

      {/* screenn */}
      <div class="MuiBox-root css-1lirfxe">
        <div class="MuiBox-root css-15ro776">
          <div
            class="hoverable  MuiBox-root css-4lkcnh"
            icons="[object Object]"
            id="header-location-pin"
            onClick={toggle2}
          >
            <BiCurrentLocation color="white" />
            <div class="MuiBox-root css-1gdh6jb">
              <p
                class="MuiTypography-root MuiTypography-Small_Regular css-xm1owp"
                style={{ padding: "0px", color: "white" }}
              >
                Change Pincode
              </p>
              {/* <p class="MuiTypography-root MuiTypography-P_SemiBold css-4s9voa">{userSelectedPincode?.pincode || 201301} */}
            </div>
          </div>
        </div>
      </div>

      {/* /  Location Selection  / */}
      {isMobile ? (
        <>
          <Drawer
            open={isOpen2}
            onClose={toggle2}
            direction={"bottom"}
            className="bla bla bla"
            style={{ height: "60vh" }}
          >
            <div>
              <div
                class="MuiPaper-root MuiPaper-elevation MuiPaper-elevation16 MuiDrawer-paper MuiDrawer-paperAnchorBottom css-fy2uxz"
                tabindex="-1"
                bis_skin_checked="1"
                style={{
                  transform: "none",
                  transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                }}
              >
                <div class="MuiBox-root css-wie1fk" bis_skin_checked="1">
                  <div class="MuiBox-root css-163n7ae" bis_skin_checked="1">
                    <div class="MuiBox-root css-khle4t" bis_skin_checked="1">
                      <p
                        class="MuiTypography-root MuiTypography-body1 css-m64942"
                        style={{ color: "black" }}
                      >
                        Select Delivery Location
                      </p>
                      <div class="MuiBox-root css-0" bis_skin_checked="1">
                        <svg
                          width="24"
                          height="24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18 6 6 18M6 6l12 12"
                            stroke="#555"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <style>
                      {`
                                                .inputagforr{
                                                border:1px solid black !important;
                                                }
                                                `}
                    </style>
                    <div class="MuiBox-root css-14zwodr" bis_skin_checked="1">
                      <div class="MuiBox-root css-j1akof" bis_skin_checked="1">
                        <div
                          class="MuiFormControl-root MuiTextField-root css-uw3g51"
                          bis_skin_checked="1"
                        >
                          <div
                            class="MuiInputBase-root MuiInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-10iatzg"
                            bis_skin_checked="1"
                          >
                            <input
                              aria-invalid="false"
                              autocomplete="off"
                              id=":r1:"
                              name="mobile"
                              placeholder="Enter your pincode"
                              type="number"
                              minlength="6"
                              maxlength="6"
                              color="#FFFFFF"
                              className="MuiInputBase-input MuiInput-input css-mnn31 inputagforr"
                              value={selectedPincode}
                              onKeyDown={handlepuincodePres}
                              onChange={(e) =>
                                setSelectedPincode(e.target.value)
                              }
                              style={{
                                borderRadius: "30px",
                                border: "1px solid black",
                                padding: "9px",
                              }}
                            />
                          </div>
                        </div>

                        <div
                          class="MuiBox-root css-bx8o6j"
                          bis_skin_checked="1"
                          role="button"
                          onClick={HandleGetPincodeBycityId}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5 12H19"
                              stroke="#069BAA"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M12 5L19 12L12 19"
                              stroke="#069BAA"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <p
                        class="MuiTypography-root MuiTypography-Small_Medium css-z77hf"
                        style={{ color: "red" }}
                      >
                        {msgPincode}
                      </p>
                    </div>
                    <p class="MuiTypography-root MuiTypography-body1 css-1j5np21">
                      Currently selected pincode :
                      <span class="MuiTypography-root MuiTypography-body1 css-1vbw26">
                      {localStorage.getItem("cityPincode") || 201301}
                      </span>
                    </p>
                    <div class="MuiBox-root css-d9r98t" bis_skin_checked="1">
                      <p class="">Or select your city</p>

                      <div class="MuiBox-root css-yx6nxt" bis_skin_checked="1">
                        <div
                          class="MuiBox-root css-vmndsz"
                          bis_skin_checked="1"
                        ></div>
                      </div>
                      {Array.isArray(filteredCities) &&
                      filteredCities.length !== 0 ? (
                        filteredCities.map((item, index) =>
                          item.status ? (
                            <div
                              key={index}
                              className={`cursor-pointer MuiBox-root css-14shhfv ${
                                selectedCity?.cityName === item.cityName
                                  ? "selected-city"
                                  : ""
                              }`}
                              style={{
                                border:
                                  selectedCity?.cityName === item.cityName
                                    ? "2px solid #069BAA"
                                    : "",
                                boxShadow:
                                  selectedCity?.cityName === item.cityName
                                    ? "0 0 10px rgba(6, 155, 170, 0.5)"
                                    : "",
                                borderRadius:
                                  selectedCity?.cityName === item.cityName
                                    ? "20px"
                                    : "",
                              }}
                              onClick={() => {
                                handleCItySelect(item);
                              }}
                            >
                              <div
                                class="MuiBox-root css-1om2adh"
                                bis_skin_checked="1"
                                style={{
                                  paddingTop: "80%",
                                  position: "relative",
                                  top: "",
                                }}
                              >
                                <img
                                  src={
                                    item.cityImagesPhotoPath.startsWith("https")
                                      ? item?.cityImagesPhotoPath
                                      : item?.cityImagesPhoto
                                  }
                                  alt=""
                                  data-srcset={
                                    item.cityImagesPhotoPath.startsWith("https")
                                      ? item?.cityImagesPhotoPath
                                      : item?.cityImagesPhoto
                                  }
                                  sizes=""
                                  class="lazy loaded"
                                  style={{
                                    height: "auto",
                                    width: "100%",
                                    visibility: "visible",
                                  }}
                                  srcset={
                                    item.cityImagesPhotoPath.startsWith("https")
                                      ? item?.cityImagesPhotoPath
                                      : item?.cityImagesPhoto
                                  }
                                />
                              </div>
                              <p class="MuiTypography-root MuiTypography-P_Medium css-1f9bf0l">
                                {item.cityName}
                              </p>
                            </div>
                          ) : null
                        )
                      ) : (
                        <p>There is no city present</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
        </>
      ) : (
        <Drawer
          open={isOpen2}
          onClose={toggle2}
          direction={"left"}
          className="bla bla bla"
          style={{
            width: "",
            height: "100%",
            overflowY: "auto",
            padding: "20px",
          }}
        >
          <div>
            <div
              class="MuiBox-root css-1rp0757"
              id="location-selector"
              bis_skin_checked="1"
            >
              <div class="MuiBox-root css-uzd8m2" bis_skin_checked="1">
                <p
                  class="MuiTypography-root MuiTypography-H10A_Regular css-mqetcj"
                  style={{ color: "black" }}
                >
                  Select Delivery Location
                </p>
                <div
                  class="cursor-pointer MuiBox-root css-0"
                  bis_skin_checked="1"
                >
                  <svg
                    width="24"
                    height="24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => set2IsOpen(false)}
                  >
                    <path
                      d="M18 6 6 18M6 6l12 12"
                      stroke="#555"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="MuiBox-root css-naaosy" bis_skin_checked="1">
                <form style={{ width: "100%" }}>
                  <style>
                    {`
                                                .inputagforr{
                                                border:1px solid black !important;
                                                }
                                                `}
                  </style>
                  <div
                    class="MuiFormControl-root MuiTextField-root css-113fqis"
                    bis_skin_checked="1"
                  >
                    <div
                      class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-adornedEnd css-a46wc5"
                      bis_skin_checked="1"
                    >
                      <input
                        aria-invalid="false"
                        id=":r2:"
                        placeholder="Enter your pincode"
                        type="number"
                        inputmode="numeric"
                        onKeyDown={handlepuincodePres}
                        pattern="[0-9]*"
                        minlength="5"
                        maxlength="6"
                        class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedEnd css-1uvydh2 inputagforr"
                        value={selectedPincode}
                        onChange={(e) => setSelectedPincode(e.target.value)}
                        style={{ borderRadius: "30px" }}
                      />
                      <div
                        class="MuiInputAdornment-root MuiInputAdornment-positionEnd MuiInputAdornment-outlined MuiInputAdornment-sizeMedium css-1nvf7g0"
                        onClick={HandleGetPincodeBycityId}
                        bis_skin_checked="1"
                      >
                        <button
                          class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-lia39s"
                          tabindex="0"
                          type="button"
                        >
                          <div class="MuiBox-root css-0" bis_skin_checked="1">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5 12.9453H19"
                                stroke="#069BAA"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="right-arrow-icon"
                              ></path>
                              <path
                                d="M12 5.94531L19 12.9453L12 19.9453"
                                stroke="#069BAA"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="right-arrow-icon"
                              ></path>
                            </svg>
                          </div>
                          <span class="MuiTouchRipple-root css-w0pj6f"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <p
                  class="MuiTypography-root MuiTypography-Small_Medium css-z77hf"
                  style={{ color: "red" }}
                >
                  {msgPincode}
                </p>
                <div class="MuiBox-root css-b8l48z" bis_skin_checked="1">
                  <p class="MuiTypography-root MuiTypography-Small_Medium css-z77hf">
                  Currently selected pincode :
                  {localStorage.getItem("cityPincode") || 201301}
                  

                  </p>
                  <p class="MuiTypography-root MuiTypography-Small_SemiBold css-81x7e">

                  </p>
                </div>
              </div>
              <div class="MuiBox-root css-0" bis_skin_checked="1">
                <div class="MuiBox-root css-1pa9coj" bis_skin_checked="1">
                  <hr class="MuiDivider-root MuiDivider-fullWidth css-39bbo6" />
                  <p
                    class="MuiTypography-root MuiTypography-H11A_Medium css-1s17izv"
                    style={{ color: "black" }}
                  >
                    Or select your city
                  </p>
                  <hr class="MuiDivider-root MuiDivider-fullWidth css-39bbo6" />
                </div>
                <div
                  class="MuiBox-root css-u78fzv p-2"
                  style={{ margin: "auto" }}
                  bis_skin_checked="1"
                >
                  {Array.isArray(filteredCities) &&
                  filteredCities.length !== 0 ? (
                    filteredCities.map((item, index) =>
                      // Check if the city status is true
                      item.status ? (
                        <div
                          key={index}
                          className={`cursor-pointer MuiBox-root css-14shhfv ${
                            selectedCity?.cityName === item.cityName
                              ? "selected-city"
                              : ""
                          }`}
                          style={{
                            border:
                              selectedCity?.cityName === item.cityName
                                ? "2px solid #069BAA"
                                : "",
                            boxShadow:
                              selectedCity?.cityName === item.cityName
                                ? "0 0 10px rgba(6, 155, 170, 0.5)"
                                : "",
                            borderRadius:
                              selectedCity?.cityName === item.cityName
                                ? "20px"
                                : "",
                          }}
                          onClick={() => {
                            handleCItySelect(item);
                          }}
                        >
                          <div
                            class="MuiBox-root css-1om2adh"
                            bis_skin_checked="1"
                            style={{ paddingTop: "80%" }}
                          >
                            <img
                              src={
                                item.cityImagesPhotoPath.startsWith("https")
                                  ? item?.cityImagesPhotoPath
                                  : item?.cityImagesPhoto
                              }
                              alt=""
                              data-srcset={
                                item.cityImagesPhotoPath.startsWith("https")
                                  ? item?.cityImagesPhotoPath
                                  : item?.cityImagesPhoto
                              }
                              sizes=""
                              class="lazy loaded"
                              style={{
                                height: "auto",
                                width: "100%",
                                visibility: "visible",
                              }}
                              srcset={
                                item.cityImagesPhotoPath.startsWith("https")
                                  ? item?.cityImagesPhotoPath
                                  : item?.cityImagesPhoto
                              }
                            />
                          </div>
                          <p class="MuiTypography-root MuiTypography-P_Medium css-1f9bf0l pt-3">
                            {item.cityName}
                          </p>
                        </div>
                      ) : null
                    )
                  ) : (
                    <p>There is no city present</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      )}
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    fetchAllCityData: state.getAllCity.data,
    message: state.getProductsByFilter.message,
    error: state.getProductsByFilter.error,
    userSelectedPincode: state.setCityPincode.data,
    ProductFilterData: state.getProductsByFilter.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleFetchAllCity: () => dispatch(fetchAllCity()),

    handleFetchProductsByFilter: (data) =>
      dispatch(fetchProductsByFilter(data)),
    setCityPincodeData: (data) =>
      dispatch({ type: "SET_CITY_PINCODE_DATA", payload: data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PincodeCom);
