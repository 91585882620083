import React from 'react'
import { Drawer, ButtonToolbar, Button, IconButton, Placeholder } from 'rsuite';
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';
import AngleLeftIcon from '@rsuite/icons/legacy/AngleLeft';
import AngleDownIcon from '@rsuite/icons/legacy/AngleDown';
import AngleUpIcon from '@rsuite/icons/legacy/AngleUp';

import rentyCover from '../assets/Renty-Cover.png'
import 'rsuite/dist/rsuite.min.css';
import { ThumbDown } from '@mui/icons-material';
const RentyCover = () => {
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();
    const isMobile = window.innerWidth <= 768;

    const handleOpen = key => {
        setOpen(true);
        setPlacement(key);
    };
    return (
        <div>
            <style>
                {

                    `
                    p{
                    font-family:Recline !important;
                    }
                    
                    `
                }
            </style>


            {
                isMobile ? (
                    <>
 <div class="MuiBox-root css-1wj33lv" bis_skin_checked="1" onClick={()=>handleOpen('bottom')}>
                                    <div class="MuiBox-root css-1xxmc96" style={{ width: "100%" }} bis_skin_checked="1">
                                        <div class="MuiBox-root css-1wf4a85" bis_skin_checked="1">
                                            <div class="MuiBox-root css-1ggxnpa" bis_skin_checked="1">
                                                <svg width="59" height="64" viewBox="0 0 59 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 14.2222L5.17188 17.0952L8 22.3492L10.8281 17.0952L16 14.2222L10.8281 11.3492L8 6.09521L5.17188 11.3492L0 14.2222Z" fill="#E6F5F6"></path><path d="M8 56.8889L13.1719 59.4028L16 64.0001L18.8281 59.4028L24 56.8889L18.8281 54.3751L16 49.7778L13.1719 54.3751L8 56.8889Z" fill="#E6F5F6"></path><path d="M42 7.11111L47.4951 9.625L50.5 14.2222L53.5049 9.625L59 7.11111L53.5049 4.59723L50.5 0L47.4951 4.59723L42 7.11111Z" fill="#E6F5F6"></path></svg></div>
                                            <div class="MuiBox-root css-0" bis_skin_checked="1">
<img src={rentyCover} style={{width:'30px',height:'30px'}}/>
                                            </div>
                                            <div class="MuiBox-root css-n7zsof" style={{ width: "80%" }} bis_skin_checked="1" >
                                                <p class="MuiTypography-root MuiTypography-body1 css-1e2tu47" style={{color:"white"}}>RENTY COVER  </p>

                                                <p class="MuiTypography-root MuiTypography-body1 css-10f5ik5" style={{color:"white"}}>Insure your furniture</p>
                                            </div>
                                            <div class="MuiBox-root css-0" bis_skin_checked="1">
                                                <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="32" height="32" rx="16" fill="#fff"></rect><path d="M11.333 16h9.334M16 11.334 20.667 16 16 20.667" stroke="#222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg></div></div></div>
                                </div>
                 
                       
                    </>
                ) : (
                    <>
                        <div onClick={() => handleOpen('right')}>
                            Condition : As good as new    <span style={{ color: "blue" }}>:-  Know More </span>
                        </div>
                    </>
                )
            }
            <Drawer placement={placement} open={open} onClose={() => setOpen(false)}>
                <Drawer.Header>
                    <Drawer.Title style={{fontWeight:'700',textAlign:'center'}}>Renty Furniture</Drawer.Title>
                    <Drawer.Actions>
                        <Button onClick={() => setOpen(false)}>Cancel</Button>

                    </Drawer.Actions>
                </Drawer.Header>
                <Drawer.Body>

                    <div class="MuiBox-root css-1wj33lv" bis_skin_checked="1">
                        <div class="MuiBox-root css-1xxmc96" style={{ width: "80%" }} bis_skin_checked="1">
                            <div class="MuiBox-root css-1wf4a85" bis_skin_checked="1">
                                <div class="MuiBox-root css-1ggxnpa" bis_skin_checked="1">
                                    <svg width="59" height="64" viewBox="0 0 59 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 14.2222L5.17188 17.0952L8 22.3492L10.8281 17.0952L16 14.2222L10.8281 11.3492L8 6.09521L5.17188 11.3492L0 14.2222Z" fill="#E6F5F6"></path><path d="M8 56.8889L13.1719 59.4028L16 64.0001L18.8281 59.4028L24 56.8889L18.8281 54.3751L16 49.7778L13.1719 54.3751L8 56.8889Z" fill="#E6F5F6"></path><path d="M42 7.11111L47.4951 9.625L50.5 14.2222L53.5049 9.625L59 7.11111L53.5049 4.59723L50.5 0L47.4951 4.59723L42 7.11111Z" fill="#E6F5F6"></path></svg></div>
                                <div class="MuiBox-root css-0" bis_skin_checked="1">
<img src={rentyCover
} style={{width:'30px',height:'30px'}}/>
                                   </div>
                                <div class="MuiBox-root css-n7zsof" style={{ width: "80%" }} bis_skin_checked="1" >
                                    <p class="MuiTypography-root MuiTypography-body1 css-1e2tu47" style={{ color: 'white' }}>RENTY COVER  </p>

                                    <p class="MuiTypography-root MuiTypography-body1 css-10f5ik5" style={{ color: 'white' }}>Insure your furniture</p>
                                </div>
                                <div class="MuiBox-root css-0" bis_skin_checked="1">
                               </div></div></div>
                    </div>
                    <div class="terms-conditions">
                        <h2 style={{ display: 'flex', justifyContent: 'center32' }}>Terms and Conditions for Renty Cover</h2>
                      
                        <h5>Cover</h5>
                        <ul>
                            <li>You will be eligible for damage waiver up to Rs 80,000/-</li>
                            <li>Damage due to normal wear and tear</li>
                            <li>Scratches and dents on the product(s)</li>
                            <li>Cracks and tears to the product(s)</li>
                            <li>Bugs and fungus damaging the product(s)</li>
                            <li>Damage waiver is applicable till the end of your current tenure</li>
                        </ul>
                        <h5>Not Covered</h5>
                        <ul>
                            <li>Damage voluntarily caused or worsened with sharp objects, hand or power tools, cigarette butts, or other such abuse.</li>
                            <li>Burglary or theft of the product(s)</li>
                        </ul>



                    </div>
                </Drawer.Body>
            </Drawer>
        </div>
    );
};

export default RentyCover