import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
//Redux
import { isAndroid, isIOS } from 'react-device-detect';

import { connect, useSelector } from "react-redux";
import flagpng from './flag.png'
import logo from './logo.png'
//API Methods
import { sendLoginOtp } from "../../actions/auth/login"
import { fetchCustomerByNumber } from "../../actions/customer/getCustomerByNumber"
import "../../style/css/login.css"
import axios from "axios";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { UpdateCartItemsBybrowserIdCustomerIdAction } from '../../actions/products/cart/updateCartItemBybandCId';
import { useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { ArrowBack } from '@mui/icons-material';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
function Login(props) {
useEffect(()=>{
localStorage.setItem('cityName','noida')
},[])
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const backgroundStyle = {
        backgroundImage: `url('path-to-your-background-image.jpg')`, // Replace 'path-to-your-background-image.jpg' with the actual path to your image file
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    };
    const userData = useSelector((state) => state?.getCustomerByNumber?.data)
    const loaded = useSelector((state) => state?.getCustomerByNumber?.loading)

    console.log(userData, 'userDatattatata')

    const location = useLocation();

    useEffect(()=>{
  if(navigate){
    localStorage.removeItem('Otp')
    localStorage.removeItem('messageOtp')
  }
   },[navigate])


    const {
        handleSendLoginOtp,
        message,
        error,
        handleFetchCustomerByNumber,
        customerMessage,
        customerError,
        loading
    } = props
    const [number, setNumber] = useState("")
    const [yesOtp, setYesOtp] = useState(false)
    useEffect(() => {

        const otp = localStorage.getItem('otp')
        if (otp) {
            setYesOtp(true)

        }
    }, [])
    const [otpverify, setOtpVerify] = useState(false);
    const [phone, setPhone] = useState("");
    const [hasFilled, setHasFilled] = useState(false);
    const [otp, setOtp] = useState("");
    const [errorValid,setValidEror]=useState()
    const handleLogin = async () => {
        localStorage.setItem('numberme',number)

         // Check if the number is exactly 10 digits long
    if (number.length !== 10) {
       return setValidEror('Your Number must be exactly 10 digits');
    }
        if (localStorage.getItem("message") === "CustomerNotExist") {
            localStorage.removeItem("message");
            await Swal.fire({
                title: 'Customer Not Exist',
                text: 'Please sign up your account to continue',
                icon: 'error',
            });

            navigate("/registration");
        } else {

            await handleFetchCustomerByNumber(number);
        }
    };

    const scjnkcs = localStorage.getItem('message')


    const OtpData = localStorage.getItem("Otp")
    //Handle Check Customer Is exit or not
    useEffect(() => {

        if (localStorage.getItem("message") == "CustomerExist") {
            localStorage.removeItem("message");
            handleSendLoginOtp(number)
        }
        if (localStorage.getItem("message") === "CustomerNotExist") {
            localStorage.removeItem("message");
            Swal.fire({
                title: 'Customer does not exist',
                text: 'Please create your account',
                icon: 'error',
            });
            navigate("/registration")
        }
    }, [customerMessage, scjnkcs])

    const messageOtp = localStorage.getItem('messageOtp')

    useEffect(() => {
        if (messageOtp === 'OtpSentSuccessfully') {
            navigate('/otp');
        }
    
        // Cleanup function (optional in this scenario)
        return () => {
            localStorage.removeItem('messageOtp')
            // Perform any cleanup if needed
            // For example: Cancel timeouts, abort API calls, etc.
        };
    }, [messageOtp]); // Ensure `navigate` is included in the dependency array
    

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          handleLogin();
        }
      };




    return (
        <>
            <style>
                {
                    `
                    .button{
                        display:none;
                    
                    }
                        @media(max-width:767px){
                        .bobobokobk{
                        display:none;
                        }
                        .mobileheader{
                        display:none;
                        }
                         .button{
                        display:block;
                    
                    }
                        }
                        `
                }
            </style>
            {
                isIOS&&(
                    <>
                      <div className='button' style={{background:'rgb(245, 99, 88)',width:'41px',padding:'9px',margin:'10px',borderRadius:'100%'}}>
                <Link to='/' style={{color:'white'}}>
            <ArrowBack color='white'></ArrowBack>
            </Link>
            </div>
                    </>
                )
            }
          
            <div className='body_color'>
                <div class="box-form" style={{ marginTop: "70px" }}>
                    <div class="left" style={{width:'80%'}}>
                        <div class="overlay" >
                            <p>
                            </p>
                            <span>
                            </span>
                        </div>
                    </div>
                    <style>
                        {
                            `
                       
                            `
                        }
                    </style>
                    <div className="right">
                        <h5 style={{ boxShadow: "", fontWeight: 1000, marginTop: "" ,fontSize:'22px',lineHeight:'28px',marginBottom:'0px'}}>Welcome to   Renty Furniture
                        </h5>
                        <br />
                        <div className="inputs">
                            <div className="input-with-icon">
                                <img src={flagpng} alt="Flag" className="icon" />
                                <input
                                onKeyPress={handleKeyDown}
                                    style={{ border: '1px solid grey', paddingLeft: '40px' }} // Added padding-left to make space for the icon
                                    type="text"
                                    placeholder="Enter Your Number"
                                    value={number}
                                    onChange={(e) => setNumber(e.target.value)}
                                />

                            </div>
                            <span style={{color:'red',marginTop:'4px',fontSize:'15px'}}>{errorValid}</span>
                            
                            <br />
                        </div>
                        <div className="remember-me--forget-password"></div>
                        <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap', justifyContent: '', marginTop: '' }}>
                            <button className="button" onClick={handleLogin} disabled={loading} style={{ margin: 'auto' }}>
                               
                                        Send OTP
                                    
                                </button>
                            <p style={{marginTop:'15px'}}>Don't have an account? <Link to="/registration">Create Your Account</Link></p>
                        </div>
                    </div>
                    <style>
                        {
                            `
        .input-with-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.input-with-icon .icon {
      position: absolute;
    left: 5px;
    width: 29px;
    height: 17px;
    pointer-events: none;
    top: 20px;
}
}

.input-with-icon input {
  padding-left: 40px; /* Make space for the icon */
}
  .bobobokobk{
                        display:none;
                        }

        `
                        }
                    </style>

                </div>
            </div>
        </>

    );
}
const mapStateToProps = (state, ownProps) => {
    return {
        fetchAllCityData: state.getAllCity.data,
        message: state.sendOtpSms.message,
        loading: state.sendOtpSms.loading,

        error: state.sendOtpSms.error,
        customerMessage: state.getCustomerByNumber.message,
        customerError: state.getCustomerByNumber.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleSendLoginOtp: (number) => dispatch(sendLoginOtp(number)),
        handleFetchCustomerByNumber: (number) => dispatch(fetchCustomerByNumber(number))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
