import React, { useEffect, useState } from 'react'
//API Methods
import { fetchProductById } from "../../actions/products/getProductById"
import { addWishListProduct } from "../../actions/products/wishList/addCustomerWishList"
import { fetchGetWishlist } from "../../actions/products/wishList/getWishList"
import { addToCartProduct } from "../../actions/products/cart/addToCartProduct"
import { Pagination, Navigation, Virtual, Autoplay, Keyboard, HashNavigation, Thumbs } from 'swiper/modules';
import BasicDemo from './coupenCompon'
//Redux
import Carousel from 'react-bootstrap/Carousel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { connect } from "react-redux";
import Slider from '@mui/material/Slider';
//Utils And Links and Images
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Logo from "../../style/images/logo.png"
import { BiCurrentLocation } from "react-icons/bi";
import { BiLike } from "react-icons/bi";
import { LiaSearchSolid } from "react-icons/lia";
import "../../style/css/productDetail.scss"
// Import Swiper React components
import { Swiper, SwiperSlide, } from 'swiper/react';
// import required modules
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import DOMPurify from 'dompurify';
import { getRelatableproducts } from '../../actions/products/getRelatbaleProducts'
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { fetchProductByDisCountId } from '../../actions/products/getProductBydisCount'
import { fetchCartProducts } from '../../actions/products/cart/getCartProducts'
import { useDispatch, useSelector } from 'react-redux'
import { SetDuraion } from '../../actions/products/setDuration'
import { GETALLDISCOUNTCOUPEN } from '../../actions/products/getAlldiscountAction'
import Pincode from '../header/pincode'
import Relatableproduct from '../homepage/relatableproduct'
import { Close } from '@mui/icons-material'
import CardComponent from './card'
import { ButtonToolbar, IconButton, Placeholder } from 'rsuite';
import { Drawer as RsuiteDrawer } from 'rsuite';
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';
import AngleLeftIcon from '@rsuite/icons/legacy/AngleLeft';
import AngleDownIcon from '@rsuite/icons/legacy/AngleDown';
import AngleUpIcon from '@rsuite/icons/legacy/AngleUp';

import 'rsuite/dist/rsuite.min.css';
import { ThumbDown } from '@mui/icons-material';


function KroductImage(props) {
    const dispatch = useDispatch()
    const relatableProducts = useSelector((state) => state?.GetRelatableProductsReducer?.data)
    const relatableProductsloading = useSelector((state) => state?.GetRelatableProductsReducer?.loading)

    const { cartitemList } = useSelector((state) => state?.getCartProducts?.data)
    const storeduration = useSelector((state) => state?.Durationreducer?.duration)
    const updatedPriceR = useSelector((state) => state?.Durationreducer?.updatedPrice)
    const loadings = useSelector((state) => state?.addToCartProduct?.loading)

    const [browserUniqueId, setBrowserUniqueId] = useState('');

    // Function to generate a browser-specific unique ID
    const generateBrowserUniqueId = () => {
        const userAgent = navigator.userAgent;
        const platform = navigator.platform;
        const language = navigator.language;
        const pluginsLength = navigator.plugins.length;

        // Concatenate browser information
        const browserInfo = userAgent + platform + language + pluginsLength;

        // Use a hashing function to generate a unique ID
        const hashCode = s => {
            let h;
            for (let i = 0; i < s.length; i++)
                h = Math.imul(31, h) + s.charCodeAt(i) | 0;
            return h;
        };

        // Set the browser unique ID
        setBrowserUniqueId(hashCode(browserInfo).toString());
    };

    // Generate the unique ID when the component mounts
    useState(() => {
        generateBrowserUniqueId();
    }, []);
    console.log(browserUniqueId, 'browserUniqueIdbrowserUniqueId')

    console.log(loadings, 'loadingsloadings')
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);


    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
    };


    const calculateAdjustedTenure = (selectedTenure) => {
        const lowercaseLabel = selectedTenure.toLowerCase();
        switch (lowercaseLabel) {
            case 'permonth':
                return 1;
            case 'threemonth':
                return 3;
            case 'sixmonth':
                return 6;
            case 'ninemonth':
                return 9;
            case 'twelvemonth':
                return 12;
            case 'eighteenmonth':
                return 18;
            default:
                return 1; // Default to 1 if the selected tenure is not recognized
        }
    };



    const [isLiked, setIsLiked] = useState(false);
    const navigate = useNavigate()

    const { handleFetchProductById,
        getProductByIdData,
        loading,
        error,
        message,
        handleAddWishListProduct,
        handleFetchGetWishlistByCustomerId,
        getWishListData,
        handleAddToCartProduct,
        addToCartMessage,
        addToCartError
    } = props

    const { id } = useParams();



    const isMobile = window.innerWidth <= 768;
    const [selectedTenure, setSelectedTenure] = useState('twelvemonth'); // Initial value, you can change it based on your requirement
    console.log(selectedTenure, 'selectedTenureselectedTenure')
    const [tenureOptions, setTenureOptions] = useState([]);
    const [updatedPrice, setUpdatedPrice] = useState()


    const [tenuremap, setTenureEstimate] = useState([])
    const [myNewLable, setLabel] = useState('')



    useEffect(() => {
        dispatch(SetDuraion(selectedTenure, updatedPrice))
    }, [selectedTenure, storeduration, updatedPrice])

    const cityId = localStorage.getItem('cityId')


    // useEffect(() => {
    //   // Check if subCategoryId is available and hasn't called the API yet
    //   if (getProductByIdData?.subCategoryId) {
  
    //     // Only call the API if subCategoryId exists and hasn't been called
    //     dispatch(getRelatableproducts(cityId, getProductByIdData?.subCategoryId));
  
    //   }
  
    // }, [ getProductByIdData, dispatch,id,dispatch]); // Dependencies
  
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        handleFetchProductById(id)

        // handleFetchGetWishlistByCustomerId()
    }, [id])

    useEffect(() => {
        // Filter keys to include only those representing months
        const availableTenures = Object.keys(getProductByIdData).filter(
            (key) => key.toLowerCase().includes('month')
        );
        const availableTenuresOb = Object.keys(getProductByIdData)
            .filter(key => key.toLowerCase().includes('month'))
            .map(key => ({
                key: key,
                value: getProductByIdData[key]
            }));

        const discounts = [];

        for (let i = 0; i < availableTenuresOb.length; i++) {
            const currentTenure = availableTenuresOb[i];

            if (currentTenure.value !== null) {
                const discountPercentage = ((getProductByIdData.perMonth - currentTenure.value) / getProductByIdData.perMonth) * 100;
                discounts.push({ label: currentTenure.key, discountPercentage: discountPercentage.toFixed(2) });
            }
        }

        // Create options based on available tenures
        const options = availableTenures.map((tenureKey, index) => {
            const value = getProductByIdData[tenureKey];

            // Get the corresponding discount percentage
            const discountObj = discounts.find(discount => discount.label === tenureKey);
            const discountPercentage = discountObj ? discountObj.discountPercentage : null;

            // Create an array of multipliers from 3 to 12
            const multipliers = Array.from({ length: 10 }, (_, index) => index + 3);

            // Calculate the estimated values for each multiplier

            return {
                value,
                label: `${tenureKey.charAt(0).toUpperCase()}${tenureKey.slice(1)}`,
                discountPercentage // Include the discount percentage in the options
            };
        });
        console.log(options, 'availableTenuresavailableTenures');

        setTenureOptions(options);
        // setSelectedTenure(options[0]?.value || ''); // Set the first tenure as the default selected value
    }, [getProductByIdData]);
    const handlcikclose = () => {
        setState({ ...state, right: false, bottom: false, left: false });

    }

    const handleTenureChange = (selectedLabel, discount) => {
        localStorage.setItem('discount', discount)
        setState({ ...state, right: false, bottom: false, left: false });
        console.log(selectedLabel, 'lololo')
        setSelectedTenure(selectedLabel);

        // Get the corresponding value from the object
        const selectedLabelLower = selectedLabel.toLowerCase();

        // Check if the key exists in the object in a case-insensitive manner
        const selectedKey = Object.keys(getProductByIdData).find(
            (key) => key.toLowerCase() === selectedLabelLower
        );

        if (selectedKey) {
            // Get the corresponding value from the object
            const selectedValue = getProductByIdData[selectedKey];
            setUpdatedPrice(selectedValue)
        } else {
            console.error(`Key "${selectedLabel}" does not exist in getProductByIdData`);
        }
        // console.log(selectedValue,'selectedLabelselectedLabelValue')
        console.log(selectedTenure, 'selectedTenureselectedTenureselectedTenureselectedTenure');

    };

    const customerId = localStorage.getItem('customerId');

    useEffect(() => {
        console.log('Selected Tenure:', selectedTenure);
        // Check if customerId exists in localStorage
        const browserIdToSet = customerId ? "" : browserUniqueId;
        setAddToCardData(prevData => ({
            ...prevData,
            "duration": storeduration || calculateAdjustedTenure(selectedTenure) || 12,
            "productId": id,
            "cartValue": updatedPriceR || getProductByIdData?.price,
            "browserId": browserIdToSet
        }));
    }, [storeduration, id, updatedPriceR, selectedTenure, customerId, browserUniqueId]);
    //Product Wishlist 
    const userId = 1;
    useEffect(() => {
        // Check if the product is present in the wishlist for the given user
        const isProductLiked = getWishListData && getWishListData?.getWishListData?.some(wishlistItem =>
            wishlistItem.passProduct.productId === getProductByIdData.productId &&
            wishlistItem.customer.customerId === userId
        );
        setIsLiked(isProductLiked);
    }, [getWishListData, userId]);

    const handleLikeToggle = () => {
        const customer = localStorage.getItem('customerId')
        console.log(customer, 'customercustomercustomercustomercustomer')
        if (!customer) {
            navigate('/login')
        }
        setIsLiked((prevIsLiked) => !prevIsLiked);
        handleAddWishListProduct(id)
    };

    //Add To Cart 


    //handle route to cart page when successfully added in the card-item

    useEffect(() => {
        if (localStorage.getItem("message") === "addToCartProductsuccuss") {
            localStorage.removeItem("message");
            navigate("/cart");
        }
        if (localStorage.getItem("message") === "addToCartProductFailed") {

            localStorage.removeItem("message");
        }
    }, [addToCartMessage, addToCartError]);

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };
    const [selectedTab, setSelectedTab] = useState('Tenure');

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };
    console.log(getProductByIdData, 'getProductByIdDatagetProductByIdData')
    const [selectedValue, setSelectedValue] = useState(tenureOptions && tenureOptions[0]?.value);
    const [duration, setStateEstimatedValue] = useState(0)
    const [FinaldurationValue, setStateFinaldurationValue] = useState("")
    const [discountcomparer, comparediscount] = useState("")


    const handleSliderChange = (event, value) => {
        setSelectedValue(value)
        const discountPercentage = ((getProductByIdData.perMonth - value) / getProductByIdData.perMonth) * 100;
        const discount = discountPercentage.toFixed(2)
        comparediscount(discount)


    };
    useEffect(() => {
        // Check if selectedValue is null or undefined, consider it as 0
        const effectiveSelectedValue = selectedValue === null || selectedValue === undefined ? 0 : selectedValue;
        // Check if duration is NaN, consider it as 0
        const effectiveDuration = isNaN(duration) ? 0 : duration;

        console.log(`${effectiveSelectedValue} ---- ${effectiveDuration}   newvalue`);

        const exactEstimatedValue = effectiveSelectedValue * effectiveDuration;
        console.log(exactEstimatedValue, 'exactEstimatedValue');

        setStateFinaldurationValue(exactEstimatedValue);
    }, [duration, selectedValue]);



    const handleTenureCompareChange = (data) => {
        setState({ ...state, right: false, bottom: false });
        setUpdatedPrice(selectedValue);
        setSelectedTenure(myNewLable)

    };
    const calculateAdjustedPrice = (value, label) => {
        const lowercaseLabel = label.toLowerCase();
        switch (lowercaseLabel) {
            case 'perMonth':
                return value * 1;
            case 'threemonth':
                return value * 3;
            case 'sixmonth':
                return value * 6;
            case 'ninemonth':
                return value * 9;
            case 'twelvemonth':
                return value * 12;
            case 'eighteenMont':
                return value * 18;
            default:
                return value;
        }
    };
    const calculateAdjustedMonth = (label) => {
        const lowercaseLabel = label.toLowerCase();
        switch (lowercaseLabel) {
            case 'perMonth':
                return 1;
            case 'threemonth':
                return 3;
            case 'sixmonth':
                return 6;
            case 'ninemonth':
                return 9;
            case 'twelvemonth':
                return 12;
            case 'eighteenMont':
                return 18;
            default:
                return "";
        }
    };
    const [addToCardData, setAddToCardData] = useState({
        "duration": "",
        "productId": id,
        "cartValue": ""
    });



    const calculateDiscountedPrice = (originalPrice, discountPercentage) => {

        const parsedOriginalPrice = parseFloat(originalPrice);
        const parsedDiscountPercentage = parseFloat(discountPercentage);

        if (!isNaN(parsedOriginalPrice) && !isNaN(parsedDiscountPercentage)) {
            const discountAmount = (parsedDiscountPercentage / 100) * parsedOriginalPrice;
            const discountedPrice = parsedOriginalPrice - discountAmount;

            return discountedPrice.toFixed(2);
        } else {
            // Handle invalid input values
            return '';
        }
    };
    // useEffect(()=>{
    //     setAddToCardData(prevState => ({
    //         ...prevState,
    //         cartValue: updatedPriceR
    //       }));
    // },[updatedPrice])

    // useEffect(()=>{
    const discount = calculateDiscountedPrice(updatedPrice, getProductByIdData?.discounts?.discountPercentage || 0);
    const [selectedIndex, setSelectedIndex] = useState(null);
    console.log(selectedIndex, 'selectedIndexselectedIndex')
    const isLogin = localStorage.getItem('customerId');



    const handleAddToCart = () => {
        const isProductInCart = Array.isArray(cartitemList) && cartitemList?.some(item => item.passProduct?.productId === getProductByIdData?.productId);
        if (isProductInCart) {
            alert('This product is already in your cart!');
            return;
        }
        console.log(addToCardData, 'addToCardDataaddToCardData')
        handleAddToCartProduct(addToCardData);
    };
    const [futureDate, setFutureDate] = useState();

    useEffect(() => {
        if (getProductByIdData?.availabeDay !== undefined) {
            const currentDate = new Date();
            console.log(getProductByIdData?.availabeDay, 'getProductByIdData?.availabeDay')
            const futureDate = new Date(currentDate.getTime() + (getProductByIdData?.availabeDay * 24 * 60 * 60 * 1000));
            const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
            var forDate = futureDate.toLocaleDateString('en-GB', options);
            console.log(forDate, 'currentDatecurrentDate')
            setFutureDate(forDate);
        }
    }, [getProductByIdData?.availabeDay]);

    const coupendatata = useSelector((state) => state?.GetAlldiscountCopenReducer?.data)



    useEffect(() => {
        dispatch(GETALLDISCOUNTCOUPEN())
    }, [dispatch])





    const maxTenureValue = Math.max(...tenureOptions.map(item => item.value));
    console.log(tenureOptions, 'tenureOptionstenureOptions')
    // Initialize state with the updated date

    //toggle
    const list = (anchor) => (
        <Box role="presentation" className="muwoddtdtd" style={{ maxWidth: '780px', minWidth: '600px' }}>
            <List className='flex' style={{ display: 'flex' }}>
                {['Tenure', 'Comparison', 'Explore'].map((text) => (
                    <ListItem key={text} disablePadding >
                        <ListItemButton style={{ borderBottom: selectedTab === text ? '2px solid rgb(245, 99, 88)' : '2px solid transparent' }}
                            onClick={() => handleTabClick(text)}>
                            <ListItemIcon style={{ fontWeight: '700', fontSize: '10px' }}>

                            </ListItemIcon>
                            <ListItemText

                                primary={text}
                                style={{ fontWeight: text === 'Tenure' ? '700' : 'normal', fontStyle: text === 'Compare Tenure' ? 'normal' : '', color: "grey", textAlign: 'center' }}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <div>
                {selectedTab === 'Tenure' && <div style={{ boxShadow: 'box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);' }}>
                    {tenureOptions.filter(data => data.value !== null && data.value !== '').map((data, index) => (
                        <div key={data.label} className="product-card p-3">
                            <div className="product-details" style={{ padding: 'unset' }}>
                                <div className='' style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <div> <h5 style={{ position: 'relative', right: '20px', fontFamily: 'Recline' }}>{calculateAdjustedMonth(data.label)} Month</h5></div>
                                    <div className="product-price">
                                        <div className="" style={{ display: 'flex', gap: '5px' }}>
                                            <div className="discount"><h6 style={{ color: 'black', position: 'relative', top: '3px', fontSize: '10px' }}>{data && data?.discountPercentage}</h6></div>
                                            ₹{data && data?.value}/Month</div>
                                    </div>
                                </div>

                                <div class="MuiBox-root css-1vj3iup" style={{ padding: "0px" }}> <p style={{ margin: '0px', width: "40%", padding: '6px', borderRadius: "0px 6px 6px 6px", background: '#ADD8E6', fontSize: "12px", color: 'black' }} class="MuiTypography-root MuiTypography-Small_SemiBold css-81x7e" >Tenure Discount : -{data && data?.discountPercentage}%</p></div>
                                <div className='dflex' style={{ display: 'flex', justifyContent: 'space-between' }}>

                                    <p style={{ fontSize: '13px', position: 'relative', top: '10px', color: '' }}>Minimum Tenure: {calculateAdjustedMonth(data.label)} Month (Payable)</p>




                                    <button
                                        onClick={() => {
                                            handleTenureChange(data.label, data?.discountPercentage);
                                            setSelectedIndex(index);
                                        }}
                                        style={{ padding: '10px', backgroundColor: selectedIndex === index ? '' : '', borderRadius: '18px' }}
                                    >
                                        {selectedIndex === index ? 'Selected' : 'Select Tenure'}
                                    </button>
                                </div>
                                <div className="product-price" style={{ position: 'relative', right: '1px', color: '' }}>₹{calculateAdjustedPrice(data.value, data.label)}/Total</div>

                            </div>
                        </div>

                    ))}



                </div>}
                {selectedTab === 'Comparison' &&

                    <Box className="compare" >
                        <div style={{ margin: '32px' }}>
                            <Slider
                                aria-label="Custom marks"
                                defaultValue={tenureOptions[0].value}
                                getAriaValueText={getAriaValueText}
                                step={null}
                                valueLabelDisplay="auto"
                                onChange={handleSliderChange}
                                max={maxTenureValue}
                                min={0}
                                marks={tenureOptions.map(item => ({ value: item.value }))}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                <div>
                                    <h6 variant="body2" style={{ textTransform: 'uppercase', background: '', padding: '8px', color: 'black', fontFamily: 'Recline', fontWeight: '600' }}>{calculateAdjustedMonth(selectedTenure)} Months</h6>
                                </div>
                                <div style={{ display: "flex", gap: "5px" }}>
                                    <div className=""><h6 style={{ color: 'black', position: 'relative', background: '#ADD8E6', padding: '5px', borderRadius: '22px', fontSize: '12px' }}>{discountcomparer} {discountcomparer ? '%' : "%"}</h6></div>

                                    <h6 variant="body2">₹{selectedValue}/Month</h6>
                                </div>
                                {/* <div> <h5 style={{ position: 'relative', right: '40px' }}>{discountcomparer}</h5></div> */}




                            </div>

                            <div class="MuiBox-root css-1vj3iup" style={{ padding: "0px" }}> <p style={{ margin: '0px', width: "40%", padding: '16px', borderRadius: "6px", background: '#ADD8E6', fontSize: "12px" }} class="MuiTypography-root MuiTypography-Small_SemiBold css-81x7e" > Discount : -{discountcomparer}%</p></div>



                            <p style={{ color: 'rgb(251, 183, 44)', fontSize: '15px', marginTop: '13px', fontWeight: '600' }}>
                                Minimum Tenure: {calculateAdjustedMonth(selectedTenure)} Month (Payable Upfront)
                            </p>
                        </div>

                        <div className='' style={{ display: 'flex', justifyContent: 'end' }}>
                            <button onClick={() => handleTenureCompareChange(getProductByIdData)} style={{ background: 'rgb(245, 99, 88)', padding: '10px', marginLeft: '38px', fontWeight: '600', border: '1px solid black', borderRadius: "18px", color: "white" }}>Select Tenure</button>
                        </div>

                    </Box>
                }
                {selectedTab === 'Explore' &&

                    <div className='knowmore' >
                        <h1>How it works</h1>
                        <div className=''>
                            <h6>Select a tenure of your choice</h6>
                            <p style={{ color: "grey", fontWeight: '400' }}>Pay upfront for 1, 6, or 12-months and get huge discounts on your rentals.</p>
                        </div>
                        <div className=''>
                            <h6>Select a tenure of your choice</h6>
                            <p style={{ color: "grey", fontWeight: '400' }}>Pay upfront for 1, 6, or 12-months and get huge discounts on your rentals.</p>
                        </div>
                        <div className=''>
                            <h6>Select a tenure of your choice</h6>
                            <p style={{ color: "grey", fontWeight: '400' }}>Pay upfront for 1, 6, or 12-months and get huge discounts on your rentals.</p>
                        </div>
                    </div>


                }
            </div>
        </Box>
    );


    const estimatedvalue = (month) => {
        setLabel(month)
        switch (month) {
            case "threemonth":
                return 3;
            case "sixmonth":
                return 6;
            case "ninemonth":
                return 9;
            case "twelvemonth":
                return 12;
            case "eighteenMonth":
                return 18;
            case "permonth":
                return 1;
            default:
                return "";
        }
    }
    const p = () => {
        if (!tenureOptions || tenureOptions.length === 0) {
            return []; // or handle the case where tenureOptions is empty
        }
        const tenureLabels = tenureOptions.map(option => option.label.toLowerCase());
        console.log(tenureLabels, 'tenureLabelstenureLabels')
        const someFactor = tenureOptions.map(option => parseFloat(option.value));
        // Use map to get an array of durations
        const durations = tenureLabels.map(label => estimatedvalue(label));

        setTenureEstimate(durations.map((duration, index) => {
            // Check if the factor is a valid number before performing multiplication
            const factor = !isNaN(someFactor[index]) ? someFactor[index] : 1;
            const estimate = duration * factor;

            return estimate;
        }));


        console.log(durations, 'durations');
        return durations;
    };

    useEffect(() => {
        console.log(tenureOptions, 'tenureOptions');
        if (tenureOptions && tenureOptions.length > 0) {
            p();
        }
    }, [tenureOptions]);


    const getAriaValueText = (value) => {
        console.log(value, 'valuevaluevaluevalue')
        setUpdatedPrice(value)
        const selectedLabel = tenureOptions.find(option => option.value === value)?.label?.toLowerCase();
        setSelectedTenure(selectedLabel)
        // Pass the lowercase label to the estimatedValue function
        const duration = estimatedvalue(selectedLabel);
        console.log(duration, 'durationdurationdurationduration', selectedLabel)
        setStateEstimatedValue(duration)
        return `${value} - ${selectedLabel}`;
    };

    const handleSelect = (selectedIndex) => {
        setSelectedImageIndex(selectedIndex);
    };


    // },[[getProductByIdData?.discounts?.discountPercentage,updatedPrice]])
    function convertHtmlToPlainText(html) {
        // Sanitize the HTML content
        const sanitizedHtml = DOMPurify.sanitize(html);

        // Parse the sanitized HTML content using DOMParser
        const doc = new DOMParser().parseFromString(sanitizedHtml, 'text/html');

        // Return the text content of the body
        return doc.body.textContent || "";
    }

    // Example usage:
    const htmlString = getProductByIdData?.productSpecification; // Assuming getProductByIdData?.productSpecification contains HTML
    const plainText = convertHtmlToPlainText(htmlString);

    useEffect(() => {
        // Check if getProductByIdData has valid data and price is available
        if (getProductByIdData && getProductByIdData.perMonth) {
            setUpdatedPrice(getProductByIdData.twelveMonth);
        } else {
            // Handle invalid or missing price here, for example, set it to a default value
            setUpdatedPrice(0);
        }
    }, [getProductByIdData]);


    const handleProductDetail = (item) => {
        // console.log("item ==-=->", item)
        navigate(`/product-details/${item.productId}`)
    }

    const mydata = (days) => {
        const currentDate = new Date();
        const futureDate = new Date(currentDate.getTime() + (days * 24 * 60 * 60 * 1000));
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        var forDate = futureDate.toLocaleDateString('en-GB', options);
        return forDate
    }
    const calculateDiscouunt = (eighteenMonth, perMonth) => {
        // Calculate the total price for 18 months without discount
        const discountPercentage = ((perMonth - eighteenMonth) / perMonth) * 100;
        // Return the discount percentage for 18 months
        return discountPercentage.toFixed(2);

    }

    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [placement, setPlacement] = React.useState();
    const [placement2, setPlacement2] = React.useState();

    const [open5, setOpen5] = React.useState(false);
    const [placement5, setPlacement5] = React.useState();

    const handleOpen = key => {
        setOpen(true);
        setPlacement(key);
    };
    const handleOpen2 = key => {
       
        setOpen2(true);
        setPlacement2(key);
    };

    const handleOpen5 = key => {
       
        setOpen5(true);
        setPlacement5(key);
    };
    const [open3, setOpen3] = React.useState(false);
    const [placement3, setPlacement3] = React.useState();


    const handleOpen3 = key => {
        setOpen3(true);
        setPlacement3(key);
    };


    return (
        <>
        
        
        <RsuiteDrawer placement="bottom" open={open5} onClose={() => setOpen5(false)}>
  <RsuiteDrawer.Header>
    <RsuiteDrawer.Actions>
      <Button onClick={() => setOpen5(false)}>Cancel</Button>
    </RsuiteDrawer.Actions>
  </RsuiteDrawer.Header>
    <RsuiteDrawer.Title style={{ fontWeight: '700',display:'flex',justifyContent:'center',textAlign:'center'  }}>Renty Furniture</RsuiteDrawer.Title>

  <RsuiteDrawer.Body style={{ padding: "40px" }}>
    <h6 className="" style={{ fontFamily: "Recline",display:'flex',justifyContent:'center',textAlign:'center',marginTop:'10px' }}>
      {getProductByIdData?.productTitle}
    </h6>
    <div className="newBoox">
      <div>
        <Carousel activeIndex={selectedImageIndex} onSelect={handleSelect}>
          {getProductByIdData &&
            getProductByIdData.productImagesPath &&
            getProductByIdData.productImagesPath.map((imagePath, index) => (
              <Carousel.Item key={index} style={{ height: '275px' }}>
                <div>
                  <img
                    onClick={() => handleOpen5('bottom')}
                    className="d-block img-fluid"
                    src={imagePath}
                    alt={`Slide ${index + 1}`}
                    style={{ width: '100%', height: '275px', objectFit: 'contain'}}
                  />
                </div>
              </Carousel.Item>
            ))}
        </Carousel>
        <div className="imagesbox">
          {getProductByIdData &&
            getProductByIdData.productImagesPath &&
            getProductByIdData.productImagesPath.map((imagePath, index) => (
              <div className={`boxImages ${selectedImageIndex === index ? 'selected' : ''}`} key={index} onClick={() => handleImageClick(index)}>
                <img src={imagePath} style={{ minHeight: '82px', maxHeight: '82px' }} alt={`Product Image ${index + 1}`} />
              </div>
            ))}
        </div>
      </div>
    </div>
  </RsuiteDrawer.Body>
</RsuiteDrawer>

<div className="MuiBox-root css-0 mobileheader">
  <div className="MuiGrid-root css-5sb7mj">
    <div className="newBoox">
      <div>
      
        <Carousel activeIndex={selectedImageIndex} onSelect={handleSelect} style={{backGround:'rgb(248, 248, 248)'}}>
          {getProductByIdData &&
            getProductByIdData.productImagesPath &&
            getProductByIdData.productImagesPath.map((imagePath, index) => (
              <Carousel.Item key={index} style={{ height: '275px' }} onClick={() => handleOpen5('bottom')}>
                <div style={{backgroundColor:'rgb(248, 248, 248)'}}>
                  <img className="d-block img-fluid" src={imagePath} alt={`Slide ${index + 1}`} style={{ width: '100%', height: '250px', objectFit: 'contain' }} />
                </div>
              </Carousel.Item>
            ))}
        </Carousel>
        <div className="imagesbox">
          {getProductByIdData &&
            getProductByIdData.productImagesPath &&
            getProductByIdData.productImagesPath.map((imagePath, index) => (
              <div className={`boxImages ${selectedImageIndex === index ? 'selected' : ''}`} key={index} onClick={() => handleImageClick(index)}>
                <img src={imagePath} style={{ minHeight: '82px', maxHeight: '82px' ,objectFit: 'contain' }} alt={`Product Image ${index + 1}`} />
              </div>
            ))}
        </div>
      </div>
    
     
    </div>
  </div>
</div>





           
        </>



    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        getProductByIdData: state.getProductById.data,
        message: state.getProductById.message,
        error: state.getProductById.error,
        loading: state.getProductById.loading,
        getWishListData: state.getWishList.data,
        addToCartMessage: state.addToCartProduct.message,
        addToCartError: state.addToCartProduct.error,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleAddWishListProduct: (data) => dispatch(addWishListProduct(data)),
        handleFetchProductById: (id) => dispatch(fetchProductById(id)),
        handleFetchGetWishlistByCustomerId: () => dispatch(fetchGetWishlist()),
        handleAddToCartProduct: (addToCardData) => dispatch(addToCartProduct(addToCardData))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(KroductImage);
