import React,{useEffect}  from 'react';
import { Box, Typography, IconButton, InputBase, Button, Input } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { fetchProductsByFilter } from '../../actions/products/getProductsByFilter';
import { useState } from 'react';
import { fetchAllCity } from "../../actions/city/getAllCity"
import { connect } from "react-redux";
//Links
import { fetchCartProducts } from '../../actions/products/cart/getCartProducts';

import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { fetchProductsBySearch } from "../../actions/products/getProductsBySearch";

import { GetAllPincodeByCityIdAction } from '../../actions/products/getAllPincodeByPincodeId';
import { ArrowUpward } from '@mui/icons-material';
import { basicAuth } from '../../actions/auth/basicAuth';
import axios from 'axios';
const SearchPageHeader = (props) => {
    const categories=useSelector((state)=>state.getAllMainCategory.data)
  const items = [
    'Queen Bed', 'Recliner', 'Wardrobe', 'Chair',
    'Microwave', 'Study Table', 'Sofa', 'TV', 'Appliances'
  ];
  const dispatch = useDispatch();
  const pincodeBycityId = useSelector((state) => state?.GetAllPincodeByCityIDREDCUER?.data)
  console.log(pincodeBycityId, 'pincodeBycityId')

  const cartData = useSelector((state => state?.getCartProducts?.data))
  const cart = cartData?.cartitemList?.length
  useEffect(() => {
      dispatch(fetchCartProducts())
  }, [dispatch])
  const {
      onOpenDrawer,
      fetchAllCityData,
      handleFetchAllCity,
      handleFetchProductsByFilter,
      message,
      ProductFilterData,
      error,
      setCityPincodeData,
      userSelectedPincode
  } = props

  const myStyle = {

  };

  const { data } = useSelector((state) => state.getProductsSearch);
  console.log(data, 'filterDatafilterDatafilterData')

  const isAuthenticated = localStorage.getItem('login');
  const isMobile = window.innerWidth <= 768;
  const { keyword } = useParams()
  const [value, setValue] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const toggleDrawer = () => {
      setIsOpen((prevState) => !prevState)
  }
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
      handleFetchAllCity()
  }, [dispatch])
  //Location selector
  const [selectedPincode, setSelectedPincode] = useState();
  const [selectedCity, setSelectedCity] = useState({
      cityName: "",
      pincode: ""
  });
  const [pincode, setPinCode] = useState('')


  const [filterData, setFilterData] = useState({
      "categoryName": "",
      "subCategoryNames": [],
      "minPrice": 0,
      "maxPrice": 0,
      "keyword": "",
      "pincode": ""
  })
  const [newPincode, setNewPincode] = useState()
  const [msgPincode, setPincodemg] = useState()


  

  const filteredCities = fetchAllCityData

  const HandleGetPincodeBycityId = () => {
      if (!selectedPincode) {
          // No pincode entered
          setPincodemg('Please enter a Valid  pincode');
          return;
      }
      console.log(pincodeBycityId,'pincodeBycityIdpincodeBycityId')
  
      if (pincodeBycityId && selectedPincode) {
          const selectedPincodeInt = parseInt(selectedPincode, 10);
          const filtered = pincodeBycityId.filter(pin => pin.pinCode === selectedPincodeInt);
  
          if (selectedCity && selectedCity.pincode === selectedPincodeInt) {
              // If the searched pincode is already selected
              setPincodemg('This Pincode already selected');
          } else if (filtered.length === 0) {
              // If no matching pincode found
              setPincodemg('Pincode is not serviceable');
          } else {
              // Valid pincode found, reset message and update the data
              setPincodemg(''); // Clear the message
              toggleDrawer();
              const firstMatchedPin = filtered[0]; // Get the first matched pincode
              const updatedSelectedCity = {
                  cityName: firstMatchedPin.city.cityName,
                  pincode: firstMatchedPin.pinCode
              };
              setSelectedCity(updatedSelectedCity);
              setPinCode(firstMatchedPin.pinCode);
              setCityPincodeData(updatedSelectedCity);
          }
      } else {
          console.log('Data or selected pincode is missing');
      }
  };
  console.log(filteredCities, 'filteredCities')



  const handleSearchProduct = () => {
      if (!filterData.keyword || filterData.keyword.trim() === '') {
          // Show an alert if the keyword is empty
          return
          // Optional: Dispatch an action if needed
          // dispatch(yourActionCreator());
          // Redirect to a different route or do nothing
          return;
      }

      // Proceed with fetching products and navigating
      // handleFetchProductsByFilter(filterData);
      navigate(`/SearchProduct/${filterData.keyword}`);
  };


  useEffect(() => {
      handleFetchProductsByFilter(filterData, keyword)
  }, [filterData]);

  useEffect(() => {
      localStorage.setItem('pincode', filterData.pincode)
  }, [filterData.pincode])

  useEffect(() => {
      setFilterData((prevFilterData) => ({
          ...prevFilterData,
          keyword: keyword ? keyword : '',
          pincode: userSelectedPincode?.pincode || 201010
      }));
  }, [userSelectedPincode])

  useEffect(() => {
      const cameFIRST = localStorage.getItem('addddcarrrr');

      if (!cameFIRST) {
          localStorage.setItem('addddcarrrr', 'true');
          toggleDrawer();
      }
  }, []);




  const handleCItySelect = async (item) => {

      console.log(item, 'itemitem.pincodeitem.pincode')
      const updatedSelectedCity = {
          cityName: item.cityName,
          pincode: item.pincode
      };
      dispatch(GetAllPincodeByCityIdAction(item?.cityId))
      setSelectedCity(updatedSelectedCity);
      setPinCode(item.pincode);
      localStorage.setItem('cityId', item?.cityId)
      // setFilterData((prevFilterData) => ({
      //     ...prevFilterData,
      //     keyword: keyword ? keyword : '',
      //     pincode: pincode ? parseInt(pincode, 10) : null
      //   }));
      toggleDrawer();
      setCityPincodeData(updatedSelectedCity);
      //    handleFetchProductsByFilter(filterData,keyword)
  }

  const [inputkey, setInputkey] = useState(null)


  const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
          handleSearchProduct()
      }
      setInputkey(null)
  };

  const handlepuincodePres = (e) => {
      if (e.key === 'Enter') {
          e.preventDefault(); // Prevent the form or page from refreshing
  
          if (!selectedPincode || selectedPincode.trim() === "") {
              setPincodemg('Please enter a valid pincode');
              return;
          }
  
          const selectedPincodeInt = parseInt(selectedPincode, 10);
          if (isNaN(selectedPincodeInt) || selectedPincodeInt.toString().length !== 6) {
              setPincodemg('Please enter a valid 6-digit pincode');
              return;
          }
  
          if (pincodeBycityId && selectedPincode) {
              const filtered = pincodeBycityId.filter(pin => pin.pinCode === selectedPincodeInt);
  
              if (selectedCity && selectedCity.pincode === selectedPincodeInt) {
                  setPincodemg('This Pincode is already selected');
              } else if (filtered.length === 0) {
                  setPincodemg('No service available for this pincode');
              } else {
                  setPincodemg(''); // Clear the message
                  toggleDrawer();
                  const firstMatchedPin = filtered[0];
                  const updatedSelectedCity = {
                      cityName: firstMatchedPin.city.cityName,
                      pincode: firstMatchedPin.pinCode
                  };
                  setSelectedCity(updatedSelectedCity);
                  setPinCode(firstMatchedPin.pinCode);
                  setCityPincodeData(updatedSelectedCity);
              }
          } else {
              console.log('Data or selected pincode is missing');
          }
      }
      setInputkey(null);
  };
  

  const handleInputChange = (e) => {
      const inputValue = e.target.value;
      setFilterData((prevFilterData) => ({
          ...prevFilterData,
          keyword: inputValue,
      }));
      setInputkey(inputValue.trim() === '' ? null : inputValue);
  };




  useEffect(() => {
    // Check if inputKey is not null and has at least 3 characters
    if (inputkey && inputkey.length >= 3) {
        const delayDebounceFn = setTimeout(() => {
            dispatch(fetchProductsBySearch(inputkey, filterData?.pincode));
        }, 300); // Optional: Add a debounce delay

        return () => clearTimeout(delayDebounceFn); // Cleanup on unmount
    }
}, [inputkey, filterData?.pincode]);


  // useEffect(() => {
  //     console.log(data, 'jhvcsbjcjhsbcjhcbjhscb')
  //     if (Array.isArray(data)) {
  //         const filteredSuggestions = data.filter((item) => {
  //             const searchTerm = inputkey?.toLowerCase();

  //             // Check if the item has a `status` of `true` and if any of the category names or product title contains the input key
  //             return (
  //                 item?.status !== false && // Filter out items with status `false`
  //                 (
  //                     item.productTitle.toLowerCase().includes(searchTerm) ||
  //                     item.mainCategoryName.toLowerCase().includes(searchTerm) ||
  //                     item.subCategoryName.toLowerCase().includes(searchTerm) ||
  //                     item.superCategoryName.toLowerCase().includes(searchTerm)
  //                 )
  //             );
  //         });
  //       //   setFilteruggestionProducts(filteredSuggestions);
  //     } else {
  //       //   setFilteruggestionProducts([]);
  //     }
  // }, [inputkey, data]);

  console.log(data, 'datataytcfasytcfasytc')
  const storedCity = localStorage.getItem('cityName');

  const handleSuggestProducts = (item) => {
      console.log(item, 'itemmm')
      const formattedProductName1 = item?.replace(/\s+/g, '-'); // Replace all spaces with hyphens
      if(storedCity){
        navigate(`/product-details/${storedCity}/${formattedProductName1}`);
        }else{
          navigate(`/product-details/${'noida'}/${formattedProductName1}`);
        }  }

  const handleFetchSuggestions = async () => {
    if(!inputkey){

      return setShowSuggestions(false)
    }
    try {
      if (inputkey.length < 3) {
        // Skip API call if input is less than 3 characters
        return;
      }
       setShowSuggestions(true)
  
      const headers = {
        "ngrok-skip-browser-warning": "skip-browser-warning",
        Authorization: basicAuth, // Ensure proper formatting
      };
  
      const baseUrl = 'https://api.rentyfurniture.com/';
      const query = inputkey; // Use the current input as query
  
      const response = await axios.get(`${baseUrl}search/lookahead`, {
        params: { q: query }, // Send 'q' as a query parameter
        headers: headers, // Optional headers if needed
      });
     setFilteruggestionProducts(response?.data?.data?.autoComplete)
    

      console.log('API Response:', response.data); // Log the response data
    } catch (error) {
      console.error('Error fetching suggestions:', error.response ? error.response.data : error.message);
    }
  };
  // Debounce function
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  // Debounced version of the fetch function to avoid API calls on every keystroke
  const debouncedFetchSuggestions = debounce(handleFetchSuggestions, 300);
  
  // Effect hook to trigger the fetch when inputkey changes
  useEffect(() => {
    console.log(inputkey,'inputkeyinputkeyinputkey')
   
    debouncedFetchSuggestions();
  }, [inputkey])
  

  const location = useLocation();
  useEffect(() => {
      const links = document.querySelectorAll('.item');
      links.forEach(link => {
          if (link.pathname === location.pathname) {
              link.classList.add('active');
          } else {
              link.classList.remove('active');
          }
      });
  }, [location]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filterSuggestion, setFilteruggestionProducts] = useState();
  console.log(filterSuggestion,'hiii')



  const handleInputFocus = () => {
      setShowSuggestions(true);
  };

  const handleInputBlur = () => {
      setShowSuggestions(false);
  };
  const handlenavigate=(e)=>{
      e.preventDefault()
      if(isAuthenticated){
          navigate('/whishlist')
      }else{
          navigate('/login')
      }
      
  }

   const handleSetCategory=(item)=>{
    setFilterData((prevFilterData) => ({
        ...prevFilterData,
        keyword: item,
    }));

   }
  return (
    <div style={{
        padding: '16px',
        maxWidth: '400px',
        margin: '0 auto',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
      }}>
        <div style={{ position: 'relative', marginBottom: '16px' }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#f3f4f6',
            borderRadius: '9999px',
            overflow: 'hidden'
          }}>
            <Link to="/" style={{ padding: '8px' }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ height: '20px', width: '20px', color: '#6b7280' }}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
            </Link>
            <input
              type="text"
              value={filterData?.keyword}
              onChange={handleInputChange}
              placeholder="Search"
              style={{
                flex: 1,
                backgroundColor: 'transparent',
                border: 'none',
                outline: 'none',
                padding: '8px'
              }}
            />
            <button
              onClick={handleSearchProduct}
              style={{
                background: 'none',
                border: 'none',
                padding: '8px',
                cursor: 'pointer'
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ height: '20px', width: '20px', color: '#6b7280' }}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </button>
          </div>
  
          {showSuggestions && (
            <div style={{
              position: 'absolute',
              zIndex: 10,
              width: '100%',
              marginTop: '4px',
              backgroundColor: 'white',
              borderRadius: '6px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
            }}>
              <ul style={{ padding: '4px 0' }}>

                {Array.isArray(filterSuggestion) && filterSuggestion?.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => handleSuggestProducts(item.Label)}
                    style={{
                      padding: '8px 16px',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      fontSize: '14px'
                    }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f3f4f6'}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ height: '16px', width: '16px', color: '#6b7280' }}>
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                    </svg>
                    <span>{item.Label}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
  
        <h2 style={{
          fontSize: '14px',
          fontWeight: 600,
          color: '#4b5563',
          marginBottom: '8px',
          display:'flex',
          justifyContent:'center'
        }}>
          Most Searched and Bought
        </h2>
  
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '8px'
        }}>
          {categories
            .filter((item) => item.status)
            .map((item) => (
              <button
                key={item.id}
                onClick={() => handleSetCategory(item.categoryName)}
                style={{
                  fontSize: '12px',
                  padding: '4px 8px',
                  height: 'auto',
                  backgroundColor: 'white',
                  border: '1px solid #d1d5db',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ height: '12px', width: '12px', marginRight: '4px' }}>
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                </svg>
                {item.categoryName}
              </button>
            ))}
        </div>
      </div>
  );
};


const mapStateToProps = (state, ownProps) => {
    return {
        fetchAllCityData: state.getAllCity.data,
        message: state.getProductsByFilter.message,
        error: state.getProductsByFilter.error,
        userSelectedPincode: state.setCityPincode.data,
        ProductFilterData: state.getProductsByFilter.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleFetchAllCity: () => dispatch(fetchAllCity()),

        handleFetchProductsByFilter: (data) => dispatch(fetchProductsByFilter(data)),
        setCityPincodeData: (data) => dispatch({ type: "SET_CITY_PINCODE_DATA", payload: data }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchPageHeader);
