import React from "react";

const Loader = () => {
  return (
    <>
      <style>
        {`
        .loading-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          z-index: 1000;
          background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black */
        }

        .loading-spinner {
          width: 40px;
          height: 40px;
          animation: rotate 1.5s linear infinite;
        }

        .loading-spinner circle {
          fill: none;
          stroke: white;
          stroke-width: 5;
          stroke-linecap: round;
          stroke-dasharray: 283; /* 2 * π * r */
          stroke-dashoffset: 75; /* Offset to create a loading effect */
          animation: dash 1.5s ease-in-out infinite;
        }

        .loading-text {
          color: #ffffff;
          font-size: 18px;
          margin-top: 15px;
        }

        /* Animations */
        @keyframes rotate {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        @keyframes dash {
          0% {
            stroke-dashoffset: 75;
          }
          50% {
            stroke-dashoffset: 0;
          }
          100% {
            stroke-dashoffset: 75;
          }
        }
        `}
      </style>
      <div className="loading-overlay">
        <svg
          className="loading-spinner"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
        >
          <circle cx="50" cy="50" r="45" />
        </svg>
      </div>
    </>
  );
};

export default Loader;
