import React, { useEffect } from 'react'
import Table from 'react-bootstrap/Table';

import { fetchAllOrdersByCustomerId } from '../../../actions/products/orders/getAllOrdersByCustomerId'
import { getInVOICEUCCESS } from '../../../actions/products/orders/getInvoice';

import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
function Invoice() {
  
  const data = useSelector((state) => state?.getInvoice?.data)
  console.log(data,'datadatadatadatadata')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getInVOICEUCCESS())
  }, [dispatch])
  const openFileInBrowser = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };
  const handleurl = async (urlss) => {
    console.log(urlss,'uruururuurur')
    localStorage.setItem('url',urlss)
    window.open('/pdfview', '', '');
   
};
  return (
    <>
      <style>
        {
          `
          @import 'https://fonts.googleapis.com/css?family=Open+Sans:600,700';

          * {font-family: 'Open Sans', sans-serif;}
          
          .rwd-table {
            margin: auto;
            min-width: 300px;
            max-width: 100%;
            border-collapse: collapse;
          }
          
          .rwd-table tr:first-child {
            border-top: none;
            color: #fff;
          }
          
          .rwd-table tr {
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
          }
          
          
          .rwd-table th {
            display: none;
          }
          
          .rwd-table td {
            display: block;
          }
          
          .rwd-table td:first-child {
            margin-top: .5em;
          }
          
          .rwd-table td:last-child {
            margin-bottom: .5em;
          }
          
          .rwd-table td:before {
            // : ) ": ";
            font-weight: bold;
            width: 120px;
            display: inline-block;
            color: #000;
          }
          
          .rwd-table th,
          .rwd-table td {
            text-align: left;
          }
          
          .rwd-table {
            color: #333;
            border-radius: .4em;
            overflow: hidden;
          }
          
          .rwd-table tr {
            border-color: #bfbfbf;
          }
          
          .rwd-table th,
          .rwd-table td {
            padding: .5em 1em;
          }
          @media screen and (max-width: 601px) {
            .rwd-table tr:nth-child(2) {
              border-top: none;
            }
          }
          @media screen and (min-width: 600px) {
            .rwd-table tr:hover:not(:first-child) {
            }
            .rwd-table td:before {
              display: none;
            }
            .rwd-table th,
            .rwd-table td {
              display: table-cell;
              padding: .25em .5em;
            }
            .rwd-table th:first-child,
            .rwd-table td:first-child {
              padding-left: 0;
            }
            .rwd-table th:last-child,
            .rwd-table td:last-child {
              padding-right: 0;
            }
            .rwd-table th,
            .rwd-table td {
              padding: 1em !important;
            }
          }
          
          
          /* THE END OF THE IMPORTANT STUFF */
          
          /* Basic Styling */
          
          h1 {
            text-align: center;
            font-size: 2.4em;
          }
          .container {
            display: block;
            text-align: center;
          }
          h3 {
            display: inline-block;
            position: relative;
            text-align: center;
            font-size: 1.5em;
            color: #cecece;
          }
          h3:before {
            
            position: absolute;
            left: -50px;
            -webkit-animation: leftRight 2s linear infinite;
            animation: leftRight 2s linear infinite;
          }
          h3:after {
            
            position: absolute;
            right: -50px;
            -webkit-animation: leftRight 2s linear infinite reverse;
            animation: leftRight 2s linear infinite reverse;
          }
          @-webkit-keyframes leftRight {
            0%    { -webkit-transform: translateX(0)}
            25%   { -webkit-transform: translateX(-10px)}
            75%   { -webkit-transform: translateX(10px)}
            100%  { -webkit-transform: translateX(0)}
          }
          @keyframes leftRight {
            0%    { transform: translateX(0)}
            25%   { transform: translateX(-10px)}
            75%   { transform: translateX(10px)}
            100%  { transform: translateX(0)}
          }
          
          /*
              Don't look at this last part. It's unnecessary. I was just playing with pixel gradients... Don't judge.
          */
          /*
          @media screen and (max-width: 601px) {
            .rwd-table tr {
              background-image: -webkit-linear-gradient(left, #428bca 137px, #f5f9fc 1px, #f5f9fc 100%);
              background-image: -moz-linear-gradient(left, #428bca 137px, #f5f9fc 1px, #f5f9fc 100%);
              background-image: -o-linear-gradient(left, #428bca 137px, #f5f9fc 1px, #f5f9fc 100%);
              background-image: -ms-linear-gradient(left, #428bca 137px, #f5f9fc 1px, #f5f9fc 100%);
              background-image: linear-gradient(left, #428bca 137px, #f5f9fc 1px, #f5f9fc 100%);
            }
            .rwd-table tr:nth-child(odd) {
              background-image: -webkit-linear-gradient(left, #428bca 137px, #ebf3f9 1px, #ebf3f9 100%);
              background-image: -moz-linear-gradient(left, #428bca 137px, #ebf3f9 1px, #ebf3f9 100%);
              background-image: -o-linear-gradient(left, #428bca 137px, #ebf3f9 1px, #ebf3f9 100%);
              background-image: -ms-linear-gradient(left, #428bca 137px, #ebf3f9 1px, #ebf3f9 100%);
              background-image: linear-gradient(left, #428bca 137px, #ebf3f9 1px, #ebf3f9 100%);
            }
          }*/
          `
        }
      </style>
      <div className=''  striped bordered hover style={{height:'73vh',overflow:'auto'}}>
      <h4>

<div>Invoice</div>
</h4>
    <Table striped bordered hover>
    <thead>
      <tr>
        <th>Order Id</th>
        <th>Delivery Date</th>
        <th>Order Status</th>
         <th>Invoice</th>
         <th>View Receipt</th>

      </tr>
    </thead>
    {
      console.log(data)
    }
    <tbody>
    {Array.isArray(data) ? (
  [...data].reverse()?.map((item, index) => (
    
  <tr style={{cursor:'pointer'}} key={index} onClick={()=>handleurl(item.orderInvoicePathName)}>

      <td>{item.orderId}</td>
      <td>
  {item.deliveryDate}
</td>      <td>{item.orderStatus}</td>
      <td>
        <button onClick={()=>handleurl(item.orderInvoicePathName)}>Receipt</button>
      </td>
      <td >
        <button style={{ background: 'rgb(245, 99, 88)', color: 'white', borderRadius: '4px', padding: '10px' }}onClick={()=>handleurl(item.orderInvoicePathName)}>View Receipt</button>
      </td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="7">No data available</td>
  </tr>
)}

    </tbody>
  </Table>
  </div>
    </>
   
  )
}

export default Invoice