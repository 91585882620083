import React, { useEffect, useRef } from "react";
//API Methods
import { fetchGetWishlist } from "../../../actions/products/wishList/getWishList";
import { deleteWishListByCustomerId } from "../../../actions/products/wishList/deleteWishListByCustomerId";
//Redux
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../../style/css/wishlist.scss";
import { Link } from "react-router-dom";
import { LiaSearchSolid } from "react-icons/lia";
import google from "./google.png";
import { ArrowForward, Delete, DeleteForever } from "@mui/icons-material";
import outofstock from "./outofstock.png";
import Swal from "sweetalert2";
import wishlist from './wishlist.png'
function Wishlist(props) {
  const navigate = useNavigate();
  const {
    handleFetchGetWishList,
    fetchGetWishListData,
    error,
    loading,
    handleDeleteWishListByCustomerId,
  } = props;

  console.log(fetchGetWishListData, "fetchGetWishListDatafetchGetWishListData");
  const isMobile = window.innerWidth <= 768;
  const hasLoadedcustomer=useRef()
  useEffect(() => {
    // Check if we're on the product details page based on the URL path
    if ( !hasLoadedcustomer.current) {
      handleFetchGetWishList();
      hasLoadedcustomer.current = true;
    }
  }, []);  // Depend on dispatch and location.pathname


  const handleGoBack = () => {
    navigate(-1); // This will navigate back one step in the history stack
  };

  const handleDeleteWishListItem = async (id) => {
    // Confirm the deletion with the user first
    const result = await Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to remove this product from wishlist?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33", // Red color for the "Yes" button
      cancelButtonColor: "#3085d6", // Color for the "Cancel" button
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    });
  
    if (!result.isConfirmed) {
      return; // Exit if the user cancels
    }
  
    // Proceed to delete the item after confirmation
    await handleDeleteWishListByCustomerId(id);
  
    // Fetch the updated wish list
    await handleFetchGetWishList();
  };
  const storedCity = localStorage.getItem('cityName');

  const handleProductDetailPage = (id) => {
    const formattedProductName = id?.replace(/\s+/g, '-'); // Replace all spaces with hyphens
    if(storedCity){
      navigate(`/product-details/${storedCity}/${formattedProductName}`);
      }else{
        navigate(`/product-details/${'noida'}/${formattedProductName}`);
      }
  };
  const mydata = (days) => {
    const currentDate = new Date();
    const futureDate = new Date(
      currentDate.getTime() + days * 24 * 60 * 60 * 1000
    );
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    var forDate = futureDate.toLocaleDateString("en-GB", options);
    return forDate;
  };

  const calculateDiscouunt = (twelveMonth, perMonth) => {
    // Calculate the total price for 18 months without discount
    const discountPercentage = ((perMonth - twelveMonth) / perMonth) * 100;
    // Return the discount percentage for 18 months
    return discountPercentage.toFixed(2);
  };
  return (
    <>
    <style>
      {
        `
        .css-70i1zb {
    margin-top: 110px;
}
        `
      }
    </style>
      {isMobile ? (
        <>
          <style>
            {`.MuiBox-root.css-1nqx8u4 {
                            display: grid !important;
                            grid-template-columns: repeat(2, 1fr);
                            gap: 19px;
                                margin-bottom: 79px;

                        }`}
          </style>
          <div class="MuiBox-root css-1lckomh" bis_skin_checked="1">
            <div class="MuiBox-root css-1gdj1ta" bis_skin_checked="1">
              <div class="MuiBox-root css-1ujzu5j" bis_skin_checked="1">
                <div class="MuiBox-root css-r23g3s" bis_skin_checked="1">
                  <div class="MuiBox-root css-1kz6cvi" bis_skin_checked="1">
                    <div
                      class="MuiBox-root css-0"
                      bis_skin_checked="1"
                      onClick={handleGoBack}
                    >
                      <svg
                        width="24"
                        height="24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19 12H5M12 19l-7-7 7-7"
                          stroke="#555"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </div>
                    <div class="MuiBox-root css-9kl10t" bis_skin_checked="1">
                      <p class="MuiTypography-root MuiTypography-body1 css-1ys33rp">
                        Wishlist
                      </p>
                      <p class="MuiTypography-root MuiTypography-body1 css-10lfzr7">
                      {fetchGetWishListData=='No items present in wishlist'?'0 Items':fetchGetWishListData.length} 

                      </p>
                    </div>
                  </div>
                
                </div>
              </div>
              <div class="MuiBox-root css-8atqhb" bis_skin_checked="1">
             
                <div class="MuiBox-root css-1nqx8u4" bis_skin_checked="1">
                  {Array.isArray(fetchGetWishListData) &&
                  fetchGetWishListData.length !== 0 ? (
                    fetchGetWishListData.map((item, index) => (
                      // <Link to="/product-details" id="product-tile-4-link" style={{ width: "48%", textDecoration: "none" }}>
                      <div class="MuiBox-root css-1thgq7w" bis_skin_checked="1"  >
                        <div>
                          <div
                            class="MuiBox-root css-cnjuyp"
                            bis_skin_checked="1"
                            style={{ paddingTop: "" }}
                           
                          >
                            <div
                              className="MuiBox-root css-14c5dc4"
                              bis_skin_checked="1"
                              style={{ position: "relative" }}
                            
                            >
                              {console.log(
                                item?.productdto?.productId,
                                "item?.inStockQuantityitem?.inStockQuantity"
                              )}
                              {item?.productdto?.inStockQuantity == 0 && (
                                <>
                                  <div
                                    className=""
                                    style={{
                                      width: "20px",
                                      position: "relative",
                                    }}
                                    onClick={() =>
                                      handleProductDetailPage(
                                        item?.productdto?.productTitle
                                      )
                                    }
                                  >
                                    <img
                                      loading="lazy"
                                      src={outofstock}
                                      style={{
                                        width: "80px",
                                        position: "absolute",
                                        top: "20px",
                                        zIndex: "999",
                                      }}
                                      onClick={() =>
                                        handleProductDetailPage(
                                          item?.productdto?.productTitle
                                        )
                                      }
                                    />
                                  </div>
                                </>
                              )}
                              {item && item.productdto && (
                                <div style={{ position: "relative" }}  >
                                  {item?.productdto.stripText ? (
                                    <>
                                                            <div style={{ position: "relative" }}>
                                      {item?.productdto.stripText && (
                                        <p
                                          className="strip-text"
                                          style={{
                                            backgroundColor: item?.productdto.stripColor || "transparent", // Default to transparent if color is missing
                                            position: "absolute",
                                            top: "", // Adjusted based on your layout
                                            right: 0,
                                            fontSize: "12px",
                                            zIndex: 1, // Ensures text appears on top
                                            padding: "2px", // More consistent padding
                                            borderRadius: "3px", // Optional for rounded edges
                                            color: "#fff", // Default text color for visibility
                                          }}
                                        >
                                          {item.productdto?.stripText}
                                        </p>
                                      )}
                                    </div>
                                   
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <div
                                    className="MuiBox-root css-1om2adh"
                                    bis_skin_checked="1"
                                    style={{ paddingTop: "100%" }}
                                    onClick={() =>
                                      handleProductDetailPage(
                                        item?.productdto?.productTitle
                                      )
                                    }
                                  >
                                    <img
                                      onClick={() =>
                                        handleProductDetailPage(
                                          item?.productdto?.productTitle
                                        )
                                      }
                                      src={
                                        item?.productdto?.productImagesPath[0]
                                      }
                                      alt=""
                                      data-srcset={
                                        item?.productdto.productImagesPath[0]
                                      }
                                      sizes=""
                                      className="lazy loaded"
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        visibility: "visible",
                                      }}
                                      srcSet={
                                        item.productdto?.productImagesPath[0]
                                      }
                                    />
                                  </div>
                                  <p
                                    className="MuiTypography-root MuiTypography-H11A_Medium css-bq4bdz"
                                    style={{
                                      display: "-webkit-box",
                                      WebkitLineClamp: 2, // Limit to 2 lines
                                      WebkitBoxOrient: "vertical",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                    onClick={() =>
                                      handleProductDetailPage(
                                        item?.productdto?.productTitle
                                      )
                                    }
                                  >
                                    {item?.productdto?.productTitle}
                                  </p>
                                  <div
                                    className=""
                                    style={{
                                      width: "30px",
                                      position: "absolute",
                                      top: 0,
                                      right: "30px",
                                      zIndex: 1, // Ensures the text is on top of the image
                                    }}
                                    onClick={() =>
                                      handleProductDetailPage(
                                        item?.productdto?.productTitle
                                      )
                                    }
                                  ></div>
                                  <div
                                    className="MuiBox-root css-ldca19"
                                    bis_skin_checked="1"
                                    style={{ width: "100%", padding: "10px" }}
                                  >
                                    <p
                                      style={{
                                        display: "flex",
                                        justifyContent: "",
                                        alignItems: "center",
                                        margin: "0px",
                                        display: "-webkit-box",
                                        WebkitLineClamp: 2, // Limit to 2 lines
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                      className=""
                                      onClick={() =>
                                        handleProductDetailPage(
                                          item?.productdto?.productTitle
                                        )
                                      }
                                    >
                                      {item.productTitle}
                                    </p>
                                    <div
                                      className=""
                                      style={{
                                        display: "flex",
                                        gap: "6px",
                                        alignItems: "center",
                                        color: "grey",
                                        justifyContent: "space-between",
                                      }}
                                      onClick={() =>
                                        handleProductDetailPage(
                                          item?.productdto?.productTitle
                                        )
                                      }
                                    >
                                      <p className="MuiTypography-root MuiTypography-Small_Medium css-11je29n"   onClick={() =>
                                handleProductDetailPage(
                                  item?.productdto?.productTitle
                                )
                              }>
                                        -
                                        {calculateDiscouunt(
                                          item?.productdto.twelveMonth,
                                          item?.productdto.perMonth
                                        )}
                                        % off
                                      </p>
                                      <p className="">
                                        <s>₹{item?.productdto.perMonth}/mo</s>
                                      </p>
                                    </div>
                                    <p className="MuiTypography-root MuiTypography-body1 css-2kxnf0"   onClick={() =>
                                handleProductDetailPage(
                                  item?.productdto?.productTitle
                                )
                              }>
                                      ₹ {item?.productdto.twelveMonth}
                                    </p>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div style={{ displya: "" }}>
                                        <div
                                          className="MuiBox-root css-ov56kn"
                                          bis_skin_checked="1"
                                        >
                                          <p className="MuiTypography-root MuiTypography-body1 css-1ws68lr">
                                            ₹ {item.productdto?.price / 10}
                                          </p>
                                        </div>
                                        <p className="MuiTypography-root MuiTypography-body1 css-1ko6fke">
                                          Get it by{" "}
                                          {mydata(
                                            item?.productdto?.availabeDay
                                          )}
                                        </p>
                                      </div>
                                      <div
                                        className=""
                                        style={{
                                          display: "flex",
                                          justifyContent: "end",
                                          alignItems: "end",
                                        }}
                                      >
                                        <ArrowForward />
                                      </div>
                                      <DeleteForever
                                    onClick={() =>
                                      handleDeleteWishListItem(
                                        item.productdto?.productId
                                      )
                                    }
                                  />
                                    </div>
                                    
                                  </div>

                                 
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      // 
                    ))
                  ) : (
                   ""
                  )}
                  {/* 
                                    <Link to="/product-details" style={{ width: "48%", textDecoration: "none" }}>
                                        <div class="MuiBox-root css-1thgq7w" bis_skin_checked="1">
                                            <div class="MuiBox-root css-0" bis_skin_checked="1">
                                                <div class="MuiBox-root css-cnjuyp" bis_skin_checked="1" style={{ paddingTop: "100%" }}><img src="https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,w_20/r2/products/3459/plp_1.png" alt="" data-srcset="https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_1.0,w_150/r2/products/3459/plp_1.png 1x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_2.0,w_150/r2/products/3459/plp_1.png 2x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_3.0,w_150/r2/products/3459/plp_1.png 3x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_4.0,w_150/r2/products/3459/plp_1.png 4x" sizes="" class="lazy loaded" style={{ height: "auto", width: "100%", visibility: "visible" }} srcset="https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_1.0,w_150/r2/products/3459/plp_1.png 1x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_2.0,w_150/r2/products/3459/plp_1.png 2x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_3.0,w_150/r2/products/3459/plp_1.png 3x, https://assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive,dpr_4.0,w_150/r2/products/3459/plp_1.png 4x" />
                                                </div>
                                            </div>
                                            <div class="MuiBox-root css-zgdir6" bis_skin_checked="1">
                                                <div class="MuiBox-root css-0" bis_skin_checked="1">
                                                    <div class="MuiBox-root css-t9bxul" bis_skin_checked="1"><svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.524.464a.5.5 0 0 1 .952 0l1.176 3.622h3.81a.5.5 0 0 1 .293.904L8.674 7.23l1.177 3.622a.5.5 0 0 1-.77.56L6 9.17l-3.081 2.24a.5.5 0 0 1-.77-.56L3.326 7.23.245 4.99a.5.5 0 0 1 .294-.904h3.809L5.524.464Z" fill="#003B58"></path></svg>
                                                        <p class="MuiTypography-root MuiTypography-body1 css-10ku9no">Best-Seller </p>
                                                    </div>
                                                    <div class="MuiBox-root css-efww2t" bis_skin_checked="1"><svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none"><path d="M0 0H8V8L0 0Z" fill="#047992"></path></svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="MuiBox-root css-1valicv" bis_skin_checked="1">
                                                <p class="MuiTypography-root MuiTypography-body1 css-17dct1g">Allen Engineered Wood  Queen Bed with 6" Premium Mattress</p>
                                                <p class="MuiTypography-root MuiTypography-body1 css-l8qz8w">RENT</p>
                                                <p class="MuiTypography-root MuiTypography-body1 css-1lt348a">₹818/mo</p>
                                                <div class="MuiBox-root css-hjjb99" bis_skin_checked="1">
                                                    <p class="MuiTypography-root MuiTypography-body1 css-hre9zm">-55%</p>
                                                    <p class="MuiTypography-root MuiTypography-body1 css-851eir">₹1,816/mo</p>
                                                </div>
                                                <div class="MuiBox-root css-1bkybme" bis_skin_checked="1">
                                                    <div class="MuiBox-root css-0" bis_skin_checked="1">
                                                        <p class="MuiTypography-root MuiTypography-body1 css-113f6zb">Delivery by 17 Oct</p>
                                                        <p class="MuiTypography-root MuiTypography-body1 css-10lfzr7">2 option(s) available</p>
                                                    </div>
                                                    <div class="MuiBox-root css-0" bis_skin_checked="1"><svg width="13" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.708 5.797a.375.375 0 0 0-.374.375v7.082a.375.375 0 0 0 .749 0V6.172a.375.375 0 0 0-.375-.375ZM4.287 5.797a.375.375 0 0 0-.375.375v7.082a.375.375 0 0 0 .75 0V6.172a.375.375 0 0 0-.375-.375Z" fill="#555"></path><path d="M1.064 4.763v9.232c0 .546.2 1.058.55 1.426.348.369.832.578 1.339.579h7.09c.506 0 .99-.21 1.338-.579.35-.368.55-.88.55-1.426V4.763a1.431 1.431 0 0 0-.367-2.814H9.644V1.48A1.472 1.472 0 0 0 8.161 0H4.834A1.472 1.472 0 0 0 3.35 1.48v.469H1.43a1.432 1.432 0 0 0-.367 2.814Zm8.978 10.488h-7.09c-.64 0-1.138-.55-1.138-1.256V4.796h9.367v9.2c0 .704-.498 1.255-1.139 1.255ZM4.1 1.48a.721.721 0 0 1 .735-.732H8.16a.722.722 0 0 1 .735.731v.469H4.099V1.48ZM1.431 2.697h10.133a.674.674 0 0 1 0 1.349H1.43a.674.674 0 1 1 0-1.35Z" fill="#555"></path><path d="M6.498 5.797a.375.375 0 0 0-.375.375v7.082a.375.375 0 0 0 .75 0V6.172a.375.375 0 0 0-.375-.375Z" fill="#555"></path></svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link> */}
                </div>
              </div>
            </div>
            {
              console.log(fetchGetWishListData,'fetchGetWishListData')
            }
            {fetchGetWishListData=='No items present in wishlist' &&(
                  <>
                  <div style={{margin:'auto',display:'flex',textAlign:'center',alignContent:'center'}}>
                  <img src={wishlist} style={{width:'70%',margin:'auto'}}/>
                  </div>
                  <div style={{display:'flex',flexDirection:'column',gap:'10px',textAlign:'center'}}>
                  <h6 style={{margin:'auto'}}>
                    Your wishlist is currently empty
                  </h6>
                  <h6 style={{margin:'auto'}}>
                    Discover a variety of exciting product on our Shop page to add your favorites!
                  </h6>
                  <button
                          className="buttonheber"
                          style={{
                            background: "",
                            padding: "10px",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            margin: "auto",
                            color:'white',
                            backgroundColor:'rgb(245, 99, 88)'
                          }}
                        >
                          <Link className="buttonheber" to="/" style={{color:'white'}}>
                            Explore Renting
                          </Link>
                        </button>
                        </div>
                  </>
                )

                }
          </div>
        
        </>
      ) : (
        <>
        
        <div class="MuiBox-root css-70i1zb" id="main" bis_skin_checked="1">
          <div
            class="MuiContainer-root MuiContainer-maxWidthXl css-1hzmgcr"
            bis_skin_checked="1"
          >
            <div class="MuiBox-root css-1455dh1" bis_skin_checked="1">
              <div class="MuiBox-root css-1tuqrad" bis_skin_checked="1">
                <div class="MuiBox-root css-1l3icnk" bis_skin_checked="1">
                  <p class="MuiTypography-root MuiTypography-H9A_Medium css-vxc7zz">
                    Wishlist
                  </p>
                  <p class="MuiTypography-root MuiTypography-H10A_Regular css-pujg1b">
                    {fetchGetWishListData=='No items present in wishlist'?'0 Items':fetchGetWishListData.length} 
                  </p>
                </div>
            
                
              </div>

              <div class="MuiBox-root css-1hrl6e0" bis_skin_checked="1">
                {Array.isArray(fetchGetWishListData) &&
                fetchGetWishListData.length !== 0
                  ? fetchGetWishListData.map((item, index) => (
                      <>
                        <div
                          key={index}
                          className="MuiBox-root css-18q7liu  singleproduct"
                          bis_skin_checked="1"
                         
                        >
                          <div
                            className="MuiBox-root css-w26f2k"
                            bis_skin_checked="1"
                            onClick={() =>
                              handleProductDetailPage(
                                item?.productdto?.productTitle
                              )
                            }
                          >
                            <div
                              className="MuiBox-root css-14c5dc4"
                              bis_skin_checked="1"
                              style={{ position: "relative" }}
                            >
                              {console.log(
                                item?.productdto.inStockQuantity,
                                "item?.inStockQuantityitem?.inStockQuantity"
                              )}
                              {Number(item?.productdto?.inStockQuantity) == 0 && (
                                <>
                                  <div
                                    className=""
                                    style={{
                                      width: "20px",
                                      position: "relative",
                                    }}
                                  >
                                    <img loading='lazy' src={outofstock} style={{ width: '100px', position: 'absolute', top: '', zIndex: '999' }} />
                                  </div>
                                </>
                              )}
                              {item && item.productdto && (
                                <div style={{ position: "relative" }}>
                                  {item?.productdto?.stripText ? (
                                    <>
                                      <p
                                        className="MuiTypography-root MuiTypography-Small_Medium css-11je29n"
                                        style={{
                                          backgroundColor: `${item?.productdto.stripColor}`,
                                          position: "absolute",
                                          top: "84%",
                                          left: 0,
                                          zIndex: 1, // Ensures the text is on top of the image
                                          padding: "5px", // Optional: add some padding to the text
                                        }}
                                      >
                                        {item?.productdto?.stripText}
                                      </p>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  <div
                                    className=""
                                    style={{
                                      width: "30px",
                                      position: "absolute",
                                      top: 0,
                                      right: "30px",
                                      zIndex: 1, // Ensures the text is on top of the image
                                    }}
                                  ></div>
                                  <div
                                    className="MuiBox-root css-1om2adh"
                                    bis_skin_checked="1"
                                    style={{ paddingTop: "100%" }}
                                  >
                                    <img
                                      src={
                                        item?.productdto?.productImagesPath[0]
                                      }
                                      alt=""
                                      data-srcset={
                                        item?.productdto.productImagesPath[0]
                                      }
                                      sizes=""
                                      className="lazy loaded"
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        visibility: "visible",
                                      }}
                                      srcSet={
                                        item.productdto?.productImagesPath[0]
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          <div
                            className="MuiBox-root css-ldca19"
                            bis_skin_checked="1"
                            style={{ width: "100%" }}
                          >
                            <p
                              style={{
                                display: "flex",
                                justifyContent: "",
                                alignItems: "center",
                                margin: "0px",
                                display: "-webkit-box",
                                WebkitLineClamp: 2, // Limit to 2 lines
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              className=""
                              onClick={() =>
                                handleProductDetailPage(
                                  item?.productdto?.productTitle
                                )
                              }
                            >
                              {item?.productdto.productTitle}
                            </p>
                            <div
                              className=""
                              style={{
                                display: "flex",
                                gap: "6px",
                                alignItems: "center",
                                color: "grey",
                                justifyContent: "space-between",
                              }}
                              onClick={() =>
                                handleProductDetailPage(
                                  item?.productdto?.productTitle
                                )
                              }
                            >
                              <p className="MuiTypography-root MuiTypography-Small_Medium css-11je29n">
                                -
                                {calculateDiscouunt(
                                  item?.productdto.twelveMonth,
                                  item?.productdto.perMonth
                                )}
                                % off
                              </p>
                              <p className="">
                                <s>₹{item?.productdto.perMonth}/mo</s>
                              </p>
                            </div>
                            <p className="MuiTypography-root MuiTypography-body1 css-2kxnf0"  onClick={() =>
                            handleProductDetailPage(
                              item?.productdto?.productTitle
                            )
                          }>
                              ₹ {item?.productdto.twelveMonth}
                            </p>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ displya: "" }}  onClick={() =>
                            handleProductDetailPage(
                              item?.productdto?.productTitle
                            )
                          }>
                                <div
                                  className="MuiBox-root css-ov56kn"
                                  bis_skin_checked="1"
                                >
                                  <p className="MuiTypography-root MuiTypography-body1 css-1ws68lr">
                                    ₹ {item.productdto?.price / 10}
                                  </p>
                                </div>
                                <p className="MuiTypography-root MuiTypography-body1 css-1ko6fke">
                                  Get it by{" "}
                                  {mydata(item?.productdto?.availabeDay)}
                                </p>
                              </div>
                              <div
                                className=""
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  alignItems: "end",
                                }}
                                onClick={() =>
                                  handleProductDetailPage(
                                    item?.productdto?.productTitle
                                  )
                                }
                              >
                                <ArrowForward />
                              </div>
                              <DeleteForever
                                onClick={() =>
                                  handleDeleteWishListItem(
                                    item.productdto?.productId
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </div>
        <div>
        {fetchGetWishListData=='No items present in wishlist' &&(
                  <>
                  <div style={{margin:'auto',display:'flex',textAlign:'center',alignContent:'center'}}>
                  <img src={wishlist} style={{width:'260px',margin:'auto'}}/>
                  </div>
                  <div style={{display:'flex',flexDirection:'column',gap:'10px'}}>
                  <h6 style={{margin:'auto'}}>
                    Your wishlist is currently empty
                  </h6>
                  <h6 style={{margin:'auto'}}>
                    Discover a variety of exciting product on our Shop page to add your favorites!
                  </h6>
                  <button
                          className="buttonheber"
                          style={{
                            background: "",
                            padding: "10px",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            margin: "auto",
                              color:'white',
                            backgroundColor:'rgb(245, 99, 88)'
                          }}
                        >
                          <Link className="buttonheber" to="/" style={{color:'white'}}>
                            Explore Renting
                          </Link>
                        </button>
                        </div>
                  </>
                )

                }
        </div>
        </>
      )}
    </>
  );
}
const mapStateToProps = (state, ownProps) => {
  return {
    fetchGetWishListData: state.getWishList.data,
    error: state.getWishList.error,
    loading: state.getWishList.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    handleFetchGetWishList: () => dispatch(fetchGetWishlist()),
    handleDeleteWishListByCustomerId: (id) =>
      dispatch(deleteWishListByCustomerId(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);
