import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import "./style/css/style1.css"
import "./style/css/style2.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {StyledEngineProvider} from '@mui/material/styles';
import $ from 'jquery';
import Popper from 'popper.js';
import {Provider} from "react-redux";
import {store as reduxstore, persistor} from "./store/createStore";
import {PersistGate} from 'redux-persist/integration/react';
import ScrollToTop from './scrolltop';
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <ScrollToTop/>
        <StyledEngineProvider injectFirst>
            <Provider store={reduxstore}>
                <PersistGate loading={null} persistor={persistor}>
                    <DevSupport ComponentPreviews={ComponentPreviews}
                                useInitialHook={useInitial}
                    >
                        <App/>
                    </DevSupport>
                </PersistGate>
            </Provider>
        </StyledEngineProvider>
    </BrowserRouter>
    //  </React.StrictMode>
);


// reportWebVitals();

// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { BrowserRouter } from "react-router-dom";
// import "./style/css/style1.css"
// import "./style/css/style2.css"
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
// import { StyledEngineProvider } from '@mui/material/styles';
// import $ from 'jquery';
// import Popper from 'popper.js';
// import { Provider } from "react-redux";
// import { store as reduxstore } from "./store/createStore";
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <StyledEngineProvider injectFirst>
//         <Provider store={reduxstore}>
//             <App />
//         </Provider>
//       </StyledEngineProvider>
//     </BrowserRouter>
//   </React.StrictMode>
// );


// reportWebVitals();

