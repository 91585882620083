import image from './image2.png'
import image2 from './living_icon.png'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import icon1 from './icon1.png'
// import icon2 from './icon2.png'
// import icon3 from './icon3.png'
// import icon4 from './icon4.png'
// import icon5 from './icon5.png'
// import icon6 from './icon6.png'
import icon1 from './product-as-good-as-new.png'
import icon2 from './Insurance-Cover.png'
import icon3 from './shipping.png'
import icon4 from './repair.png'
import icon5 from './return.png'
import icon6 from './relocate.png'
import { ArrowBack, ArrowForward } from '@mui/icons-material';






const CardComponent = () => {
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerPadding: "30px",
    arrows: false,
    
    nextArrow: false, // Custom next arrow
    prevArrow: false, // Custom prev arrow
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          centerPadding: "80px"
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,  // Changed to show 4 slides on small screens
          slidesToScroll: 1,
          centerPadding: "80px"
        }
      }
    ]
  };

  const containerStyle = {
    width: '91%',
    margin: "auto",
    marginTop: '20px'
  };

  const imageContainerStyle = {
    padding: '0 10px', // Add padding for gap between images
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
  };

  const getImageStyle = () => {
    const isMobile = window.innerWidth < 480; // Adjust breakpoint as needed

    return {
      width: isMobile ? "70%" : "100%",
      maxWidth: isMobile ? "100px" : "150px",
      margin: "auto"
    };
  };

  // Use getImageStyle() when rendering your image
  const imageStyle = getImageStyle();

  return (
    <>
      <style>
        {
          `
        
                .FLELLELE {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 62px;
}
    @media(max-width:768px){
    .FLELLELE{
    grid-template-columns: repeat(2, 1fr);

    }
    .title{
    font-size:9px !important;
    }
 .slide {
  margin: 0 10px; /* Adjust as needed for spacing */
}

    
    }
       
                `
        }
      </style>
      {
        <>

          <div style={containerStyle}>
            <Slider {...settings}>
              <div style={imageContainerStyle} className='text-center'>
                <img src={icon1} style={imageStyle} alt="product1" />
                <h6 className='mt-2  title'>

                  Product As Good As New
                </h6>
              </div>
              <div style={imageContainerStyle} className='text-center'>
                <img src={icon2} style={imageStyle} alt="product2" />
                <h6 className='mt-2 title'>
                  Insurance Cover
                </h6>
              </div>
              <div className='text-center leading-7' style={{ imageContainerStyle, border: '1px solid black' }}>
                <img src={icon3} style={imageStyle} alt="product3" />
                <h6 className='mt-2 title'>
                  Free & Easy Shiping
                </h6>
              </div>
              <div style={imageContainerStyle} className='text-center'>
                <img src={icon4} style={imageStyle} alt="product4" />
                <h6 className='mt-2 title'>
                  Free Repair & Maintenance
                </h6>
              </div>
              <div style={imageContainerStyle} className='text-center'>
                <img src={icon5} className='' style={imageStyle} alt="product5" />
                <h6 className='mt-2 title'>
                  Easy Return, No Question Asked
                </h6>
              </div>
              <div style={imageContainerStyle} className='text-center'>
                <img src={icon6} style={imageStyle} alt="product6" />
                <h6 className='mt-2 title'>
                  Free Upgrades & Relocatation
                </h6>
              </div>
            </Slider>
          </div>
        </>
      }

    </>
  )
}

export default CardComponent