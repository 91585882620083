import {
    GET_PRODUCTS_BY_FILTER_REQUEST,
    GET_PRODUCTS_BY_FILTER_SUCCESS,
    GET_PRODUCTS_BY_FILTER_FAILURE,
    GetAllProductsHomeRequest,
    GetAllProductsHomeSucccess,
    GetAllProductsHomeFailure,
} from "../types";
import axios from "axios";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";
export const fetchProductsByFilterRequest = () => {
    return {
        type: GET_PRODUCTS_BY_FILTER_REQUEST,
    };
};
export const fetchProductsByFilterSuccess = (Data) => {
    return {
        type: GET_PRODUCTS_BY_FILTER_SUCCESS,
        payload: Data,
    };
};

export const fetchProductsByFilterFailure = (Error) => {
    return {
        type: GET_PRODUCTS_BY_FILTER_FAILURE,
        payload: Error,
    };
};






export const fetchProductsByFilter = (data, keyword) => {
    console.log("Our Pin code data is here =====>", data);
    const headers = {
        "Content-Type": "Application/json",
        'Authorization': basicAuth,
    };

    return (dispatch) => {
        dispatch(fetchProductsByFilterRequest());

        // Introduce a delay of 5 seconds
        setTimeout(() => {
            const categoryName = localStorage.getItem('cat');

            // Validation check
            // if (!data?.categoryName || data?.categoryName.trim() === ''||categoryName) {
            //     // Handle the case where categoryName is empty
            //     console.log("Category name is empty. API call will not be made.");
            //     // Optionally, dispatch an action to notify the failure
            //     dispatch(fetchProductsByFilterFailure(new Error("Category name cannot be empty.")));
            //     return; // Exit the function
            // }

            axios
                .post(url + `getProductByFilter`, {
                    "categoryName": categoryName,
                    "subCategoryNames": data?.subCategoryNames ? data.subCategoryNames : [],
                    "supperCategoryNames": data?.supperCategoryNames ? data.supperCategoryNames : [],
                    "minPrice": data?.minPrice ? data.minPrice : 0,
                    "maxPrice": data?.maxPrice ? data.maxPrice : 0,
                    "keyword": data?.keyword ? data.keyword : null,
                    "pincode": localStorage.getItem('pincode'),
                },
                {
                    headers: headers,
                })
                .then(function (response) {
                    console.log(response, 'Response data');
                    localStorage.setItem("message", "getFilterByProductSuccessfully");
                    dispatch(fetchProductsByFilterSuccess(response.data));
                })
                .catch(function (error) {
                    localStorage.setItem("message", "getFilterByProductFailed");
                    dispatch(fetchProductsByFilterFailure(error));
                });
        }, 10); // 5000 milliseconds (5 seconds)
    };
};






export const fetchProductsByHomeFilterRequest = () => {
    return {
        type: GetAllProductsHomeRequest,
    };
};
export const fetchProductsByHomeFilterSuccess = (Data) => {
    return {
        type: GetAllProductsHomeSucccess,
        payload: Data,
    };
};

export const fetchProductsByHomeFilterFailure = (Error) => {
    return {
        type: GetAllProductsHomeFailure,
        payload: Error,
    };
};



export const fetchProductsHomeByFilter = (data) => {
    console.log(data)
    const headers = {
        "Content-Type": "Application/json",
        'Authorization': basicAuth,
    };

    // Dynamic Object creatingprod
    return (dispatch) => {
        dispatch(fetchProductsByHomeFilterRequest());
        // const pincode=localStorage.getItem('data')

        // Introduce a delay of 5 seconds
            axios
                .get(url + `getAllProductByCityId/${data}`,
                    {
                        headers: headers,
                    })
                .then(function (response) {
                    localStorage.setItem("message", "getFilterByProductSuccessfully");
                    dispatch(fetchProductsByHomeFilterSuccess(response.data));
                })
                .catch(function (error) {
                    localStorage.setItem("message", "getFilterByProductFailed");
                    dispatch(fetchProductsByHomeFilterFailure(error));
                });
       // 5000 milliseconds (5 seconds)
    };
};
